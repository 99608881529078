import React, { useState, useEffect, useCallback } from "react";
import {
  Drawer,
  Box,
  Button,
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addProgressAnalysis,
  getExams,
  getSubmisionData,
} from "../../../../services/Exam";
import { sortByDate } from "../../../../utils/dateHelper";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
} from "chart.js";
import {
  ModalCustomCancel,
  ModalCustomConfirm,
} from "../../../../styles/muiStylesHelper";
import SnackbarMessage from "../../../../components/UI/SnackbarMessage";
import ProgressionChart from "./ProgressionChart";
import SelectAnalysisCard from "./SelectAnalysisCard";
import FetchExam from "./FetchExam";
import { progressionChartShapes } from "../../../../enums";

const ProgressAnalysis = ({ open, setOpen, clinicId, selectedPatients }) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [selectedEye, setSelectedEye] = useState("Left");
  const [sortedExams, setSortedExams] = useState([]);
  const [filteredExams, setFilteredExams] = useState([]);
  const [selectedExams, setSelectedExams] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openAddExamSnackbar, setOpenAddExamSnackbar] = useState(false);
  const [addExamSuccess, setAddExamSuccess] = useState(false);
  const [addExamFailed, setAddExamFailed] = useState(false);

  const [chartData, setChartData] = useState({ MD: [], PSD: [], RLFI: [] });

  Chart.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    LineController
  );

  // Filter the "Left" or "Right" eye exams from a list of sorted exams
  const filterExamsByEye = useCallback((sortedExams, selectedEye) => {
    const filteredExams = sortedExams.filter((exam) => {
      if (exam.completionState !== "Completed") return false;

      const matchingSections = exam.visualFieldSections?.filter(
        (section) =>
          ["FULL_THRESHOLD", "STANDARD_THRESHOLD", "FAST_THRESHOLD"].includes(
            section.algorithm
          ) && section.eye === selectedEye
      );
      return matchingSections && matchingSections.length > 0;
    });

    const mappedExams = filteredExams.map((exam) => ({
      ...exam,
      visualFieldSections: exam.visualFieldSections.filter(
        (section) => section.eye === selectedEye
      ),
    }));

    setFilteredExams(mappedExams);
  }, []);

  // Reset data on the drawer
  const resetData = () => {
    setSelectedExams([]);
    setChartData({ MD: [], PSD: [], RLFI: [] });
  };

  // Logic to run when changing the eye type ("Left" or "Right")
  const changeEye = (event) => {
    resetData();
    setSelectedEye(event.target.value);
    filterExamsByEye(sortedExams, event.target.value);
  };

  const handleCardSelect = useCallback(
    async (exam) => {
      if (selectedExams.some((e) => e.id === exam.id)) {
        const updatedSelection = selectedExams.filter((e) => e.id !== exam.id);
        setSelectedExams(updatedSelection);
        updateCharts(updatedSelection);
      } else {
        if (selectedExams.length > 4) {
          setOpenSnackbar(true);
          return;
        }

        try {
          const token = await getAccessTokenSilently();
          const visualFieldSectionId = exam.visualFieldSections?.[0]?.id;
          const reportData = await getSubmisionData(
            token,
            clinicId,
            selectedPatients[0],
            exam.id,
            visualFieldSectionId
          );

          if (reportData) {
            const parsedReportData = JSON.parse(reportData);

            if (
              parsedReportData?.postProcessing?.retinaLogikFieldIndex !== null
            ) {
              parsedReportData.postProcessing.retinaLogikFieldIndex *= 100;
            }
            if (parsedReportData?.stats?.fixationLossPercentage != null) {
              parsedReportData.stats.fixationLossPercentage *= 100;
            }
            if (parsedReportData?.stats?.falsePositivePercentage != null) {
              parsedReportData.stats.falsePositivePercentage *= 100;
            }

            const enrichedExam = {
              ...exam,
              reportData: parsedReportData,
              visualFieldSectionId,
            };

            const updatedSelection = [...selectedExams, enrichedExam];
            setSelectedExams((prevExams) => [...prevExams, enrichedExam]);
            updateCharts(updatedSelection);
          }
        } catch (err) {
          console.error(`Error fetching report data for exam ${exam.id}:`, err);
        }
      }
    },
    [clinicId, getAccessTokenSilently, selectedExams, selectedPatients]
  );

  const handleAddProgressAnalysis = async () => {
    if (selectedExams.length <= 2) {
      setOpenAddExamSnackbar(true);
      return;
    }
    const examIds = selectedExams.map((exam) => exam.id);
    const ExamData = { eye: selectedEye, examIds };
    try {
      const token = await getAccessTokenSilently();
      const response = await addProgressAnalysis(
        token,
        clinicId,
        selectedPatients[0],
        ExamData
      );
      console.log({ response });
      setAddExamSuccess(true);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 4000);
    } catch (error) {
      setAddExamSuccess(false);
      setAddExamFailed(true);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  };

  const updateCharts = (selectedExams) => {
    const time = selectedExams.map((exam) =>
      new Date(exam.creationDate).toLocaleDateString()
    );

    const MD = selectedExams.map(
      (exam) => exam.reportData?.postProcessing?.totalMeanDeviation ?? null
    );
    const PSD = selectedExams.map(
      (exam) =>
        exam.reportData?.postProcessing?.patternStandardDeviation ?? null
    );
    const RLFI = selectedExams.map(
      (exam) => exam.reportData?.postProcessing?.retinaLogikFieldIndex ?? null
    );
    const chartPointShapes = selectedExams.map(
      (exam) => progressionChartShapes[exam.reportData?.exam.algorithm]
    );

    setChartData({ time, MD, PSD, RLFI, chartPointShapes });
  };

  useEffect(() => {
    const validExamGrids = ["G_24_2", "G_30_2"];

    // Fetch all exams and sort them by creation date
    const fetchExams = async () => {
      if (!selectedPatients || selectedPatients.length !== 1) return;

      try {
        const token = await getAccessTokenSilently();
        const examsData = await getExams(token, clinicId, selectedPatients[0]);

        if (!examsData) return;

        // only return exams with certain grid types
        const validExams = examsData.filter((exam) =>
          validExamGrids.includes(exam.visualFieldSections?.[0]?.gridType)
        );

        // sort exams by creation date
        const sortedExams = sortByDate(validExams, "creationDate");
        setSortedExams(sortedExams);
        filterExamsByEye(sortedExams, selectedEye);
      } catch (error) {
        console.error("Error fetching exams:", error);
      }
    };

    fetchExams();
  }, [
    filterExamsByEye,
    getAccessTokenSilently,
    selectedEye,
    clinicId,
    selectedPatients,
  ]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          resetData();
          setOpen(false);
        }}
        PaperProps={{
          sx: {
            width: "80%",
            height: "100%",
          },
        }}
      >
        <Box
          sx={{
            height: "100vh",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              py: 1,
              px: 2,
              gap: 2,
              backgroundColor: "white",
              borderBottom: "1px solid black",
              position: "relative",
            }}
          >
            <Button
              variant="outlined"
              sx={ModalCustomCancel}
              onClick={() => {
                resetData();
                setOpen(false);
              }}
            >
              {t("progress_analysis_cancel")}
            </Button>
            <Button
              variant="outlined"
              sx={ModalCustomConfirm}
              onClick={handleAddProgressAnalysis}
              disabled={selectedExams?.length < 3}
            >
              {t("progress_analysis_generate")}
            </Button>
          </Box>

          <Grid container>
            <Grid item xs={12} lg={4} sm={6}>
              <Box
                sx={{
                  width: "100%",
                  height: "72vh",
                  padding: 2,
                  borderRight: { xs: "none", sm: "1px solid black" },
                  borderBottom: { xs: "1px solid black", sm: "none" },
                }}
              >
                <ProgressionChart chartData={chartData} />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              lg={8}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
                height: "calc(100vh - 28vh)",
                overflowY: "auto",
                padding: 2,
              }}
            >
              {selectedExams.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography variant="body1" sx={{ color: "#999" }}>
                    {t("exams_select_rule")}
                  </Typography>
                </Box>
              ) : (
                filteredExams.map((exam) => {
                  const isSelected = selectedExams.some(
                    (e) => e.id === exam.id
                  );
                  const selectedExam = selectedExams.find(
                    (e) => e.id === exam.id
                  );

                  return isSelected && selectedExam?.reportData ? (
                    <Box key={exam.id}>
                      <FetchExam exam={exam} selectedExam={selectedExam} />
                    </Box>
                  ) : null;
                })
              )}
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderTop: "1px solid black",
              py: 1,
              px: 2,
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              {t("progress_analysis_select_eye")}
            </Typography>

            <RadioGroup
              row
              value={selectedEye}
              onChange={(e) => changeEye(e)}
              sx={{ marginLeft: 2 }}
            >
              <FormControlLabel
                value="Left"
                control={
                  <Radio
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#E2772E",
                      },
                    }}
                  />
                }
                label={t("word_left")}
              />

              <FormControlLabel
                value="Right"
                control={
                  <Radio
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#E2772E",
                      },
                    }}
                  />
                }
                label={t("word_right")}
              />
            </RadioGroup>
          </Box>

          <Stack
            direction="row"
            gap={2}
            sx={{
              px: 2,
              paddingBottom: 2,
              overflowX: "auto",
              "&::-webkit-scrollbar": { height: "8px" },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: 4,
              },
              "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
            }}
          >
            {filteredExams.map((exam) => {
              const isSelected = selectedExams.some((e) => e.id === exam.id);
              const selectedExam = selectedExams.find((e) => e.id === exam.id);
              const isDisabled = !isSelected && selectedExams.length >= 5;

              return (
                <SelectAnalysisCard
                  key={exam.id}
                  exam={exam}
                  isSelected={isSelected}
                  selectedExam={selectedExam}
                  handleCardSelect={handleCardSelect}
                  isDisabled={isDisabled}
                />
              );
            })}
          </Stack>
        </Box>

        <SnackbarMessage
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          text={t("exams_select_rule")}
          position={{ vertical: "top", horizontal: "right" }}
        />

        <SnackbarMessage
          open={openAddExamSnackbar}
          onClose={() => setOpenAddExamSnackbar(false)}
          text={t("exams_add_rule")}
          position={{ vertical: "top", horizontal: "right" }}
        />

        <SnackbarMessage
          open={addExamSuccess}
          onClose={() => setAddExamSuccess(false)}
          success={true}
          text={t("exams_add_analysis_success")}
          position={{ vertical: "top", horizontal: "right" }}
        />

        <SnackbarMessage
          open={addExamFailed}
          onClose={() => setAddExamFailed(false)}
          text={t("exams_add_analysis_failed")}
          position={{ vertical: "top", horizontal: "right" }}
        />
      </Drawer>
    </>
  );
};

export default ProgressAnalysis;
