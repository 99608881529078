import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Components
import ClinicModeModal from "../ClinicModeModal";
import LanguageSwitcherModal from "../LanguageSwitcherModal";
import SignOutModal from "../SignOutModal";
import UserMenu from "../UserMenu";

const clinicRoute = "/";

const UserMenuAdminWrapper = ({ canReachPages }) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [clinicModeModal, setClinicModeModal] = useState(false);
  const [languageSwitcherModal, setLanguageSwitcherModal] = useState(false);

  const { logout } = useAuth0();

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  // menu close handler
  const userMenuClose = () => {
    setUserMenu(null);
  };

  const handleClinicClick = useCallback(() => setClinicModeModal(true), []);

  // handle language switcher click
  const handleLanguageClick = useCallback(
    () => setLanguageSwitcherModal(true),
    []
  );

  // handle admin modal confirm
  const handleClinicConfirm = () => {
    userMenuClose();
    setClinicModeModal(false);
    navigate(clinicRoute);
  };

  // handle language switcher modal confirm
  const handleLanguageConfirm = () => {
    userMenuClose();
    setLanguageSwitcherModal(false);
  };

  // State management
  const [userMenu, setUserMenu] = useState(null);
  const [signOutModal, setSignOutModal] = useState(false);

  const handleLogout = useCallback(
    (event) => {
      if (event) {
        // Clear local storage
        localStorage.clear();
        sessionStorage.clear();
        logout({
          logoutParams: {
            returnTo: process.env.REACT_APP_URL,
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          },
        });
      }
    },
    [logout]
  );

  // Modal handlers
  const handleSignOutClick = useCallback((event) => {
    setSignOutModal(true);
  }, []);

  const handleSignOutConfirm = useCallback(
    (event) => {
      handleLogout(event);
      setSignOutModal(false);
    },
    [handleLogout]
  );

  return (
    <>
      {/* menu button */}
      <Button
        onClick={userMenuClick}
        sx={{
          display: { xs: "block", sm: "inline" },
          minWidth: { xs: 0, sm: 64 },
          ml: 4,
          color: "#E2772E",
          fontSize: 20,
          ...(Boolean(userMenu) && {
            fontWeight: "bold",
            textDecoration: "underline",
            textUnderlineOffset: 4,
          }),
          "&:hover": {
            ...(Boolean(userMenu) && {
              textDecoration: "underline",
              textUnderlineOffset: 4,
            }),
          },
        }}
      >
        <div className="flex space-x-2 items-center">
          <FontAwesomeIcon icon={faUserShield} size="sm" />
          <Typography
            variant="button"
            sx={{
              color: "#E2772E",
              display: { xs: "none", sm: "none", md: "block" },
              textTransform: "capitalize",
              fontSize: 20,
            }}
          >
            {user.name}
          </Typography>
        </div>
      </Button>

      <UserMenu
        canReachPages={canReachPages}
        userMenu={userMenu}
        userMenuClose={userMenuClose}
        handleLeftClick={handleClinicClick}
        handleCenterClick={handleLanguageClick}
        handleRightClick={handleSignOutClick}
        isAdmin={true}
        isAdminPage={true}
        // setIsAdmin={}
      />

      <ClinicModeModal
        open={clinicModeModal}
        onClose={() => setClinicModeModal(false)}
        onCancel={() => setClinicModeModal(false)}
        onConfirm={handleClinicConfirm}
      />
      <LanguageSwitcherModal
        open={languageSwitcherModal}
        onClose={() => setLanguageSwitcherModal(false)}
        onCancel={() => setLanguageSwitcherModal(false)}
        onConfirm={handleLanguageConfirm}
      />
      <SignOutModal
        open={signOutModal}
        onClose={() => setSignOutModal(false)}
        onCancel={() => setSignOutModal(false)}
        onConfirm={handleSignOutConfirm}
      />
    </>
  );
};

export default UserMenuAdminWrapper;
