// Libraries
import { useAuth0 } from "@auth0/auth0-react";
import { BlobServiceClient } from "@azure/storage-blob";
import { useCallback, useEffect, useRef, useState } from "react";

// Services
import { AllFileTypes, ErrorMessages } from "../../services/Exam";
import { getClinics } from "../../services/Clinic";

import { ReportStatus } from "../../utils/examReportsHelper";
import useClinic from "../useClinic";

export const useReportGeneration = ({
  getAccessInfo,
  generateReportFile,
  getReportInfo,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  // clinic context
  const { clinicId, setClinicLicensing } = useClinic();

  const [fileInfo, setFileInfo] = useState({
    pdf: {
      hasBlobStorageError: false,
      isDownloading: false,
      openFailedToast: false,
      accessInfo: null,
      reportInfoData: null,
    },
    dicom: {
      hasBlobStorageError: false,
      isDownloading: false,
      openFailedToast: false,
      accessInfo: null,
      reportInfoData: null,
    },
    png: {
      hasBlobStorageError: false,
      isDownloading: false,
      openFailedToast: false,
      accessInfo: null,
      reportInfoData: null,
    },
  });
  // It's true when report info request itself fails.
  const [reportInfoError, setReportInfoError] = useState(false);
  const [openFailedToast, setOpenFailedToast] = useState(false);
  const [shouldDisplayDicomButton, setShouldDisplayDicomButton] =
    useState(true);
  const [shouldDisplayPngButton, setShouldDisplayPngButton] = useState(true);

  const isfirstLoading = useRef(true);

  // Retrieve access information for blob storage
  const getBlobAccessInfo = useCallback(
    async (fileType, signal) => {
      try {
        const accessInfo = await getAccessInfo(fileType, signal);
        setFileInfo((prev) => {
          const newFileInfo = { ...prev };
          if (newFileInfo[fileType]?.hasBlobStorageError !== undefined)
            newFileInfo[fileType].hasBlobStorageError = false;
          if (newFileInfo[fileType]?.accessInfo !== undefined)
            newFileInfo[fileType].accessInfo = accessInfo;
          return newFileInfo;
        });
        return accessInfo;
      } catch (e) {
        if (fileInfo?.[fileType]?.hasBlobStorageError)
          setFileInfo((prev) => {
            const newFileInfo = { ...prev };
            if (newFileInfo[fileType]?.hasBlobStorageError)
              fileInfo[fileType].hasBlobStorageError = true;
          });
        throw e;
      }
    },
    [fileInfo, getAccessInfo]
  );

  const getReportInfoData = useCallback(
    async (signal) => {
      try {
        const reportInfo = await getReportInfo(signal);

        setReportInfoError(false);
        if (reportInfo?.length) {
          // const newReportInfo = {};
          // This object is to store the data to check the specific file format(type) is to be generated or not.
          // Each property is gonna be true when the report info doesn't have any info for the specific file types or it was failed to generate or just unavaiable.
          const fileTypeStatusChecker = Object.keys(AllFileTypes).reduce(
            (acc, key) => {
              acc[key] = false;
              return acc;
            },
            {}
          );

          const existingFileTypes = {};
          // If the information of each file type is included in the response of the reportInfo request.
          reportInfo.forEach((fileData) => {
            if (typeof fileData?.type === "string") {
              const fileType = fileData.type.toLowerCase();
              // newReportInfo[fileData.type] = fileData;
              setFileInfo((prev) => {
                const newFileInfo = { ...prev };
                if (
                  newFileInfo[fileType] &&
                  newFileInfo[fileType].reportInfoData !== undefined
                ) {
                  newFileInfo[fileType].reportInfoData = fileData;
                }

                return newFileInfo;
              });

              existingFileTypes[fileType] = true;

              if (
                fileData.status === ReportStatus.Failed ||
                fileData.status === ReportStatus.Unavailable
              ) {
                // Add the file types which is to be generate.
                fileTypeStatusChecker[fileType] = true;
              } else if (fileData.status !== ReportStatus.Ready)
                fileTypeStatusChecker[fileType] = false;
            }
          });

          // We'll regenerate lacked files on demand only at once.
          if (isfirstLoading.current === false) return;
          // The file types of which there's no information in the response from the beginning
          Object.keys(AllFileTypes).forEach((type) => {
            if (!existingFileTypes[type]) fileTypeStatusChecker[type] = true;
          });

          const getLicensing = async () => {
            const token = await getAccessTokenSilently();
            const { licensing } = await getClinics(token, clinicId);
            if (licensing) setClinicLicensing(licensing);
            return licensing;
          };
          const licensing = await getLicensing();

          // Generate files on demand. (Never generated or Unavailable or Failed)
          Object.keys(fileTypeStatusChecker).forEach(async (type) => {
            if (fileTypeStatusChecker[type]) {
              // licensing check that is for users to be able to generate DICOM file
              // This alos should be triggered concurrently in this loop
              if (
                type === AllFileTypes.dicom &&
                licensing.canUseDicomReports !== true
              )
                return setShouldDisplayDicomButton(false);
              if (
                type === AllFileTypes.png &&
                licensing.canUsePngReports !== true
              )
                return setShouldDisplayPngButton(false);
              await generateReportFile(type);
            }
          });
        }
      } catch (e) {
        console.error(e);
        if (e === ErrorMessages.ReportInfoError) {
          setReportInfoError(true);
        } else if (e === ErrorMessages.ReportFileGenrationError) {
          console.error(e);
        }
        // setHasPdfBlobStorageError(true);
      } finally {
        isfirstLoading.current = false;
      }
    },
    [
      clinicId,
      setClinicLicensing,
      generateReportFile,
      getReportInfo,
      getAccessTokenSilently,
    ]
  );

  // Check continuously if blob info to download pdf is ready or not.
  // Since report page gets open
  useEffect(() => {
    const controller = new AbortController();

    const intervalTime = 3000; // Interval between requests (3 seconds)

    const intervalId = setInterval(async () => {
      if (
        (fileInfo.dicom.reportInfoData?.status === ReportStatus.Ready ||
          !shouldDisplayDicomButton) &&
        (fileInfo.png.reportInfoData?.status === ReportStatus.Ready ||
          !shouldDisplayPngButton) &&
        fileInfo.pdf.reportInfoData?.status === ReportStatus.Ready
      ) {
        clearInterval(intervalId);
        return;
      }

      try {
        await getReportInfoData(controller.signal);
        setReportInfoError(false);
      } catch (e) {
        setReportInfoError(true);
      }
    }, intervalTime);

    return () => {
      controller.abort();
      clearInterval(intervalId);
    };
  }, [
    shouldDisplayDicomButton,
    fileInfo.dicom.reportInfoData?.status,
    fileInfo.pdf.reportInfoData?.status,
    getReportInfoData,
    fileInfo.png.reportInfoData?.status,
    shouldDisplayPngButton,
  ]);

  const downloadPdfBlob = async (fileType) => {
    setFileInfo((prev) => {
      const newFileInfo = { ...prev };
      if (
        newFileInfo?.[fileType] &&
        newFileInfo[fileType].isDownloading !== undefined
      )
        newFileInfo[fileType].isDownloading = true;
      return newFileInfo;
    });
    try {
      const accessInfo = await getBlobAccessInfo(fileType);
      const access = accessInfo?.access;
      if (!accessInfo || !access) {
        setFileInfo((prev) => {
          const newFileInfo = { ...prev };
          if (
            newFileInfo?.[fileType] &&
            newFileInfo[fileType].isDownloading !== undefined
          )
            newFileInfo[fileType].isDownloading = false;
          return newFileInfo;
        });
        throw new Error("Invalid access information");
      }
      const blobServerClient = new BlobServiceClient(
        `${access?.url}${access.sasKey}`
      );
      const containerClient = blobServerClient.getContainerClient(
        access.containerName
      );
      const blobClient = containerClient
        .getBlobClient(accessInfo.fileName)
        .getBlockBlobClient();
      const downloadedRes = await blobClient.download();
      const blob = await downloadedRes.blobBody;
      if (blob) {
        setFileInfo((prev) => {
          const newFileInfo = { ...prev };
          if (
            newFileInfo?.[fileType] &&
            newFileInfo[fileType].isDownloading !== undefined
          )
            newFileInfo[fileType].isDownloading = false;
          return newFileInfo;
        });
        return {
          blob: blob,
          fileName: accessInfo.recommendedFileName,
        };
      }
      return null;
    } catch (e) {
      console.error(e);
      setOpenFailedToast(true);
      return null;
    } finally {
      setFileInfo((prev) => {
        const newFileInfo = { ...prev };
        if (
          newFileInfo?.[fileType] &&
          newFileInfo[fileType].isDownloading !== undefined
        )
          newFileInfo[fileType].isDownloading = false;
        return newFileInfo;
      });
    }
  };

  const downloadPdf = async (fileType) => {
    const blobData = await downloadPdfBlob(fileType);
    if (!blobData) {
      // setHasNoPdf(true);
      // warning modal
      return;
    }
    const blob = blobData.blob;
    if (blob && blob.size > 0) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);

      link.download = blobData.fileName;
      link.click();
    } else {
      // warning modal
    }
  };

  return {
    downloadPdf,
    getReportInfoData,
    shouldDisplayDicomButton,
    shouldDisplayPngButton,
    fileInfo,
    reportInfoError,
    openFailedToast,
    setOpenFailedToast,
  };
};
