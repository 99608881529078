// MUI Components
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useMemo } from "react";

const ListHeader = ({
  containerPaddingX = 2,
  onSelectAllClick = () => {},
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort = () => {},
  headCells = [],
  showCheckbox = true,
  checkBoxGrid = 1,
  shouldDisplayGrid = "xxs",
  displayFirtsBeforeCheckbox = false,
  needTextAlignCenter = false,
  shouldTakeUpSpace = true,
  extraCells = [],
}) => {
  // Handles sort
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const extraGridSize = useMemo(() => {
    let s = 0;
    extraCells.forEach((cell) => (s += cell?.gridSize ?? 0));
    return s;
  }, [extraCells]);

  const arrowStyle = { marginLeft: { xs: 0, sm: 0.5 } };

  return (
    <Grid
      container
      px={{ md: 0, lg: containerPaddingX }}
      sx={{
        display: { xs: "none", [shouldDisplayGrid]: "flex" },
        justifyContent: displayFirtsBeforeCheckbox
          ? undefined
          : "space-between",
      }}
    >
      {displayFirtsBeforeCheckbox && (
        <Grid
          item
          xs={headCells[0].gridSize}
          textAlign={needTextAlignCenter ? "center" : "left"}
        >
          {headCells[0].label && (
            <Button
              sx={{
                color: "black",
                textTransform: "capitalize",
                fontSize: "1rem",
                pr: orderBy === headCells[0].orderUsing[0] ? 0 : 1,
                pl: 1,
                ml: -1,
                minWidth: 0,
                pointerEvents: headCells[0].orderUsing[0] ? "auto" : "none",
              }}
              {...(headCells[0].orderUsing[0]
                ? { onClick: createSortHandler(headCells[0].orderUsing) }
                : {})}
            >
              {headCells[0].label}
              {orderBy === headCells[0].orderUsing[0] && order === "asc" && (
                <ArrowDropUpIcon sx={arrowStyle} />
              )}
              {orderBy === headCells[0].orderUsing[0] && order === "desc" && (
                <ArrowDropDownIcon sx={arrowStyle} />
              )}
            </Button>
          )}
        </Grid>
      )}
      {/* If should display the first item before the checkbox, the first item is a different grid compared to the remaining items */}
      {!displayFirtsBeforeCheckbox ? (
        <Grid container item xs={12}>
          {shouldTakeUpSpace && (
            <Grid item xs={checkBoxGrid} mr={{ xs: 2.5, sm: 0 }}>
              {showCheckbox && rowCount > 0 && (
                <Checkbox
                  sx={{ color: "black !important" }}
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                />
              )}
            </Grid>
          )}
          <Grid
            container
            item
            xs={12 - extraGridSize - checkBoxGrid}
            alignItems={"center"}
          >
            {headCells.map((headCell) => (
              <Grid
                item
                xs={headCell.gridSize}
                key={headCell.id}
                display={!shouldTakeUpSpace ? "flex" : undefined}
                justifyContent={!shouldTakeUpSpace ? "center" : undefined}
              >
                {headCell.label && (
                  <Button
                    sx={{
                      color: "black",
                      textTransform: "capitalize",
                      fontSize: { xs: "10px", md: "1rem" },
                      pr: orderBy === headCell.orderUsing[0] ? 0 : 1,
                      pl: 1,
                      ml: -1,
                      minWidth: 0,
                      pointerEvents: headCell.orderUsing[0] ? "auto" : "none",
                    }}
                    {...(headCell.orderUsing[0]
                      ? { onClick: createSortHandler(headCell.orderUsing) }
                      : {})}
                  >
                    {headCell.label}
                    {orderBy === headCell.orderUsing[0] && order === "asc" && (
                      <ArrowDropUpIcon sx={arrowStyle} />
                    )}
                    {orderBy === headCell.orderUsing[0] && order === "desc" && (
                      <ArrowDropDownIcon sx={arrowStyle} />
                    )}
                  </Button>
                )}
              </Grid>
            ))}
          </Grid>
          <Grid container item xs={extraGridSize}>
            {extraCells.map((headCell) => (
              <Grid
                key={headCell?.id}
                item
                xs={0}
                lg={headCell?.gridSize}
              ></Grid>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Grid container item xs={12 - headCells[0].gridSize}>
          <Grid container alignItems={"center"}>
            <Grid item xs={checkBoxGrid}>
              {showCheckbox && (
                <Checkbox
                  sx={{ color: "black !important" }}
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                />
              )}
            </Grid>
            <Grid
              container
              item
              xs={12 - checkBoxGrid - extraGridSize}
              alignItems={"center"}
            >
              {headCells.slice(1).map((headCell) => (
                <Grid
                  item
                  xs={headCell.gridSize}
                  key={headCell.id}
                  textAlign={needTextAlignCenter ? "center" : "left"}
                >
                  {headCell.label && (
                    <Button
                      sx={{
                        color: "black",
                        textTransform: "capitalize",
                        fontSize: { xs: "10px", md: "1rem" },
                        pr: orderBy === headCell.orderUsing[0] ? 0 : 1,
                        pl: 1,
                        ml: -1,
                        minWidth: 0,
                        pointerEvents: headCell.orderUsing[0] ? "auto" : "none",
                      }}
                      {...(headCell.orderUsing[0]
                        ? { onClick: createSortHandler(headCell.orderUsing) }
                        : {})}
                    >
                      {headCell.label}
                      {orderBy === headCell.orderUsing[0] &&
                        order === "asc" && <ArrowDropUpIcon sx={arrowStyle} />}
                      {orderBy === headCell.orderUsing[0] &&
                        order === "desc" && (
                          <ArrowDropDownIcon sx={arrowStyle} />
                        )}
                    </Button>
                  )}
                </Grid>
              ))}
            </Grid>
            <Grid container item xs={extraGridSize}>
              {extraCells.map((headCell) => (
                <Grid
                  key={headCell?.id}
                  item
                  xs={0}
                  lg={headCell?.gridSize}
                ></Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ListHeader;
