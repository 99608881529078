// Library methods
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

import {
  getSingleProgressionAnalysis,
  getSubmisionData,
} from "../../services/Exam";

export const useProgressionAnalysisExams = ({
  useToken,
  clinicId,
  patientId,
  progressionAnalysisId,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);

  // analysis object (unaltered from backend return type)
  const [analysisData, setAnalysisData] = useState();

  // enriched analysis exam objects
  const [examsData, setExamsData] = useState();

  useEffect(() => {
    const fetchProgressionAnalysisExams = async () => {
      setIsLoading(true);
      try {
        const token = useToken ? await getAccessTokenSilently() : null;
        const analysisData = await getSingleProgressionAnalysis(
          token,
          clinicId,
          patientId,
          progressionAnalysisId
        );

        setAnalysisData(analysisData);

        if (analysisData?.exams?.length > 0) {
          const enrichedExams = await Promise.all(
            analysisData.exams.map(async (exam) => {
              try {
                const visualFieldSectionId = exam.visualFieldSections?.[0]?.id;
                const reportData = await getSubmisionData(
                  token,
                  clinicId,
                  exam.patient.id,
                  exam.id,
                  visualFieldSectionId
                );

                if (reportData) {
                  const parsedReportData = JSON.parse(reportData);

                  if (
                    parsedReportData?.postProcessing?.retinaLogikFieldIndex !==
                    null
                  ) {
                    parsedReportData.postProcessing.retinaLogikFieldIndex *= 100;
                  }
                  if (parsedReportData?.stats?.fixationLossPercentage != null) {
                    parsedReportData.stats.fixationLossPercentage *= 100;
                  }
                  if (
                    parsedReportData?.stats?.falsePositivePercentage != null
                  ) {
                    parsedReportData.stats.falsePositivePercentage *= 100;
                  }

                  return {
                    ...exam,
                    reportData: parsedReportData,
                    visualFieldSectionId,
                  };
                }
              } catch (err) {
                console.error(
                  `Error fetching report data for exam ${exam.id}:`,
                  err
                );
              }
              return exam;
            })
          );
          setExamsData(enrichedExams);
        }
      } catch (e) {
        console.error("Error fetching data:", e);
      } finally {
        setIsLoading(false);
      }
    };

    if (progressionAnalysisId && clinicId && patientId) {
      fetchProgressionAnalysisExams();
    }
  }, [
    useToken,
    clinicId,
    patientId,
    progressionAnalysisId,
    getAccessTokenSilently,
  ]);

  return { analysisData, examsData, isLoading };
};
