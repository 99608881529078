// Library methods
import { useTranslation } from "react-i18next";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// Styles
import {
  ModalCustomCancel,
  ModalPaperProps,
} from "../../../styles/muiStylesHelper";

const UnavailableDeviceModal = ({ open, onClose, onCancel }) => {
  // internationalization
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: ModalPaperProps,
      }}
    >
      <DialogContent>
        <Box sx={{ padding: 1 }}>
          <Typography
            id="modal-modal-title"
            className="text-left"
            variant="h5"
            component="h2"
          >
            {t("device_unavailable_modal_title")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 3 }}>
            {t("device_unavailable_description")}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ padding: 3, display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="text"
          sx={ModalCustomCancel}
          onClick={onCancel}
          style={{ marginRight: "1.25rem" }}
        >
          {t("button_close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnavailableDeviceModal;
