// Assets
import Grayscale0 from "../assets/images/grayscale/0.png";
import Grayscale1to5 from "../assets/images/grayscale/1-5.png";
import Grayscale6to10 from "../assets/images/grayscale/6-10.png";
import Grayscale11to15 from "../assets/images/grayscale/11-15.png";
import Grayscale16to20 from "../assets/images/grayscale/16-20.png";
import Grayscale21to25 from "../assets/images/grayscale/21-25.png";
import Grayscale26to30 from "../assets/images/grayscale/26-30.png";
import Grayscale31to35 from "../assets/images/grayscale/31-35.png";
import Grayscale36to40 from "../assets/images/grayscale/36-40.png";
import Grayscale41to50 from "../assets/images/grayscale/41-50.png";
import Undefined from "../assets/images/grayscale/undefined.png";
import LessThan05 from "../assets/images/deviation-patterns/LessThan05.png";
import LessThan1 from "../assets/images/deviation-patterns/LessThan1.png";
import LessThan2 from "../assets/images/deviation-patterns/LessThan2.png";
import LessThan5 from "../assets/images/deviation-patterns/LessThan5.png";

export const GridTypes = {
  G10: "G_10_2",
  G24: "G_24_2",
  G24C: "G_24_2_C",
  G30: "G_30_2",
  G120: "G_120",
  F120: "FULL_120",
};

export const ReportStatus = {
  Unavailable: "Unavailable",
  Pending: "Pending",
  InProgress: "InProgress",
  Ready: "Ready",
  Failed: "Failed",
};

export const FixationPointShapeOptions = {
  Sphere: "Sphere",
  Diamond: "Diamond",
};

export const FixationLossMethodOptions = {
  EyeTracking: "EyeTracking",
  BlindSpot: "BlindSpot",
};

export const GHT = {
  Undefined: "Undefined",
  AbnormallyHighSensitivity: "AbnormallyHighSensitivity",
  OutsideNormalLimits: "OutsideNormalLimits",
  Borderline: "Borderline",
  GeneralReductionOfSensitivity: "GeneralReductionOfSensitivity",
  WithinNormalLimits: "WithinNormalLimits",
};

/**
 * Gets the data for the VisualSpots, from the examReport, to populate the charts.
 * @param {*} examReport
 */
export const getChartSpots = (examReport) => {
  if (!examReport?.state?.visualSpots) return [];
  if (examReport?.state?.visualSpots.length === 0) return [];

  // formatting the spots into another structure, used by the number chart
  const formattedSpots = examReport?.state?.visualSpots?.map((spot) => ({
    decibelValue: Math.floor(spot.intensity),
    x: spot.position.x,
    y: spot.position.y,
    completed: spot.isCompleted,
    current: false,
    seen: spot.isSeen,
  }));

  // add the current visual spot into the formatted spots array
  const currentVisualSpot = examReport?.state?.currentVisualSpot;
  if (currentVisualSpot) {
    const currentVisualSpotData = {
      decibelValue: Math.floor(currentVisualSpot.intensity),
      x: currentVisualSpot.position.x,
      y: currentVisualSpot.position.y,
      completed: currentVisualSpot.isCompleted,
      current: true,
      seen: currentVisualSpot.isSeen ?? false,
    };
    formattedSpots.push(currentVisualSpotData);
  }

  return formattedSpots;
};

/**
 * Creates a 2D array, based on the received x and y values. y represents the outer array length, and x represents the inner arrays' lengths.
 * @param {*} x The outer array length.
 * @param {*} y The inner array's lengths.
 * @returns A 2D array, with undefined as values.
 */
export const create2DArray = (x, y) => {
  // to get a specific value, in case needed, this is how it should be done: array[yIndex][xIndex]
  return new Array(y).fill(undefined).map(() => new Array(x).fill(undefined));
};

export const getTotalChartSpots = (examReport) => {
  if (!examReport?.postProcessing) return [];
  if (!examReport?.postProcessing?.processedPoints) return [];
  if (examReport?.state?.visualSpots.length === 0) return [];
  if (!examReport?.postProcessing?.processedPoints.length) return [];

  // formatting the spots into another structure, used by the total chart
  const formattedTotalSpots = examReport?.postProcessing.processedPoints?.map(
    (point) => ({
      decibelValue: point.TotalDeviation,
      valuePercentile: isNaN(parseFloat(point.TotalDeviationPercentile))
        ? undefined
        : point.TotalDeviationPercentile * 100,
      x: point?.Spot.position.x,
      y: point?.Spot.position.y,
      completed: point?.Spot.isCompleted,
      current: false,
    })
  );

  return formattedTotalSpots;
};

export const defaultGazeChartSpect = {
  maxValue: 90,
  minValue: -90,
  centralValue: 3,
  totalValues: 183,
};

export const getGazeChartData = (
  examReport,
  chartSpecs = defaultGazeChartSpect
) => {
  let under0 = 0;
  let eyeTrackingError = null;
  if (examReport?.misc?.eyeTracking === undefined)
    return { gazeSpots: [], eyeTrackingError };

  const gazeSpots = examReport?.misc?.eyeTracking.map((eyeData, id) => {
    if (eyeData < 0) under0++;
    const { gazeValue, bottomDistance } = calculateGazeChartPoint(
      eyeData,
      chartSpecs
    );

    return { id, gazeValue, bottomDistance };
  });

  eyeTrackingError = under0 * 2 >= examReport?.misc?.eyeTracking?.length;
  return { gazeSpots, eyeTrackingError };
};

export const getPatternDevChartSpots = (examReport) => {
  if (!examReport?.postProcessing) return [];
  if (!examReport?.postProcessing?.processedPoints) return [];
  if (examReport?.state?.visualSpots.length === 0) return [];
  if (!examReport?.postProcessing?.processedPoints.length) return [];

  // formatting the spots into another structure, used by the pattern dev chart
  const formattedPatternDevSpots =
    examReport?.postProcessing.processedPoints?.map((point) => ({
      decibelValue: point.PatternDeviation,
      valuePercentile: isNaN(parseFloat(point.PatternDeviationPercentile))
        ? undefined
        : point.PatternDeviationPercentile * 100,
      x: point?.Spot.position.x,
      y: point?.Spot.position.y,
      completed: point?.Spot.isCompleted,
      current: false,
    }));

  return formattedPatternDevSpots;
};

export const calculateXPaddingEstermanGraph = (xFloor, scaleFactor = 1) => {
  // jump Axis lets us jump from negative x axis to positive x axis (half of total width + line width + padding)
  // Adjusted scale for xFloor unit
  const jumpAxis = 311 * scaleFactor;
  const paddingPerUnit = 3.8 * scaleFactor;

  if (xFloor <= 0) {
    return 305 * scaleFactor - xFloor * -paddingPerUnit;
  } else if (xFloor > 0) {
    return xFloor * paddingPerUnit + jumpAxis;
  }
};

export const calculateYPaddingEstermanGraph = (yFloor, scaleFactor = 1) => {
  // jump Axis lets us jump from negative y axis to negative y axis (half of total width + line width + padding)
  const jumpAxis = 311 * scaleFactor;
  const paddingPerUnit = 3.8 * scaleFactor;

  if (yFloor >= 0) {
    // Adjust fixed value and multiplication by the scaleFactor
    return 306 * scaleFactor - yFloor * paddingPerUnit;
  } else {
    return jumpAxis + yFloor * -paddingPerUnit;
  }
};

export const calculateXPaddingSingleThresholdGraph = (
  xFloor,
  scaleFactor = 1
) => {
  // jump Axis lets us jump from negative x axis to positive x axis (half of total width + line width + padding)
  const jumpAxis = 306 * scaleFactor;
  const paddingPerUnit = 5.2 * scaleFactor;

  if (xFloor <= 0) {
    return 310 * scaleFactor - xFloor * -paddingPerUnit;
  } else if (xFloor > 0) {
    return xFloor * paddingPerUnit + jumpAxis;
  }
};

export const calculateYPaddingSingleThresholdGraph = (
  yFloor,
  scaleFactor = 1
) => {
  // jump Axis lets us jump from negative y axis to negative y axis (half of total width + line width + padding)
  const jumpAxis = 306 * scaleFactor;

  if (yFloor >= 0) {
    return 308 * scaleFactor - yFloor * 5.2 * scaleFactor;
  } else {
    return jumpAxis + yFloor * -5.3 * scaleFactor;
  }
};

export const calculateXPaddingNumberGraph = (xFloor) => {
  // jump Axis lets us jump from negative x axis to positive x axis (half of total width + line width + padding)
  const jumpAxis = 133;

  if (xFloor <= 0) {
    return 163 - xFloor * -31;
  } else if (xFloor > 0) {
    return xFloor * 31 + jumpAxis;
  }
};

export const calculateYPaddingNumberGraph = (yFloor) => {
  // jump Axis lets us jump from negative y axis to negative y axis (half of total width + line width + padding)
  const jumpAxis = 135;

  if (yFloor >= 0) {
    return 160 - yFloor * 29;
  } else {
    return jumpAxis + yFloor * -29;
  }
};
export const calculateXPaddingTotalGraph = (xFloor) => {
  // jump Axis lets us jump from negative x axis to positive x axis, 152 (half of total width + line width + 5px padding)
  const jumpAxis = 115;

  if (xFloor <= 0) {
    return 120 - xFloor * -23;
  } else if (xFloor > 0) {
    // x floor - 1 to make sure x = 1 maps at 5 px padding from the 0.
    return xFloor * 23 + jumpAxis;
  }
};

export const calculateYPaddingTotalGraph = (yFloor) => {
  // jump Axis lets us jump from positive y axis to negative y axis, 152 (half of total height + line width + 5px padding)
  const jumpAxis = 115;

  if (yFloor >= 0) {
    // unit for graph is 25
    return 120 - yFloor * 25;
    // return 0;
  } else {
    return jumpAxis + yFloor * -25;
  }
};

export const calculateXPaddingPatternGraph = (e) => {
  // jump Axis lets us jump from negative x axis to positive x axis, 152 (half of total width + line width + 5px padding)
  const jumpAxis = 136;
  const xFloor = e.x;

  if (xFloor <= 0) {
    // unit for graph is 25
    return 151 - xFloor * -25;
  } else if (xFloor > 0) {
    return jumpAxis + xFloor * 25;
  }
};

export const calculateYPaddingPatternGraph = (e) => {
  // jump Axis lets us jump from positive y axis to negative y axis, 152 (half of total height + line width + 5px padding)
  const jumpAxis = 131;
  const yFloor = e.y;

  if (yFloor >= 0) {
    // unit for graph is 30
    return 151 - yFloor * 30;
  } else {
    return jumpAxis + yFloor * -30;
  }
};

//  gives pattern image for the decibel values, and the alt text for the image to be displayed.
export const getImageInfo = (val) => {
  if (val <= 0)
    return {
      imageSrc: Grayscale0,
      alt: "Grayscale 0 value representation.",
    };
  else if (val <= 5 && val > 0)
    return {
      imageSrc: Grayscale1to5,
      alt: "Grayscale 1 to 5 value representation.",
    };
  else if (val <= 10 && val > 5)
    return {
      imageSrc: Grayscale6to10,
      alt: "Grayscale 6 to 10 value representation.",
    };
  else if (val <= 15 && val > 10)
    return {
      imageSrc: Grayscale11to15,
      alt: "Grayscale 11 to 15 value representation.",
    };
  else if (val <= 20 && val > 15)
    return {
      imageSrc: Grayscale16to20,
      alt: "Grayscale 16 to 20 value representation.",
    };
  else if (val <= 25 && val > 20)
    return {
      imageSrc: Grayscale21to25,
      alt: "Grayscale 21 to 25 value representation.",
    };
  else if (val <= 30 && val > 25)
    return {
      imageSrc: Grayscale26to30,
      alt: "Grayscale 26 to 30 value representation.",
    };
  else if (val <= 35 && val > 30)
    return {
      imageSrc: Grayscale31to35,
      alt: "Grayscale 31 to 35 value representation.",
    };
  else if (val <= 40 && val > 35)
    return {
      imageSrc: Grayscale36to40,
      alt: "Grayscale 36 to 40 value representation.",
    };
  else if (val <= 50 && val > 40)
    return {
      imageSrc: Grayscale41to50,
      alt: "Grayscale 41 to 50 value representation.",
    };

  return {
    imageSrc: Undefined,
    alt: "Undefined grayscale value representation.",
  };
};

//  gives pattern image for the decibel values
export const getPattern = (val) => {
  if (val <= 0) return Grayscale0;
  else if (val <= 5 && val >= 1) return Grayscale1to5;
  else if (val <= 10 && val >= 6) return Grayscale6to10;
  else if (val <= 15 && val >= 11) return Grayscale11to15;
  else if (val <= 20 && val >= 16) return Grayscale16to20;
  else if (val <= 25 && val >= 21) return Grayscale21to25;
  else if (val <= 30 && val >= 26) return Grayscale26to30;
  else if (val <= 35 && val >= 31) return Grayscale31to35;
  else if (val <= 40 && val >= 36) return Grayscale36to40;
  else if (val <= 50 && val >= 41) return Grayscale41to50;
  return Undefined;
};

//  gives pattern image for the total deviation and pattern deviation charts
export const getDeviationChartPattern = (val) => {
  if (val < 0.5) return LessThan05;
  else if (val < 1) return LessThan1;
  else if (val < 2) return LessThan2;
  else if (val < 5) return LessThan5;
  return undefined;
};

// calculate age from date of birth
export const calculateAge = (dateValue) => {
  if (!dateValue) return "-";

  const dob = new Date(dateValue);
  const diff = Date.now() - dob;
  const diffMs = new Date(diff);

  return Math.abs(diffMs.getUTCFullYear() - 1970);
};

// calculate age from exam date
export const calculateAgeFromExamDate = (dateValue, examDate) => {
  if (!dateValue || !examDate) return "-";

  const dob = new Date(dateValue);
  const diff = new Date(examDate) - dob;
  const diffMs = new Date(diff);

  return Math.abs(diffMs.getUTCFullYear() - 1970);
};

// calculate duration in seconds
export const calculateDurationInSeconds = (startDateValue, endDateValue) => {
  const startDate = new Date(startDateValue);
  const endDate = new Date(endDateValue);

  // seconds difference between end and start date
  const diffS = Math.abs(endDate - startDate) / 1000;

  return diffS;
};

// calculate duration of exam
export const calculateDurationForDisplay = (elapsedTimeSeconds) => {
  if (elapsedTimeSeconds <= 0) return "00:00";
  // seconds
  let timeInSeconds = elapsedTimeSeconds;

  // days
  const days = Math.floor(timeInSeconds / 86400);
  timeInSeconds -= days * 86400;

  // hours
  const hours = Math.floor(timeInSeconds / 3600) % 24;
  timeInSeconds -= hours * 3600;

  //  minutes
  const minutes = Math.floor(timeInSeconds / 60) % 60;
  timeInSeconds -= minutes * 60;

  //  seconds
  const seconds = Math.floor(timeInSeconds % 60);

  // format a string with what's not 0
  var result = "";

  let remain = hours % 100;
  if (hours) result += (remain < 10 ? "0" + remain : remain) + ":";

  remain = minutes % 100;
  result += (remain < 10 ? "0" + remain : remain) + ":";

  remain = seconds % 100;
  result += remain < 10 ? "0" + remain : remain;

  return result;
};

// gives formatted exam type
export const getExamType = (exam) => {
  if (exam?.visualFieldSections?.[0]?.algorithm === "OBSOLETE_FAST")
    return "Fast";
  else if (exam?.visualFieldSections?.[0]?.algorithm === "FULL_THRESHOLD")
    return "Full Threshold";
  else if (exam?.visualFieldSections?.[0]?.algorithm === "STANDARD_THRESHOLD")
    return "Standard Threshold";
  else if (exam?.visualFieldSections?.[0]?.algorithm === "FAST_THRESHOLD")
    return "Fast Threshold";
  else if (exam?.visualFieldSections?.[0]?.algorithm === "SCREEN_FAST")
    return "RLFast";
  else if (exam?.visualFieldSections?.[0]?.algorithm === "FULL_120")
    return "Single Threshold";
  else if (exam?.visualFieldSections?.[0]?.algorithm === "ESTERMAN_FAST")
    return "Esterman";
  else if (exam?.contrastSensitivitySections?.[0]?.algorithm === "PELLI_ROBSON")
    return "Pelli Robson";
  else if (exam?.contrastSensitivitySections?.[0]?.algorithm === "TUMBLING_E")
    return "Tumbling E";
  else if (exam?.colorVisionSections?.[0]?.algorithm === "D_15") return "D-15";
  // if (exam?.visualAcuitySections?.[0]?.position === "UNDEFINED")
  //   return "Undefined";
  if (exam?.visualAcuitySections?.[0]?.algorithm === "TUMBLING_E")
    return "VisualAcuity";
  if (exam?.visualAcuitySections?.[0]?.algorithm === "LANDOLT_C")
    return "Landolt C";
  if (exam?.visualAcuitySections?.[0]?.algorithm === "SNELLEN")
    return "Snellen";
  return "N.A";
};

export const getExamAlgorithmTranslation = (exam) => {
  const translationDic = {
    OBSOLETE_FAST: "word_fast",
    SCREEN_FAST: "word_screen_fast",
    FULL_THRESHOLD: "full_threshold",
    STANDARD_THRESHOLD: "standard_threshold",
    FAST_THRESHOLD: "fast_threshold",
    FULL_120: "single_threshold_title",
    ESTERMAN_FAST: "esterman_report_title",
    D_15: "D-15",
    PELLI_ROBSON: "word_pelli_robson",
    TUMBLING_E: "word_tumbling",
    LANDOLT_C: "word_landolt",
    SNELLEN: "word_snellen",
    ACUITY: "word_acuity_tumbling",
  };

  const examSectionsKeys = Object.keys(exam).filter((key) =>
    key.includes("Sections")
  );
  const currentExamSectionsKey = examSectionsKeys?.filter(
    (key) => exam[key].length > 0
  );
  if (!exam[currentExamSectionsKey]) return "N/A";
  if (exam[currentExamSectionsKey]?.[0]?.algorithm === "TUMBLING_E") {
    if (exam?.visualAcuitySections?.length) return translationDic.ACUITY;
    return translationDic.TUMBLING_E;
  }
  return translationDic[exam[currentExamSectionsKey]?.[0]?.algorithm] ?? "N/A";
};

export const getExamTypeAbbreviation = (exam) => {
  if (exam?.visualFieldSections?.length) return "VF";
  else if (exam?.colorVisionSections?.length) return "CV";
  else if (exam?.contrastSensitivitySections?.length) return "CS";
  else if (exam?.visualAcuitySections?.length) return "VA";
};

export const getExamTypeTranslation = (exam) => {
  if (exam?.visualFieldSections?.length) return "exams_column_visual_field";
  else if (exam?.colorVisionSections?.length) return "color_vision_title";
  else if (exam?.contrastSensitivitySections?.length)
    return "contrast_sensitivity_title";
  else if (exam?.visualAcuitySections?.length) return "visual_acuity_title";
};

export const getCurrentSectionKey = (examData) => {
  const examSectionsKeys = Object.keys(examData).filter((key) =>
    key.includes("Sections")
  );
  const currentExamSectionsKey = examSectionsKeys.filter(
    (key) => examData[key].length > 0
  );
  if (currentExamSectionsKey.length !== 1) return null;
  return currentExamSectionsKey;
};

// gets the exam's patient name
export const getExamPatientName = (exam) =>
  `${exam.firstName} ${exam.lastName}`;

// get visual field
export const getVisualField = (exam) => {
  // check not null
  if (exam?.visualFieldSections?.[0]?.gridType) {
    // format the value from backend
    if (exam?.visualFieldSections?.[0]?.gridType === "G_24_2") return "24-2";
    else if (exam?.visualFieldSections?.[0]?.gridType === "G_24_2_C")
      return "24-2C";
    else if (exam?.visualFieldSections?.[0]?.gridType === "G_30_2")
      return "30-2";
    else if (exam?.visualFieldSections?.[0]?.gridType === "G_10_2")
      return "10-2";
    else if (exam?.visualFieldSections?.[0]?.gridType === "G_120") return "120";
  }

  return "N.A";
};

// finds the term of arthmetic progression given by backend as x or y value and maps it to correct x and y value for graphs
export const getSequenceValue = (val) => {
  return (val + 3) / 6;
};

// gets the values for the inner elements within the grid of the pattern graph
export const getValue1x1 = (value, neighbours) => {
  let weights = 4;
  if (neighbours.left !== undefined) weights += 2;
  if (neighbours.topLeft !== undefined) weights += 1;
  if (neighbours.top !== undefined) weights += 2;
  const newValue =
    4 * value +
    2 * (neighbours.left || 0) +
    1 * (neighbours.topLeft || 0) +
    2 * (neighbours.top || 0);
  return newValue / weights;
};

export const getValue2x1 = (value, neighbours) => {
  let weights = 6;
  if (neighbours.top !== undefined) weights += 3;
  const newValue = 6 * value + 3 * (neighbours.top || 0);
  return newValue / weights;
};

export const getValue3x1 = (value, neighbours) => {
  let weights = 4;
  if (neighbours.right !== undefined) weights += 2;
  if (neighbours.topRight !== undefined) weights += 1;
  if (neighbours.top !== undefined) weights += 2;
  const newValue =
    4 * value +
    2 * (neighbours.right || 0) +
    1 * (neighbours.topRight || 0) +
    2 * (neighbours.top || 0);
  return newValue / weights;
};

export const getValue1x2 = (value, neighbours) => {
  let weights = 6;
  if (neighbours.left !== undefined) weights += 3;
  const newValue = 6 * value + 3 * (neighbours.left || 0);
  return newValue / weights;
};

export const getValue2x2 = (value) => value;

export const getValue3x2 = (value, neighbours) => {
  let weights = 6;
  if (neighbours.right !== undefined) weights += 3;
  const newValue = 6 * value + 3 * (neighbours.right || 0);
  return newValue / weights;
};

export const getValue1x3 = (value, neighbours) => {
  let weights = 4;
  if (neighbours.left !== undefined) weights += 2;
  if (neighbours.bottomLeft !== undefined) weights += 1;
  if (neighbours.bottom !== undefined) weights += 2;
  const newValue =
    4 * value +
    2 * (neighbours.left || 0) +
    1 * (neighbours.bottomLeft || 0) +
    2 * (neighbours.bottom || 0);
  return newValue / weights;
};

export const getValue2x3 = (value, neighbours) => {
  let weights = 6;
  if (neighbours.bottom !== undefined) weights += 3;
  const newValue = 6 * value + 3 * (neighbours.bottom || 0);
  return newValue / weights;
};

export const getValue3x3 = (value, neighbours) => {
  let weights = 4;
  if (neighbours.right !== undefined) weights += 2;
  if (neighbours.bottomRight !== undefined) weights += 1;
  if (neighbours.bottom !== undefined) weights += 2;
  const newValue =
    4 * value +
    2 * (neighbours.right || 0) +
    1 * (neighbours.bottomRight || 0) +
    2 * (neighbours.bottom || 0);
  return newValue / weights;
};

export const getValueExtendedTopRight = (value, neighbours) => {
  let weights = 1;
  if (neighbours.bottomRight !== undefined) weights += 1;
  if (neighbours.bottom !== undefined) weights += 1;
  const newValue =
    1 * value +
    1 * (neighbours.bottomRight || 0) +
    1 * (neighbours.bottom || 0);
  return newValue / weights;
};

export const getValueExtendedTopLeft = (value, neighbours) => {
  let weights = 1;
  if (neighbours.bottomLeft !== undefined) weights += 1;
  if (neighbours.bottom !== undefined) weights += 1;
  const newValue =
    1 * value + 1 * (neighbours.bottomLeft || 0) + 1 * (neighbours.bottom || 0);
  return newValue / weights;
};

export const getValueExtendedBottomRight = (value, neighbours) => {
  let weights = 1;
  if (neighbours.topRight !== undefined) weights += 1;
  if (neighbours.top !== undefined) weights += 1;
  const newValue =
    1 * value + 1 * (neighbours.topRight || 0) + 1 * (neighbours.top || 0);
  return newValue / weights;
};

export const getValueExtendedBottomLeft = (value, neighbours) => {
  let weights = 1;
  if (neighbours.topLeft !== undefined) weights += 1;
  if (neighbours.top !== undefined) weights += 1;
  const newValue =
    1 * value + 1 * (neighbours.topLeft || 0) + 1 * (neighbours.top || 0);
  return newValue / weights;
};

/**
 * Method to get the length of an array filled with undefined values.
 * @param {*} gridSize The grid size (which will be the length of the inner arrays)
 * @param {*} rowValuesQty The quantity of defined values within an inner row.
 * @param {*} rowExtendedQty The quantity of possibly extended values within an inner row (these may be defined or undefined). This is used when the chart is not symmetric (like for the 24-2 type, for example). If the chart using this method is symmetric (like for the 30-2 type), the value should be 0.
 */
export const getUndefinedArrayLength = (
  gridSize,
  rowValuesQty,
  rowExtendedQty = 0
) => {
  // Remove from the total array length (gridSize), the quantity of values that are defined, and the values that are possibly defined.
  return gridSize - rowValuesQty - rowExtendedQty;
};

/**
 * Method to get the absolute value from a given value.
 * @param {*} val The value.
 * @returns The absolute value.
 */
const abs = (val) => Math.abs(val);

// Instantiate an array of arrays with the expected grid dimensions.
/**
 * Instantiate an array of arrays with the expected grid dimensions.
 * @param {*} gridSize The grid size.
 * @returns An array of size gridSize, with each index occupied by an array of gridSize.
 */
const initialGridDefinition = (gridSize) => {
  return new Array(gridSize)
    .fill(undefined)
    .map(() => new Array(gridSize).fill(undefined));
};

/**
 * Method to build the 24-2 type quadrant grid.
 * @param {*} points The values used to build the grid, having each X and Y position representing a points. These values will be the base of a new grid, with more sections.
 * @param {*} extended If it should extend the grid for this quadrant.
 * @returns An array of arrays, representing the grid of a quadrant.
 */
export const build24Dash2Grid = (points, extended = false) => {
  const gridSize = 14; // grid dimension

  // Inject the initial values into the array of arrays (this varies per exam type).
  // We will consider the abs values for the X and Y position, and inject them on a new grid that has "gridSize" as dimension.
  // This way, the grid will be always built as if the values were on the top-right grid (with X and Y positives), then, the grid is mirrored according to how we want it to be displayed (according to the quadrant).
  const injectInitialValues = (array, points, extended) => {
    // Mapping all values to a dictionary, for each X and Y pairs within the set os points received.
    const mappedPoints = points.reduce((acc, cur) => {
      const key = `valX${Math.round(Math.abs(cur.x))}Y${Math.round(Math.abs(cur.y))}`;
      if (acc[key])
        acc[key].decibelValue =
          (acc[key].decibelValue + cur.decibelValue) / 2.0;
      else acc[key] = cur;
      return acc;
    }, {});

    for (const point of Object.keys(mappedPoints)) {
      if (!mappedPoints[point]?.completed)
        mappedPoints[point].decibelValue = undefined;
    }

    // Values in which Y = 1 (in the original X and Y position values).
    array[0][0] = mappedPoints.valX1Y1?.decibelValue;
    array[0][1] = mappedPoints.valX1Y1?.decibelValue;
    array[1][0] = mappedPoints.valX1Y1?.decibelValue;
    array[1][1] = mappedPoints.valX1Y1?.decibelValue;

    array[0][3] = mappedPoints.valX2Y1?.decibelValue;
    array[0][4] = mappedPoints.valX2Y1?.decibelValue;
    array[1][3] = mappedPoints.valX2Y1?.decibelValue;
    array[1][4] = mappedPoints.valX2Y1?.decibelValue;

    array[0][6] = mappedPoints.valX3Y1?.decibelValue;
    array[0][7] = mappedPoints.valX3Y1?.decibelValue;
    array[1][6] = mappedPoints.valX3Y1?.decibelValue;
    array[1][7] = mappedPoints.valX3Y1?.decibelValue;

    array[0][9] = mappedPoints.valX4Y1?.decibelValue;
    array[0][10] = mappedPoints.valX4Y1?.decibelValue;
    array[1][9] = mappedPoints.valX4Y1?.decibelValue;
    array[1][10] = mappedPoints.valX4Y1?.decibelValue;

    if (extended) {
      array[0][12] = mappedPoints.valX5Y1?.decibelValue;
      array[0][13] = mappedPoints.valX5Y1?.decibelValue;
      array[1][12] = mappedPoints.valX5Y1?.decibelValue;
      array[1][13] = mappedPoints.valX5Y1?.decibelValue;
    }

    // Values in which Y = 2 (in the original X and Y position values).
    array[3][0] = mappedPoints.valX1Y2?.decibelValue;
    array[3][1] = mappedPoints.valX1Y2?.decibelValue;
    array[4][0] = mappedPoints.valX1Y2?.decibelValue;
    array[4][1] = mappedPoints.valX1Y2?.decibelValue;

    array[3][3] = mappedPoints.valX2Y2?.decibelValue;
    array[3][4] = mappedPoints.valX2Y2?.decibelValue;
    array[4][3] = mappedPoints.valX2Y2?.decibelValue;
    array[4][4] = mappedPoints.valX2Y2?.decibelValue;

    array[3][6] = mappedPoints.valX3Y2?.decibelValue;
    array[3][7] = mappedPoints.valX3Y2?.decibelValue;
    array[4][6] = mappedPoints.valX3Y2?.decibelValue;
    array[4][7] = mappedPoints.valX3Y2?.decibelValue;

    array[3][9] = mappedPoints.valX4Y2?.decibelValue;
    array[3][10] = mappedPoints.valX4Y2?.decibelValue;
    array[4][9] = mappedPoints.valX4Y2?.decibelValue;
    if (extended) {
      array[4][10] = mappedPoints.valX4Y2?.decibelValue;
    }

    // Values in which Y = 3 (in the original X and Y position values).
    array[6][0] = mappedPoints.valX1Y3?.decibelValue;
    array[6][1] = mappedPoints.valX1Y3?.decibelValue;
    array[7][0] = mappedPoints.valX1Y3?.decibelValue;
    array[7][1] = mappedPoints.valX1Y3?.decibelValue;

    array[6][3] = mappedPoints.valX2Y3?.decibelValue;
    array[6][4] = mappedPoints.valX2Y3?.decibelValue;
    array[7][3] = mappedPoints.valX2Y3?.decibelValue;
    array[7][4] = mappedPoints.valX2Y3?.decibelValue;

    array[6][6] = mappedPoints.valX3Y3?.decibelValue;
    array[6][7] = mappedPoints.valX3Y3?.decibelValue;
    array[7][6] = mappedPoints.valX3Y3?.decibelValue;
    array[7][7] = mappedPoints.valX3Y3?.decibelValue;

    // Values in which Y = 4 (in the original X and Y position values).
    array[9][0] = mappedPoints.valX1Y4?.decibelValue;
    array[9][1] = mappedPoints.valX1Y4?.decibelValue;
    array[10][0] = mappedPoints.valX1Y4?.decibelValue;
    array[10][1] = mappedPoints.valX1Y4?.decibelValue;

    array[9][3] = mappedPoints.valX2Y4?.decibelValue;
    array[9][4] = mappedPoints.valX2Y4?.decibelValue;
    array[10][3] = mappedPoints.valX2Y4?.decibelValue;

    return array;
  };

  // Inject the final values into the array of arrays (this varies per exam type).
  const injectFinalValues = (array, extended) => {
    // Method that will "merge" the initial values with their horizontal undefined values.
    const mergeInitialValuesHorizontally = () => {
      // Values in which Y = 0 or Y = 1.
      array[0][2] = 0.5 * array[0][1] + 0.5 * array[0][3];
      array[1][2] = 0.5 * array[1][1] + 0.5 * array[1][3];
      array[0][5] = 0.5 * array[0][4] + 0.5 * array[0][6];
      array[1][5] = 0.5 * array[1][4] + 0.5 * array[1][6];
      array[0][8] = 0.5 * array[0][7] + 0.5 * array[0][9];
      array[1][8] = 0.5 * array[1][7] + 0.5 * array[1][9];
      array[0][11] = 0.5 * array[0][10] + 0.5 * array[0][12];
      array[1][11] = 0.5 * array[1][10] + 0.5 * array[1][12];

      // Values in which Y = 3 or Y = 4.
      array[3][2] = 0.5 * array[3][1] + 0.5 * array[3][3];
      array[4][2] = 0.5 * array[4][1] + 0.5 * array[4][3];
      array[3][5] = 0.5 * array[3][4] + 0.5 * array[3][6];
      array[4][5] = 0.5 * array[4][4] + 0.5 * array[4][6];
      array[3][8] = 0.5 * array[3][7] + 0.5 * array[3][9];
      array[4][8] = 0.5 * array[4][7] + 0.5 * array[4][9];
      if (extended) {
        array[3][11] = array[3][10];
      }

      // Values in which Y = 6 or Y = 7.
      array[6][2] = 0.5 * array[6][1] + 0.5 * array[6][3];
      array[7][2] = 0.5 * array[7][1] + 0.5 * array[7][3];
      array[6][5] = 0.5 * array[6][4] + 0.5 * array[6][6];
      array[7][5] = 0.5 * array[7][4] + 0.5 * array[7][6];
      array[6][8] = array[6][7];

      // Values in which Y = 9 or Y = 10.
      array[9][2] = 0.5 * array[9][1] + 0.5 * array[9][3];
      array[10][2] = 0.5 * array[10][1] + 0.5 * array[10][3];
      array[9][5] = array[9][4];
    };

    // Method that will "merge" the initial values on their vertical undefined values.
    const mergeInitialValuesVertically = () => {
      // Values in which X = 0 or X = 1.
      array[2][0] = 0.5 * array[1][0] + 0.5 * array[3][0];
      array[2][1] = 0.5 * array[1][1] + 0.5 * array[3][1];
      array[5][0] = 0.5 * array[4][0] + 0.5 * array[6][0];
      array[5][1] = 0.5 * array[4][1] + 0.5 * array[6][1];
      array[8][0] = 0.5 * array[7][0] + 0.5 * array[9][0];
      array[8][1] = 0.5 * array[7][1] + 0.5 * array[9][1];

      // Values in which X = 3 or X = 4.
      array[2][3] = 0.5 * array[1][3] + 0.5 * array[3][3];
      array[2][4] = 0.5 * array[1][4] + 0.5 * array[3][4];
      array[5][3] = 0.5 * array[4][3] + 0.5 * array[6][3];
      array[5][4] = 0.5 * array[4][4] + 0.5 * array[6][4];
      array[8][3] = 0.5 * array[7][3] + 0.5 * array[9][3];
      array[8][4] = 0.5 * array[7][4] + 0.5 * array[9][4];

      // Values in which X = 6 or X = 7.
      array[2][6] = 0.5 * array[1][6] + 0.5 * array[3][6];
      array[2][7] = 0.5 * array[1][7] + 0.5 * array[3][7];
      array[5][6] = 0.5 * array[4][6] + 0.5 * array[6][6];
      array[5][7] = 0.5 * array[4][7] + 0.5 * array[6][7];
      array[8][6] = array[7][6];

      // Values in which X = 9 or X = 10.
      array[2][9] = 0.5 * array[1][9] + 0.5 * array[3][9];
      array[2][10] = 0.5 * array[1][10] + 0.5 * array[3][10];
      array[5][9] = array[4][9];

      // Values in which X = 12.
      if (extended) {
        array[2][12] = array[1][12];
      }
    };

    // Fill the inner values that are not yet defined, based on the top-right-bottom-left neighbours. This depends on initialGridDefinition, injectInitialValues, mergeInitialValuesHorizontally and mergeInitialValuesVertically.
    // Method that will "merge"/"blend" the inner gap values, to be a representation of its neighbours (top-right-bottom-left).
    const mergeInnerGaps = () => {
      // Calculates the new value based on the top-right-bottom-left neighbour values.
      const calculateBasedOnDirectNeighbours = (array, x, y) => {
        return (
          0.25 * array[y - 1][x] +
          0.25 * array[y + 1][x] +
          0.25 * array[y][x - 1] +
          0.25 * array[y][x + 1]
        );
      };

      // Values in which Y = 2.
      array[2][2] = calculateBasedOnDirectNeighbours(array, 2, 2);
      array[2][5] = calculateBasedOnDirectNeighbours(array, 5, 2);
      array[2][8] = calculateBasedOnDirectNeighbours(array, 8, 2);
      if (extended) {
        array[2][11] = calculateBasedOnDirectNeighbours(array, 11, 2);
      }

      // Values in which Y = 5.
      array[5][2] = calculateBasedOnDirectNeighbours(array, 2, 5);
      array[5][5] = calculateBasedOnDirectNeighbours(array, 5, 5);
      array[5][8] = calculateBasedOnDirectNeighbours(array, 8, 5);

      // Values in which Y = 8.
      array[8][2] = calculateBasedOnDirectNeighbours(array, 2, 8);
      array[8][5] = calculateBasedOnDirectNeighbours(array, 5, 8);
    };

    // Update the values on the array (grid).
    mergeInitialValuesHorizontally();
    mergeInitialValuesVertically();
    mergeInnerGaps();

    return array;
  };

  // We first build a grid filled with undefined values.
  const rawGrid = initialGridDefinition(gridSize);
  // We then inject the initial values, based on the decibel values for the given quadrant points.
  const initialGrid = injectInitialValues(rawGrid, points, extended);
  // We then inject the final values, which are besed on the definition of the initial values.
  const finalGrid = injectFinalValues(initialGrid, extended);

  // We reverse it, so the grid system used to build it matches the axis point definitions on the pressedPoints.
  // For example: the 1,1 position on the grid would be place on a row at the top of the grid; but the 1,1 position on the axis world would coordinate the bottom; so we reverse the grid, so that it matches the axis world when displayed.
  return finalGrid.reverse();
};

/**
 * Method to build the 30-2 type quadrant grid.
 * @param {*} points The values used to build the grid, having each X and Y position representing a points. These values will be the base of a new grid, with more sections.
 * @returns An array of arrays, representing the grid of a quadrant.
 */
export const build30Dash2Grid = (points) => {
  const gridSize = 14; // grid dimension

  // Inject the initial values into the array of arrays (this varies per exam type).
  // We will consider the abs values for the X and Y position, and inject them on a new grid that has "gridSize" as dimension.
  // This way, the grid will be always built as if the values were on the top-right grid (with X and Y positives), then, the grid is mirrored according to how we want it to be displayed (according to the quadrant).
  const injectInitialValues = (array, points) => {
    // Mapping all values to a dictionary, for each X and Y pairs within the set os points received.
    const mappedPoints = points.reduce(
      (acc, cur) => ({ ...acc, [`valX${abs(cur.x)}Y${abs(cur.y)}`]: cur }),
      {}
    );

    for (const point of Object.keys(mappedPoints)) {
      if (!mappedPoints[point]?.completed)
        mappedPoints[point].decibelValue = undefined;
    }

    // Values in which Y = 1 (in the original X and Y position values).
    array[0][0] = mappedPoints.valX1Y1?.decibelValue;
    array[0][1] = mappedPoints.valX1Y1?.decibelValue;
    array[1][0] = mappedPoints.valX1Y1?.decibelValue;
    array[1][1] = mappedPoints.valX1Y1?.decibelValue;

    array[0][4] = mappedPoints.valX2Y1?.decibelValue;
    array[1][4] = mappedPoints.valX2Y1?.decibelValue;

    array[0][7] = mappedPoints.valX3Y1?.decibelValue;
    array[1][7] = mappedPoints.valX3Y1?.decibelValue;

    array[0][10] = mappedPoints.valX4Y1?.decibelValue;
    array[1][10] = mappedPoints.valX4Y1?.decibelValue;

    array[0][13] = mappedPoints.valX5Y1?.decibelValue;
    array[1][13] = mappedPoints.valX5Y1?.decibelValue;

    // Values in which Y = 2 (in the original X and Y position values).
    array[4][0] = mappedPoints.valX1Y2?.decibelValue;
    array[4][1] = mappedPoints.valX1Y2?.decibelValue;

    array[4][4] = mappedPoints.valX2Y2?.decibelValue;

    array[4][7] = mappedPoints.valX3Y2?.decibelValue;

    array[4][10] = mappedPoints.valX4Y2?.decibelValue;

    array[4][13] = mappedPoints.valX5Y2?.decibelValue;

    // Values in which Y = 3 (in the original X and Y position values).
    array[7][0] = mappedPoints.valX1Y3?.decibelValue;
    array[7][1] = mappedPoints.valX1Y3?.decibelValue;

    array[7][4] = mappedPoints.valX2Y3?.decibelValue;

    array[7][8] = mappedPoints.valX3Y3?.decibelValue;

    array[7][11] = mappedPoints.valX4Y3?.decibelValue;

    // Values in which Y = 4 (in the original X and Y position values).
    array[10][0] = mappedPoints.valX1Y4?.decibelValue;
    array[10][1] = mappedPoints.valX1Y4?.decibelValue;

    array[10][4] = mappedPoints.valX2Y4?.decibelValue;

    array[10][8] = mappedPoints.valX3Y4?.decibelValue;

    // Values in which Y = 5 (in the original X and Y position values).
    array[13][0] = mappedPoints.valX1Y5?.decibelValue;
    array[13][1] = mappedPoints.valX1Y5?.decibelValue;

    array[13][4] = mappedPoints.valX2Y5?.decibelValue;

    return array;
  };

  // Inject the final values into the array of arrays (this varies per exam type).
  const injectFinalValues = (array) => {
    // Method that will "merge" the initial values with their horizontal undefined values.
    const mergeInitialValuesHorizontally = () => {
      // Values in which Y = 0 or Y = 1.
      array[0][2] = 0.7 * array[0][1] + 0.3 * array[0][4];
      array[1][2] = 0.7 * array[1][1] + 0.3 * array[1][4];
      array[0][3] = 0.3 * array[0][1] + 0.7 * array[0][4];
      array[1][3] = 0.3 * array[1][1] + 0.7 * array[1][4];

      array[0][5] = 0.7 * array[0][4] + 0.3 * array[0][7];
      array[1][5] = 0.7 * array[1][4] + 0.3 * array[1][7];
      array[0][6] = 0.3 * array[0][4] + 0.7 * array[0][7];
      array[1][6] = 0.3 * array[1][4] + 0.7 * array[1][7];

      array[0][8] = 0.7 * array[0][7] + 0.3 * array[0][10];
      array[1][8] = 0.7 * array[1][7] + 0.3 * array[1][10];
      array[0][9] = 0.3 * array[0][7] + 0.7 * array[0][10];
      array[1][9] = 0.3 * array[1][7] + 0.7 * array[1][10];

      array[0][11] = 0.7 * array[0][10] + 0.3 * array[0][13];
      array[1][11] = 0.7 * array[1][10] + 0.3 * array[1][13];
      array[0][12] = 0.3 * array[0][10] + 0.7 * array[0][13];
      array[1][12] = 0.3 * array[1][10] + 0.7 * array[1][13];

      // Values in which Y = 4.
      array[4][2] = 0.7 * array[4][1] + 0.3 * array[4][4];
      array[4][3] = 0.3 * array[4][1] + 0.7 * array[4][4];

      array[4][5] = 0.7 * array[4][4] + 0.3 * array[4][7];
      array[4][6] = 0.3 * array[4][1] + 0.7 * array[4][4];

      array[4][8] = 0.7 * array[4][7] + 0.3 * array[4][10];
      array[4][9] = 0.3 * array[4][7] + 0.7 * array[4][10];

      array[4][11] = 0.7 * array[4][10] + 0.3 * array[4][13];
      array[4][12] = 0.3 * array[4][10] + 0.7 * array[4][13];

      // Values in which Y = 7.
      array[7][2] = 0.7 * array[7][1] + 0.3 * array[7][4];
      array[7][3] = 0.3 * array[7][1] + 0.7 * array[7][4];

      array[7][5] = 0.7 * array[7][4] + 0.3 * array[7][8];
      array[7][6] = 0.5 * array[7][4] + 0.5 * array[7][8];
      array[7][7] = 0.3 * array[7][4] + 0.7 * array[7][8];

      array[7][9] = 0.7 * array[7][8] + 0.3 * array[7][11];
      array[7][10] = 0.3 * array[7][8] + 0.7 * array[7][11];

      // Values in which Y = 10.
      array[10][2] = 0.7 * array[10][1] + 0.3 * array[10][4];
      array[10][3] = 0.3 * array[10][1] + 0.7 * array[10][4];

      array[10][5] = 0.7 * array[10][4] + 0.3 * array[10][8];
      array[10][6] = 0.5 * array[10][4] + 0.5 * array[10][8];
      array[10][7] = 0.3 * array[10][4] + 0.7 * array[10][8];

      // Values in which Y = 13.
      array[13][2] = 0.7 * array[13][1] + 0.3 * array[13][4];
      array[13][3] = 0.3 * array[13][1] + 0.7 * array[13][4];
    };

    // Method that will "merge" the initial values on their vertical undefined values.
    const mergeInitialValuesVertically = () => {
      // Values in which X = 0 or X = 1.
      array[2][0] = 0.7 * array[1][0] + 0.3 * array[4][0];
      array[2][1] = 0.7 * array[1][1] + 0.3 * array[4][1];
      array[3][0] = 0.3 * array[1][0] + 0.7 * array[4][0];
      array[3][1] = 0.3 * array[1][1] + 0.7 * array[4][1];

      array[5][0] = 0.7 * array[4][0] + 0.3 * array[7][0];
      array[5][1] = 0.7 * array[4][1] + 0.3 * array[7][1];
      array[6][0] = 0.3 * array[4][0] + 0.7 * array[7][0];
      array[6][1] = 0.3 * array[4][1] + 0.7 * array[7][1];

      array[8][0] = 0.7 * array[7][0] + 0.3 * array[10][0];
      array[8][1] = 0.7 * array[7][1] + 0.3 * array[10][1];
      array[9][0] = 0.3 * array[7][0] + 0.7 * array[10][0];
      array[9][1] = 0.3 * array[7][1] + 0.7 * array[10][1];

      array[11][0] = 0.7 * array[10][0] + 0.3 * array[13][0];
      array[11][1] = 0.7 * array[10][1] + 0.3 * array[13][1];
      array[12][0] = 0.3 * array[10][0] + 0.7 * array[13][0];
      array[12][1] = 0.3 * array[10][1] + 0.7 * array[13][1];

      // Values in which X = 4.
      array[2][4] = 0.7 * array[1][4] + 0.3 * array[4][4];
      array[3][4] = 0.3 * array[1][4] + 0.7 * array[4][4];

      array[5][4] = 0.7 * array[4][4] + 0.3 * array[7][4];
      array[6][4] = 0.3 * array[4][4] + 0.7 * array[7][4];

      array[8][4] = 0.7 * array[7][4] + 0.3 * array[10][4];
      array[9][4] = 0.3 * array[7][4] + 0.7 * array[10][4];

      array[11][4] = 0.7 * array[10][4] + 0.3 * array[13][4];
      array[12][4] = 0.3 * array[10][4] + 0.7 * array[13][4];

      // Values in which X = 7.
      array[2][7] = 0.7 * array[1][7] + 0.3 * array[4][7];
      array[3][7] = 0.3 * array[1][7] + 0.7 * array[4][7];

      // Values in which X = 8.
      array[8][8] = 0.7 * array[7][8] + 0.3 * array[10][8];
      array[9][8] = 0.3 * array[7][8] + 0.7 * array[10][8];

      // Values in which X = 10.
      array[2][10] = 0.7 * array[1][10] + 0.3 * array[4][10];
      array[3][10] = 0.3 * array[1][10] + 0.7 * array[4][10];

      // Values in which X = 13.
      array[2][13] = 0.7 * array[1][13] + 0.3 * array[4][13];
      array[3][13] = 0.3 * array[1][13] + 0.7 * array[4][13];
    };

    // Fill the inner values that are not yet defined. This depends on initialGridDefinition, injectInitialValues, mergeInitialValuesHorizontally and mergeInitialValuesVertically.
    // Method that will "merge"/"blend" the inner gap values, to be a representation of its neighbours (top-bottom-left).
    const mergeInnerGaps = () => {
      // Values in which Y = 2 or Y = 3.
      array[2][2] = 0.7 * array[1][2] + 0.3 * array[4][2];
      array[2][3] = 0.7 * array[1][3] + 0.3 * array[4][3];
      array[3][2] = 0.3 * array[1][2] + 0.7 * array[4][2];
      array[3][3] = 0.3 * array[1][3] + 0.7 * array[4][3];

      array[2][5] = 0.7 * array[1][5] + 0.3 * array[4][5];
      array[2][6] = 0.7 * array[1][6] + 0.3 * array[4][6];
      array[3][5] = 0.3 * array[1][5] + 0.7 * array[4][5];
      array[3][6] = 0.3 * array[1][6] + 0.7 * array[4][6];

      array[2][8] = 0.7 * array[1][8] + 0.3 * array[4][8];
      array[2][9] = 0.7 * array[1][9] + 0.3 * array[4][9];
      array[3][8] = 0.3 * array[1][8] + 0.7 * array[4][8];
      array[3][9] = 0.3 * array[1][9] + 0.7 * array[4][9];

      array[2][11] = 0.7 * array[1][11] + 0.3 * array[4][11];
      array[2][12] = 0.7 * array[1][12] + 0.3 * array[4][12];
      array[3][11] = 0.3 * array[1][11] + 0.7 * array[4][11];
      array[3][12] = 0.3 * array[1][12] + 0.7 * array[4][12];

      // // Values in which Y = 5 or Y = 6.
      array[5][2] = 0.7 * array[4][2] + 0.3 * array[7][2];
      array[5][3] = 0.7 * array[4][3] + 0.3 * array[7][3];
      array[6][2] = 0.3 * array[4][2] + 0.7 * array[7][2];
      array[6][3] = 0.3 * array[4][3] + 0.7 * array[7][3];

      array[5][5] = 0.7 * array[4][5] + 0.3 * array[7][5];
      array[5][6] = 0.7 * array[4][6] + 0.3 * array[7][6];
      array[5][7] = 0.7 * array[4][7] + 0.3 * array[7][7];
      array[5][8] = 0.7 * array[4][8] + 0.3 * array[7][8];
      array[5][9] = 0.7 * array[4][9] + 0.3 * array[7][9];
      array[5][10] = 0.7 * array[4][10] + 0.3 * array[7][10];
      array[5][11] = 0.7 * array[4][11] + 0.3 * array[7][11];

      array[6][5] = 0.3 * array[4][5] + 0.7 * array[7][5];
      array[6][6] = 0.3 * array[4][6] + 0.7 * array[7][6];
      array[6][7] = 0.3 * array[4][7] + 0.7 * array[7][7];
      array[6][8] = 0.3 * array[4][8] + 0.7 * array[7][8];
      array[6][9] = 0.3 * array[4][9] + 0.7 * array[7][9];
      array[6][10] = 0.3 * array[4][10] + 0.7 * array[7][10];
      array[6][11] = 0.3 * array[4][11] + 0.7 * array[7][11];

      // Values in which Y = 8 or Y = 9.
      array[8][2] = 0.7 * array[7][2] + 0.3 * array[10][2];
      array[8][3] = 0.7 * array[7][3] + 0.3 * array[10][3];
      array[9][2] = 0.3 * array[7][2] + 0.7 * array[10][2];
      array[9][3] = 0.3 * array[7][3] + 0.7 * array[10][3];

      array[8][5] = 0.7 * array[7][5] + 0.3 * array[10][5];
      array[8][6] = 0.7 * array[7][6] + 0.3 * array[10][6];
      array[8][7] = 0.7 * array[7][7] + 0.3 * array[10][7];
      array[9][5] = 0.3 * array[7][5] + 0.7 * array[10][5];
      array[9][6] = 0.3 * array[7][6] + 0.7 * array[10][6];
      array[9][7] = 0.3 * array[7][7] + 0.7 * array[10][7];

      array[8][9] = 0.7 * array[7][9] + 0.3 * array[10][9];
      array[9][9] = 0.3 * array[7][9] + 0.7 * array[10][9];

      // // Values in which Y = 11 or Y = 12.
      array[11][2] = 0.7 * array[10][2] + 0.3 * array[13][2];
      array[11][3] = 0.7 * array[10][3] + 0.3 * array[13][3];
      array[12][2] = 0.3 * array[10][2] + 0.7 * array[13][2];
      array[12][3] = 0.3 * array[10][3] + 0.7 * array[13][3];

      array[11][5] = array[11][4];
      array[12][5] = array[12][4];

      array[11][6] = array[11][5];
      array[12][6] = array[12][5];

      array[11][7] = array[10][7];
      array[11][8] = array[10][8];

      // Values in which Y = 10.
      array[10][9] = array[10][8];

      // Values in which Y = 8 or Y = 9.
      array[9][9] = 0.7 * array[10][9] + 0.3 * array[7][9];
      array[8][9] = 0.3 * array[10][9] + 0.7 * array[7][9];

      array[9][10] = array[9][9];
      array[8][10] = array[8][9];

      array[8][11] = array[7][11];

      // Values in which Y = 5 or Y = 6.
      array[6][12] = array[6][11];
      array[5][12] = array[5][11];
    };

    // Update the values on the array (grid).
    mergeInitialValuesHorizontally();
    mergeInitialValuesVertically();
    mergeInnerGaps();

    return array;
  };

  // We first build a grid filled with undefined values.
  const rawGrid = initialGridDefinition(gridSize);
  // We then inject the initial values, based on the decibel values for the given quadrant points.
  const initialGrid = injectInitialValues(rawGrid, points);
  // // We then inject the final values, which are besed on the definition of the initial values.
  const finalGrid = injectFinalValues(initialGrid);

  // We reverse it, so the grid system used to build it matches the axis point definitions on the pressedPoints.
  // For example: the 1,1 position on the grid would be place on a row at the top of the grid; but the 1,1 position on the axis world would coordinate the bottom; so we reverse the grid, so that it matches the axis world when displayed.
  return finalGrid.reverse();
};

/**
 * Method to build the 10-2 type quadrant grid.
 * @param {*} points The values used to build the grid, having each X and Y position representing a points. These values will be the base of a new grid, with more sections.
 * @returns An array of arrays, representing the grid of a quadrant.
 */
export const build10Dash2Grid = (points) => {
  const gridSize = 14; // grid dimension

  // Inject the initial values into the array of arrays (this varies per exam type).
  // We will consider the abs values for the X and Y position, and inject them on a new grid that has "gridSize" as dimension.
  // This way, the grid will be always built as if the values were on the top-right grid (with X and Y positives), then, the grid is mirrored according to how we want it to be displayed (according to the quadrant).
  const injectInitialValues = (array, points) => {
    // Mapping all values to a dictionary, for each X and Y pairs within the set os points received.
    const mappedPoints = points.reduce(
      (acc, cur) => ({ ...acc, [`valX${abs(cur.x)}Y${abs(cur.y)}`]: cur }),
      {}
    );

    for (const point of Object.keys(mappedPoints)) {
      if (!mappedPoints[point]?.completed)
        mappedPoints[point].decibelValue = undefined;
    }

    // Values in which Y = 1 (in the original X and Y position values).
    array[0][0] = mappedPoints.valX1Y1?.decibelValue;
    array[0][1] = mappedPoints.valX1Y1?.decibelValue;
    array[1][0] = mappedPoints.valX1Y1?.decibelValue;
    array[1][1] = mappedPoints.valX1Y1?.decibelValue;

    array[0][4] = mappedPoints.valX2Y1?.decibelValue;
    array[1][4] = mappedPoints.valX2Y1?.decibelValue;

    array[0][7] = mappedPoints.valX3Y1?.decibelValue;
    array[1][7] = mappedPoints.valX3Y1?.decibelValue;

    array[0][10] = mappedPoints.valX4Y1?.decibelValue;
    array[1][10] = mappedPoints.valX4Y1?.decibelValue;

    array[0][13] = mappedPoints.valX5Y1?.decibelValue;
    array[1][13] = mappedPoints.valX5Y1?.decibelValue;

    // Values in which Y = 2 (in the original X and Y position values).
    array[4][0] = mappedPoints.valX1Y2?.decibelValue;
    array[4][1] = mappedPoints.valX1Y2?.decibelValue;

    array[4][4] = mappedPoints.valX2Y2?.decibelValue;

    array[4][7] = mappedPoints.valX3Y2?.decibelValue;

    array[4][10] = mappedPoints.valX4Y2?.decibelValue;

    // For the 10-2 type, there's no value for this position (like we have on the 30-2, which is the one similar to 10-2).
    // This way, we represent it's value based on it's neighbours.
    const valX5Y2 =
      0.25 * mappedPoints.valX4Y3?.decibelValue +
      0.25 * mappedPoints.valX4Y2?.decibelValue +
      0.25 * mappedPoints.valX4Y1?.decibelValue +
      0.25 * mappedPoints.valX5Y1?.decibelValue;
    array[4][13] = valX5Y2;

    // Values in which Y = 3 (in the original X and Y position values).
    array[7][0] = mappedPoints.valX1Y3?.decibelValue;
    array[7][1] = mappedPoints.valX1Y3?.decibelValue;

    array[7][4] = mappedPoints.valX2Y3?.decibelValue;

    array[7][8] = mappedPoints.valX3Y3?.decibelValue;

    array[7][11] = mappedPoints.valX4Y3?.decibelValue;

    // Values in which Y = 4 (in the original X and Y position values).
    array[10][0] = mappedPoints.valX1Y4?.decibelValue;
    array[10][1] = mappedPoints.valX1Y4?.decibelValue;

    array[10][4] = mappedPoints.valX2Y4?.decibelValue;

    array[10][8] = mappedPoints.valX3Y4?.decibelValue;

    // Values in which Y = 5 (in the original X and Y position values).
    array[13][0] = mappedPoints.valX1Y5?.decibelValue;
    array[13][1] = mappedPoints.valX1Y5?.decibelValue;

    // For the 10-2 type, there's no value for this position (like we have on the 30-2, which is the one similar to 10-2).
    // This way, we represent it's value based on it's neighbours.
    const valX2Y5 =
      0.25 * mappedPoints.valX1Y4?.decibelValue +
      0.25 * mappedPoints.valX2Y4?.decibelValue +
      0.25 * mappedPoints.valX3Y4?.decibelValue +
      0.25 * mappedPoints.valX1Y5?.decibelValue;
    array[13][4] = valX2Y5;

    return array;
  };

  // Inject the final values into the array of arrays (this varies per exam type).
  const injectFinalValues = (array) => {
    // Method that will "merge" the initial values with their horizontal undefined values.
    const mergeInitialValuesHorizontally = () => {
      // Values in which Y = 0 or Y = 1.
      array[0][2] = 0.7 * array[0][1] + 0.3 * array[0][4];
      array[1][2] = 0.7 * array[1][1] + 0.3 * array[1][4];
      array[0][3] = 0.3 * array[0][1] + 0.7 * array[0][4];
      array[1][3] = 0.3 * array[1][1] + 0.7 * array[1][4];

      array[0][5] = 0.7 * array[0][4] + 0.3 * array[0][7];
      array[1][5] = 0.7 * array[1][4] + 0.3 * array[1][7];
      array[0][6] = 0.3 * array[0][4] + 0.7 * array[0][7];
      array[1][6] = 0.3 * array[1][4] + 0.7 * array[1][7];

      array[0][8] = 0.7 * array[0][7] + 0.3 * array[0][10];
      array[1][8] = 0.7 * array[1][7] + 0.3 * array[1][10];
      array[0][9] = 0.3 * array[0][7] + 0.7 * array[0][10];
      array[1][9] = 0.3 * array[1][7] + 0.7 * array[1][10];

      array[0][11] = 0.7 * array[0][10] + 0.3 * array[0][13];
      array[1][11] = 0.7 * array[1][10] + 0.3 * array[1][13];
      array[0][12] = 0.3 * array[0][10] + 0.7 * array[0][13];
      array[1][12] = 0.3 * array[1][10] + 0.7 * array[1][13];

      // Values in which Y = 4.
      array[4][2] = 0.7 * array[4][1] + 0.3 * array[4][4];
      array[4][3] = 0.3 * array[4][1] + 0.7 * array[4][4];

      array[4][5] = 0.7 * array[4][4] + 0.3 * array[4][7];
      array[4][6] = 0.3 * array[4][1] + 0.7 * array[4][4];

      array[4][8] = 0.7 * array[4][7] + 0.3 * array[4][10];
      array[4][9] = 0.3 * array[4][7] + 0.7 * array[4][10];

      array[4][11] = 0.7 * array[4][10] + 0.3 * array[4][13];
      array[4][12] = 0.3 * array[4][10] + 0.7 * array[4][13];

      // Values in which Y = 7.
      array[7][2] = 0.7 * array[7][1] + 0.3 * array[7][4];
      array[7][3] = 0.3 * array[7][1] + 0.7 * array[7][4];

      array[7][5] = 0.7 * array[7][4] + 0.3 * array[7][8];
      array[7][6] = 0.5 * array[7][4] + 0.5 * array[7][8];
      array[7][7] = 0.3 * array[7][4] + 0.7 * array[7][8];

      array[7][9] = 0.7 * array[7][8] + 0.3 * array[7][11];
      array[7][10] = 0.3 * array[7][8] + 0.7 * array[7][11];

      // Values in which Y = 10.
      array[10][2] = 0.7 * array[10][1] + 0.3 * array[10][4];
      array[10][3] = 0.3 * array[10][1] + 0.7 * array[10][4];

      array[10][5] = 0.7 * array[10][4] + 0.3 * array[10][8];
      array[10][6] = 0.5 * array[10][4] + 0.5 * array[10][8];
      array[10][7] = 0.3 * array[10][4] + 0.7 * array[10][8];

      // Values in which Y = 13.
      array[13][2] = 0.7 * array[13][1] + 0.3 * array[13][4];
      array[13][3] = 0.3 * array[13][1] + 0.7 * array[13][4];
    };

    // Method that will "merge" the initial values on their vertical undefined values.
    const mergeInitialValuesVertically = () => {
      // Values in which X = 0 or X = 1.
      array[2][0] = 0.7 * array[1][0] + 0.3 * array[4][0];
      array[2][1] = 0.7 * array[1][1] + 0.3 * array[4][1];
      array[3][0] = 0.3 * array[1][0] + 0.7 * array[4][0];
      array[3][1] = 0.3 * array[1][1] + 0.7 * array[4][1];

      array[5][0] = 0.7 * array[4][0] + 0.3 * array[7][0];
      array[5][1] = 0.7 * array[4][1] + 0.3 * array[7][1];
      array[6][0] = 0.3 * array[4][0] + 0.7 * array[7][0];
      array[6][1] = 0.3 * array[4][1] + 0.7 * array[7][1];

      array[8][0] = 0.7 * array[7][0] + 0.3 * array[10][0];
      array[8][1] = 0.7 * array[7][1] + 0.3 * array[10][1];
      array[9][0] = 0.3 * array[7][0] + 0.7 * array[10][0];
      array[9][1] = 0.3 * array[7][1] + 0.7 * array[10][1];

      array[11][0] = 0.7 * array[10][0] + 0.3 * array[13][0];
      array[11][1] = 0.7 * array[10][1] + 0.3 * array[13][1];
      array[12][0] = 0.3 * array[10][0] + 0.7 * array[13][0];
      array[12][1] = 0.3 * array[10][1] + 0.7 * array[13][1];

      // Values in which X = 4.
      array[2][4] = 0.7 * array[1][4] + 0.3 * array[4][4];
      array[3][4] = 0.3 * array[1][4] + 0.7 * array[4][4];

      array[5][4] = 0.7 * array[4][4] + 0.3 * array[7][4];
      array[6][4] = 0.3 * array[4][4] + 0.7 * array[7][4];

      array[8][4] = 0.7 * array[7][4] + 0.3 * array[10][4];
      array[9][4] = 0.3 * array[7][4] + 0.7 * array[10][4];

      array[11][4] = 0.7 * array[10][4] + 0.3 * array[13][4];
      array[12][4] = 0.3 * array[10][4] + 0.7 * array[13][4];

      // Values in which X = 7.
      array[2][7] = 0.7 * array[1][7] + 0.3 * array[4][7];
      array[3][7] = 0.3 * array[1][7] + 0.7 * array[4][7];

      // Values in which X = 8.
      array[8][8] = 0.7 * array[7][8] + 0.3 * array[10][8];
      array[9][8] = 0.3 * array[7][8] + 0.7 * array[10][8];

      // Values in which X = 10.
      array[2][10] = 0.7 * array[1][10] + 0.3 * array[4][10];
      array[3][10] = 0.3 * array[1][10] + 0.7 * array[4][10];

      // Values in which X = 13.
      array[2][13] = 0.7 * array[1][13] + 0.3 * array[4][13];
      array[3][13] = 0.3 * array[1][13] + 0.7 * array[4][13];
    };

    // Fill the inner values that are not yet defined. This depends on initialGridDefinition, injectInitialValues, mergeInitialValuesHorizontally and mergeInitialValuesVertically.
    // Method that will "merge"/"blend" the inner gap values, to be a representation of its neighbours (top-bottom-left).
    const mergeInnerGaps = () => {
      // Values in which Y = 2 or Y = 3.
      array[2][2] = 0.7 * array[1][2] + 0.3 * array[4][2];
      array[2][3] = 0.7 * array[1][3] + 0.3 * array[4][3];
      array[3][2] = 0.3 * array[1][2] + 0.7 * array[4][2];
      array[3][3] = 0.3 * array[1][3] + 0.7 * array[4][3];

      array[2][5] = 0.7 * array[1][5] + 0.3 * array[4][5];
      array[2][6] = 0.7 * array[1][6] + 0.3 * array[4][6];
      array[3][5] = 0.3 * array[1][5] + 0.7 * array[4][5];
      array[3][6] = 0.3 * array[1][6] + 0.7 * array[4][6];

      array[2][8] = 0.7 * array[1][8] + 0.3 * array[4][8];
      array[2][9] = 0.7 * array[1][9] + 0.3 * array[4][9];
      array[3][8] = 0.3 * array[1][8] + 0.7 * array[4][8];
      array[3][9] = 0.3 * array[1][9] + 0.7 * array[4][9];

      array[2][11] = 0.7 * array[1][11] + 0.3 * array[4][11];
      array[2][12] = 0.7 * array[1][12] + 0.3 * array[4][12];
      array[3][11] = 0.3 * array[1][11] + 0.7 * array[4][11];
      array[3][12] = 0.3 * array[1][12] + 0.7 * array[4][12];

      // // Values in which Y = 5 or Y = 6.
      array[5][2] = 0.7 * array[4][2] + 0.3 * array[7][2];
      array[5][3] = 0.7 * array[4][3] + 0.3 * array[7][3];
      array[6][2] = 0.3 * array[4][2] + 0.7 * array[7][2];
      array[6][3] = 0.3 * array[4][3] + 0.7 * array[7][3];

      array[5][5] = 0.7 * array[4][5] + 0.3 * array[7][5];
      array[5][6] = 0.7 * array[4][6] + 0.3 * array[7][6];
      array[5][7] = 0.7 * array[4][7] + 0.3 * array[7][7];
      array[5][8] = 0.7 * array[4][8] + 0.3 * array[7][8];
      array[5][9] = 0.7 * array[4][9] + 0.3 * array[7][9];
      array[5][10] = 0.7 * array[4][10] + 0.3 * array[7][10];
      array[5][11] = 0.7 * array[4][11] + 0.3 * array[7][11];

      array[6][5] = 0.3 * array[4][5] + 0.7 * array[7][5];
      array[6][6] = 0.3 * array[4][6] + 0.7 * array[7][6];
      array[6][7] = 0.3 * array[4][7] + 0.7 * array[7][7];
      array[6][8] = 0.3 * array[4][8] + 0.7 * array[7][8];
      array[6][9] = 0.3 * array[4][9] + 0.7 * array[7][9];
      array[6][10] = 0.3 * array[4][10] + 0.7 * array[7][10];
      array[6][11] = 0.3 * array[4][11] + 0.7 * array[7][11];

      // Values in which Y = 8 or Y = 9.
      array[8][2] = 0.7 * array[7][2] + 0.3 * array[10][2];
      array[8][3] = 0.7 * array[7][3] + 0.3 * array[10][3];
      array[9][2] = 0.3 * array[7][2] + 0.7 * array[10][2];
      array[9][3] = 0.3 * array[7][3] + 0.7 * array[10][3];

      array[8][5] = 0.7 * array[7][5] + 0.3 * array[10][5];
      array[8][6] = 0.7 * array[7][6] + 0.3 * array[10][6];
      array[8][7] = 0.7 * array[7][7] + 0.3 * array[10][7];
      array[9][5] = 0.3 * array[7][5] + 0.7 * array[10][5];
      array[9][6] = 0.3 * array[7][6] + 0.7 * array[10][6];
      array[9][7] = 0.3 * array[7][7] + 0.7 * array[10][7];

      array[8][9] = 0.7 * array[7][9] + 0.3 * array[10][9];
      array[9][9] = 0.3 * array[7][9] + 0.7 * array[10][9];

      // // Values in which Y = 11 or Y = 12.
      array[11][2] = 0.7 * array[10][2] + 0.3 * array[13][2];
      array[11][3] = 0.7 * array[10][3] + 0.3 * array[13][3];
      array[12][2] = 0.3 * array[10][2] + 0.7 * array[13][2];
      array[12][3] = 0.3 * array[10][3] + 0.7 * array[13][3];

      array[11][5] = array[11][4];
      array[12][5] = array[12][4];

      array[11][6] = array[11][5];
      array[12][6] = array[12][5];

      array[11][7] = array[10][7];
      array[11][8] = array[10][8];

      // Values in which Y = 10.
      array[10][9] = array[10][8];

      // Values in which Y = 8 or Y = 9.
      array[9][9] = 0.7 * array[10][9] + 0.3 * array[7][9];
      array[8][9] = 0.3 * array[10][9] + 0.7 * array[7][9];

      array[9][10] = array[9][9];
      array[8][10] = array[8][9];

      array[8][11] = array[7][11];

      // Values in which Y = 5 or Y = 6.
      array[6][12] = array[6][11];
      array[5][12] = array[5][11];
    };

    // Update the values on the array (grid).
    mergeInitialValuesHorizontally();
    mergeInitialValuesVertically();
    mergeInnerGaps();

    return array;
  };

  // We first build a grid filled with undefined values.
  const rawGrid = initialGridDefinition(gridSize);
  // We then inject the initial values, based on the decibel values for the given quadrant points.
  const initialGrid = injectInitialValues(rawGrid, points);
  // We then inject the final values, which are besed on the definition of the initial values.
  const finalGrid = injectFinalValues(initialGrid);

  // We reverse it, so the grid system used to build it matches the axis point definitions on the pressedPoints.
  // For example: the 1,1 position on the grid would be place on a row at the top of the grid; but the 1,1 position on the axis world would coordinate the bottom; so we reverse the grid, so that it matches the axis world when displayed.
  return finalGrid.reverse();
};

/**
 * Checks if the section grid should be extended or not.
 * @param {*} sectionSide The side of the quadrant section in relation to the chart cross.
 * @param {*} eyeSide The side of the eye.
 * @returns A boolean informing whether the grid should be extended or not.
 */
export const shouldExtendGrid = (sectionSide, eyeSide) => {
  // If eye is right, extend left section
  if (eyeSide === "Right" && sectionSide === "Left") return true;

  // If eye is left, extend right section
  if (eyeSide === "Left" && sectionSide === "Right") return true;

  return false;
};

/**
 * Method to build the quadrant grid based on the grid type, and which eye the report refers to.
 * @param {*} type The type of the exam (currently we have 30-2, 24-2 or 10-2).
 * @param {*} sectionSide The grid section side (based on the X and Y values in comparison to the inner cross of a the charts). E.g.: the top left quadrant would have "Left" as value, while the top right quadrant should have "Right" as value.
 * @param {*} eyeSide The eye in which the report refers to ("Left" or "Right").
 * @param {*} pressedPoints The values.
 * @returns An array representing the grid for a quadrant.
 */
export const buildQuadrantGrid = (
  type,
  sectionSide,
  eyeSide,
  pressedPoints = []
) => {
  // Checks if the grid for this section should be extended (can only be extended when the exam type is 24-2).
  const shouldExtend =
    type === GridTypes.G24 || GridTypes.G24C
      ? shouldExtendGrid(sectionSide, eyeSide)
      : false;

  let grid = [];
  if (type === GridTypes.G24)
    grid = build24Dash2Grid(pressedPoints, shouldExtend);
  else if (type === GridTypes.G24C)
    grid = build24Dash2Grid(pressedPoints, shouldExtend);
  else if (type === GridTypes.G30) grid = build30Dash2Grid(pressedPoints);
  else if (type === GridTypes.G10) grid = build10Dash2Grid(pressedPoints);

  return grid;
};

/**
 * Adding the decibel values for each neighbour, so we can process the image based on the neighbours' values
 * @param {*} pressedPoints The points that we will calculate the neighbours for.
 * @returns The pressedPoints with each point containing the neighbours information.
 */
export const calculateNeighbours = (pressedPoints) =>
  pressedPoints.map((point) => {
    // method to add an extra value to help reaching the neighbours from a point on the grid.
    // when on x = 1 and y = 1, the neighbour to the left for example, should be the item on x = -1 and y = 1 (not x = 0 and y = 1).
    const xOffset = (curX, targetX) => {
      // is on the right of the chart
      if (curX > 0) {
        return targetX === 0 ? -1 : targetX;
      }

      // is on the left of the chart
      return targetX === 0 ? 1 : targetX;
    };

    // method to add an extra value to help reaching the neighbours from a point on the grid.
    // when on x = 1 and y = 1, the neighbour to the bottom for example, should be the item on x = 1 and y = -1 (not x = 1 and y = 0).
    const yOffset = (curY, targetY) => {
      // is on the top of the chart
      if (curY > 0) {
        return targetY === 0 ? -1 : targetY;
      }

      // is on the bottom of the chart
      return targetY === 0 ? 1 : targetY;
    };

    return {
      ...point,
      neighboursDecibel: {
        topLeft: pressedPoints.find(
          (e) =>
            e.x === xOffset(point.x, point.x - 1) &&
            e.y === yOffset(point.y, point.y + 1)
        )?.decibelValue,
        top: pressedPoints.find(
          (e) =>
            e.x === xOffset(point.x, point.x) &&
            e.y === yOffset(point.y, point.y + 1)
        )?.decibelValue,
        topRight: pressedPoints.find(
          (e) =>
            e.x === xOffset(point.x, point.x + 1) &&
            e.y === yOffset(point.y, point.y + 1)
        )?.decibelValue,
        right: pressedPoints.find(
          (e) =>
            e.x === xOffset(point.x, point.x + 1) &&
            e.y === yOffset(point.y, point.y)
        )?.decibelValue,
        bottomRight: pressedPoints.find(
          (e) =>
            e.x === xOffset(point.x, point.x + 1) &&
            e.y === yOffset(point.y, point.y - 1)
        )?.decibelValue,
        bottom: pressedPoints.find(
          (e) =>
            e.x === xOffset(point.x, point.x) &&
            e.y === yOffset(point.y, point.y - 1)
        )?.decibelValue,
        bottomLeft: pressedPoints.find(
          (e) =>
            e.x === xOffset(point.x, point.x - 1) &&
            e.y === yOffset(point.y, point.y - 1)
        )?.decibelValue,
        left: pressedPoints.find(
          (e) =>
            e.x === xOffset(point.x, point.x - 1) &&
            e.y === yOffset(point.y, point.y)
        )?.decibelValue,
      },
    };
  });

// The base nivo gaze chart specs, that is applied in case no specs is passed to calculateGazeChartPoint.
const baseNivoGazechartSpecs = {
  maxValue: 100,
  minValue: -100,
  centralValue: 5,
  totalValues: 205,
};

/**
 * Method to calculate the value to be displayed on the gaze chart, based on the angle value.
 * @param {*} angle The angle value, that will define the final value to be considered for the bar gaze chart on that specific position.
 * @param {*} chartSpecs The specs for a nivo bar chart.
 * @returns The gaze chart value representation for the given angle, and the distance from the bottom X axis it should have.
 */
export const calculateGazeChartPoint = (angle, chartSpecs) => {
  // Some constants used to increase the intensity of the values in some cases.
  const negativeMultiplierConstant = 25;
  const posMultiplierConstant = 1.5;

  // The specs to consider. We use the baseNivoGazeChartSpecs when no chartSpecs is informed.
  const specs = {
    ...baseNivoGazechartSpecs,
    ...chartSpecs,
  };

  // Method to get the bottom distance (the offset to place the black bar chart of the stack).
  const getBottomDistance = (angle, middlePosOffset) => {
    // We increase the intensity when angle === -1. We multiply by the negativeMultiplierConstant.
    // Angle === -1 means that the person blinked at that point.
    if (angle === -1)
      return middlePosOffset + angle * negativeMultiplierConstant;

    // By default, we also return the middlePosOffset.
    return middlePosOffset;
  };

  // Method to get the height of the black bar chart of the stack, that represents the patient's focus during the exam.
  const getGazeValue = (angle) => {
    // In case the angle is between 0 and 5, we don't show a spike (which means it should have the height of the centralValue defined on the specs).
    if (angle >= 0 && angle <= 5) return specs.centralValue;

    // When angle equals to -1, we need to increase the height according to the negativeMultiplierConstant.
    if (angle === -1)
      return specs.centralValue + Math.abs(angle) * negativeMultiplierConstant;

    // In this case, we simply add the angle value (multiplied by the defined posMultiplierConstant), to the centralValue defined on the specs.
    return specs.centralValue + angle * posMultiplierConstant;
  };

  // The vertical middle position, based on the max and min values from the chart specs.
  const middlePosOffset =
    (Math.abs(specs.maxValue) + Math.abs(specs.maxValue)) / 2;

  // Anything under 0 should be treated as if their eyes are closed (becomes -1).
  const adjustedAngle = angle < 0 ? -1 : angle;

  // The gaze chart value (the black bar part of the chart).
  // Should have the centralValue chart spec as height (for the bar section), and increase it in case the angle is greater than 4.
  const gazeValue = getGazeValue(adjustedAngle);

  // The bottom distance that the gaze chart shoud have, based on the angle value.
  const bottomDistance = getBottomDistance(adjustedAngle, middlePosOffset);

  // The chart will be composed of two stacked sections on a bar chart.
  // The top section (black bar, displayed on the vertical middle of the chart, because of the bottom section, used as offset) represents the visual part of the chart that will be built.
  // The bottom section (white) represents the distance to the bottom X axis, so that the middle section can be placed having the vertical middle of the chart as it's origin.
  return { gazeValue, bottomDistance };
};

export const getColorVisionTime = (elapsedTime) => {
  const minutes = Math.floor(elapsedTime / 60);
  const remaining_seconds = Math.floor(elapsedTime % 60);
  const formatted_time =
    String(minutes).padStart(2, "0") +
    ":" +
    String(remaining_seconds).padStart(2, "0");
  return formatted_time;
};

export const isBinocular = (exam) => {
  return (
    (exam?.colorVisionSections?.length === 1 &&
      exam?.colorVisionSections[0].eye === "Both") ||
    (exam?.contrastSensitivitySections?.length === 1 &&
      exam?.contrastSensitivitySections[0].eye === "Both") ||
    (exam?.visualAcuitySections?.length === 1 &&
      exam?.visualAcuitySections[0].eye === "Both") ||
    (exam?.visualFieldSections?.length === 1 &&
      exam?.visualFieldSections[0].eye === "Both")
  );
};

export const isMonocularMulitpleSections = (exam) => {
  return (
    exam?.visualFieldSections?.length > 1 ||
    exam?.colorVisionSections?.length > 1 ||
    exam?.contrastSensitivitySections?.length > 1 ||
    exam?.visualAcuitySections?.length > 1
  );
};
