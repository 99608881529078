import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getPatientById } from "../../services/Patient";

export const usePatient = ({ useToken, clinicId, patientId, isPdf }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    const fetchPatient = async (signal) => {
      setIsLoading(true);
      try {
        const token = useToken ? await getAccessTokenSilently() : null;
        const patientData = await getPatientById(
          token,
          clinicId,
          patientId,
          signal,
          isPdf
        );
        setData(patientData);
      } catch (e) {
        console.error("Error fetching data:", e);
      } finally {
        setIsLoading(false);
      }
    };

    const controller = new AbortController();
    if (clinicId && patientId) {
      fetchPatient(controller.signal);
    }
    return () => {
      controller.abort();
    };
  }, [useToken, clinicId, patientId, isPdf, getAccessTokenSilently]);

  return { data, isLoading };
};
