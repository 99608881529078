// Library methods
import { useTranslation } from "react-i18next";

// MUI Components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";

// utilities
import CustomCheckbox from "../../../components/UI/CustomCheckbox";
import { getLocalTime } from "../../../utils/dateHelper";
import { useMemo } from "react";

const InfoDisplay = ({ label, value, gridSize, noWrap = false }) => {
  const fontSize = {
    xs: "12px",
    sm: "12px",
    md: "16px",
    lg: "1rem",
  };
  return (
    <Grid item xs={12} sm={gridSize}>
      <Typography
        sx={{
          display: { xs: "block", sm: "none" },
          fontSize: fontSize,
        }}
      >
        <b>{label}:</b> {value}
      </Typography>
      <Typography
        noWrap={noWrap}
        sx={{
          display: { xs: "none", sm: "block" },
          fontSize: fontSize,
        }}
      >
        {value}
      </Typography>
    </Grid>
  );
};

const ProgressionAnalysisCard = ({
  row,
  patientId,
  isSelected,
  headCells,
  handleCheckboxClick,
  handleViewReportClick,
  associatedExamDevices,
}) => {
  // const { exam, examStatus } = ExamCardShared(row, associatedExamDevices);
  const { t } = useTranslation();

  const eyeTranslation = {
    Left: t("word_left"),
    Right: t("word_right"),
    Binocular: t("word_binocular"),
  };

  const creationDate = useMemo(
    () => getLocalTime(row?.creationDate ?? Date.now()),
    [row]
  );

  return (
    <Grid
      container
      py={0}
      sx={{ display: "flex", alignItems: "center", flexWrap: "no-wrap" }}
    >
      <Grid item xs={12}>
        <Card
          elevation={0}
          key={row?.id}
          sx={{
            marginBottom: 2,
            borderRadius: 2,
            backgroundColor: !isSelected ? "#202338" : "#E2772E",
            color: "white",
            height: { xs: 120, sm: 52 },
            display: "flex",
            jutifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            py={0}
            sx={{
              display: { xs: "grid", sm: "flex" },
              gridTemplateColumns: { xs: "13% 57% 30%", sm: "none" },
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Grid item sm={1} sx={{ ml: { xs: 0, sm: 0 } }}>
              <CustomCheckbox
                checkedFill="#E2772E"
                checked={isSelected}
                onClick={() => handleCheckboxClick(row.id)}
              />
            </Grid>
            <Grid
              item
              sm={10}
              display={{ sx: "block", sm: "flex" }}
              flexDirection={{ sm: "space-between" }}
              alignItems="center"
              data-testid={`progression-analysis-card-${row?.id}`}
            >
              <InfoDisplay
                label={headCells[1].label}
                value={eyeTranslation[row?.eye]}
                gridSize={headCells[1].gridSize}
              />
              <InfoDisplay
                label={headCells[2].label}
                value={`${row?.earliestCompletionLocalDate} - ${row?.latestCompletionLocalDate}`}
                gridSize={headCells[2].gridSize}
              />
              <InfoDisplay
                label={headCells[3].label}
                value={creationDate}
                gridSize={headCells[3].gridSize}
              />
            </Grid>
            <Grid item sm={1}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", sm: "end" },
                  mr: { xs: 0, sm: 1 },
                }}
              >
                <Tooltip title={t("word_view_results")}>
                  <span>
                    <a
                      href={`/patients/${patientId}/progression-analysis/${row?.id}`}
                      style={{ textDecoration: "none" }}
                      onClick={(e) => {
                        // Prevent default behavior of <a>
                        e.preventDefault();
                        handleViewReportClick(patientId, row?.id);
                      }}
                    >
                      <IconButton
                        size="medium"
                        sx={{
                          color: "white",
                          padding: "1px 2px 1px 2px",
                          "&.Mui-disabled": {
                            color: "white",
                            opacity: "0.5",
                          },
                        }}
                      >
                        <PlagiarismOutlinedIcon fontSize="inherit" />
                      </IconButton>
                    </a>
                  </span>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProgressionAnalysisCard;
