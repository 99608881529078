// Library methods
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Components
import AdminModeModal from "../AdminModeModal";
import SignOutModal from "../SignOutModal";
import UserMenu from "../UserMenu";

const adminRoute = "/admin/clinics";

const UserMenuWrapper = ({ canReachPages }) => {
  // auth
  const { user } = useAuth0();

  // routing
  const navigate = useNavigate();

  // logout function
  const { logout } = useAuth0();

  // menu state
  const [userMenu, setUserMenu] = useState(null);
  const [adminModeModal, setAdminModeModal] = useState(false);
  const [signOutModal, setSignOutModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  // menu click handler
  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  // menu close handler
  const userMenuClose = () => {
    setUserMenu(null);
  };

  // handle admin modal confirm
  const handleAdminConfirm = useCallback(() => {
    setUserMenu(null);
    setAdminModeModal(false);
    navigate(adminRoute);
  }, [navigate, setUserMenu]);

  // handle admin click
  const handleAdminClick = useCallback(() => setAdminModeModal(true), []);

  // handle clinic settings click
  const handleClinicSettings = useCallback(
    () => navigate("/clinic-settings"),
    [navigate]
  );

  const handleLogout = useCallback(
    (event) => {
      if (event) {
        // Clear local storage
        localStorage.clear();
        sessionStorage.clear();
        logout({
          logoutParams: {
            returnTo: process.env.REACT_APP_URL,
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          },
        });
      }
    },
    [logout]
  );

  // Modal handlers
  const handleSignOutClick = useCallback((event) => {
    setSignOutModal(true);
  }, []);

  const handleSignOutConfirm = useCallback(
    (event) => {
      handleLogout(event);
      setSignOutModal(false);
    },
    [handleLogout]
  );

  return (
    <>
      {/* menu button */}
      <Button
        onClick={userMenuClick}
        sx={{
          position: "relative",
          display: { xs: "block", sm: "inline" },
          minWidth: { xs: 0, sm: 64 },
          ml: { md: 2, lg: 4 },
          color: "#E7EBF8",
          fontSize: 20,
          ...(Boolean(userMenu) && {
            fontWeight: "bold",
            textDecoration: "underline",
            textUnderlineOffset: 4,
          }),
          "&:hover": {
            ...(Boolean(userMenu) && {
              textDecoration: "underline",
              textUnderlineOffset: 4,
            }),
          },
        }}
      >
        <Grid item display={{ xs: "block", sm: "flex" }} alignItems={"center"}>
          <FontAwesomeIcon icon={faUser} size="sm" />
          <Typography
            variant="button"
            sx={{
              color: "#E7EBF8",
              display: { xs: "none", sm: "none", md: "block" },
              textTransform: "capitalize",
              fontSize: 20,
              ml: 1.5,
            }}
            ml={0.5}
          >
            {user.name}
          </Typography>
        </Grid>
      </Button>

      {/* {Boolean(userMenu) && ( */}
      <UserMenu
        canReachPages={canReachPages}
        userMenu={userMenu}
        userMenuClose={userMenuClose}
        handleLeftClick={handleAdminClick}
        handleCenterClick={handleClinicSettings}
        handleRightClick={handleSignOutClick}
        isAdmin={isAdmin}
        setIsAdmin={setIsAdmin}
        // setAdminModeModal={setAdminModeModal}
        // setSignOutModal={setSignOutModal}
      />
      {/* )} */}
      <AdminModeModal
        open={adminModeModal}
        onClose={() => setAdminModeModal(false)}
        onCancel={() => setAdminModeModal(false)}
        onConfirm={handleAdminConfirm}
      />
      <SignOutModal
        open={signOutModal}
        onClose={() => setSignOutModal(false)}
        onCancel={() => setSignOutModal(false)}
        onConfirm={handleSignOutConfirm}
      />
    </>
  );
};

export default UserMenuWrapper;
