// TODO: format enum properties
export const conditionTitles = {
  Glaucoma: "Glaucoma",
  AMD: "AMD",
  DiabeticRetinopathy: "DR",
  otherConditions: "Other condition",
};

export const progressionChartShapes = {
  FAST_THRESHOLD: "circle",
  STANDARD_THRESHOLD: "triangle",
  FULL_THRESHOLD: "rect",
};
