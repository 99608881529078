// Library methods
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Alert from "@mui/material/Alert";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// Styles
import {
  CustomTextFieldOutlined,
  CustomTextFieldOutlinedInputProps,
  ModalCustomCancel,
  ModalPaperProps,
  ModalCustomConfirm,
  ModalCustomConfirmIconHandler,
  ModalDialogActions,
} from "../../../../styles/muiStylesHelper";

// Utilities
import { getLocale } from "../../../../utils/localeHelper";
import { calculateYearDifference } from "../../../../utils/dateHelper";
import { allLanguageMap } from "../../../../i18n/languages";
import { useMemo } from "react";
import SearchBox from "../../../../components/UI/SearchBox";
import CustomDatePicker from "../../../../components/UI/CustomDatePIcker";

const AddPatientModal = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  firstName,
  lastName,
  healthCareId,
  birthDate,
  birthDateError,
  sex,
  language,
  setFirstName,
  setLastName,
  setHealthCareId,
  setBirthDate,
  setBirthDateError,
  setSex,
  setLanguage,
  checkPatientName,
  existingPatient,
  setExistingPatient,
  onConfirmNavigation,
  sortedLanguageList = [],
  genderData = [],
}) => {
  // internationalization
  const { t, i18n } = useTranslation();

  const existingBirthDate = useMemo(
    () =>
      existingPatient?.dateOfBirth
        ? existingPatient.dateOfBirth.slice(0, 10)
        : null,
    [existingPatient?.dateOfBirth]
  );

  const calculatedAgeText = useMemo(
    () =>
      birthDate
        ? `${calculateYearDifference(birthDate)} ${t("patients_modal_age")}`
        : null,
    [birthDate, t]
  );

  const today = new Date();
  // Set time to the start of the day to avoid time zone issues
  today.setHours(0, 0, 0, 0);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 120);
  maxDate.setHours(0, 0, 0, 0);

  const handleChange = (newValue) => {
    const isDateValid = !isNaN(new Date(newValue).getTime());

    if (!isDateValid) {
      setBirthDateError(2);
    } else if (newValue > today) {
      setBirthDateError(1);
    } else if (newValue < maxDate) {
      setBirthDateError(4);
    } else {
      setBirthDateError(false);
      setBirthDate(newValue);
    }
  };

  const shouldDisable = useMemo(() => {
    let isIncluded = 0;
    Object.keys(allLanguageMap).forEach((key) => {
      if (t(allLanguageMap[key]?.val) === language?.val) isIncluded++;
    });
    genderData.forEach((gender) => {
      if (gender?.val === sex?.val) isIncluded++;
    });
    return !(firstName && lastName && birthDate && sex) || isIncluded !== 2;
  }, [t, birthDate, firstName, language, lastName, sex, genderData]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: ModalPaperProps,
      }}
    >
      <DialogContent>
        <Box
          padding={1}
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
        >
          <FontAwesomeIcon icon={faUserPlus} size="lg" />
          <Typography
            style={{ fontWeight: "bold" }}
            variant="h5"
            component="h2"
          >
            {t("patients_modal_title")}
          </Typography>
        </Box>
        <Box padding={1}>
          {/* First Name and Last Name fields */}
          <Box sx={{ display: "flex" }}>
            <TextField
              id="outlined-basic"
              required
              value={firstName}
              onChange={(e) => {
                setExistingPatient(null);
                setFirstName(e.target.value);
              }}
              onBlur={() => checkPatientName(firstName, lastName)}
              label={t("patients_modal_first_name")}
              variant="outlined"
              fullWidth
              sx={() =>
                CustomTextFieldOutlined({ mt: "1.25rem", mr: "1.25rem" })
              }
              InputProps={{
                sx: CustomTextFieldOutlinedInputProps,
              }}
              color="warning"
            />
            <TextField
              id="outlined-basic"
              required
              value={lastName}
              onChange={(e) => {
                setExistingPatient(null);
                setLastName(e.target.value);
              }}
              onBlur={() => checkPatientName(firstName, lastName)}
              label={t("patients_modal_last_name")}
              variant="outlined"
              fullWidth
              sx={() => CustomTextFieldOutlined({ my: "1.25rem" })}
              InputProps={{
                sx: CustomTextFieldOutlinedInputProps,
              }}
              color="warning"
            />
          </Box>
          <TextField
            id="outlined-basic"
            value={healthCareId}
            onChange={(e) => setHealthCareId(e.target.value)}
            label={t("patients_modal_health_care_id")}
            variant="outlined"
            fullWidth
            sx={() => CustomTextFieldOutlined({ mb: "1.25rem" })}
            InputProps={{
              sx: CustomTextFieldOutlinedInputProps,
            }}
            color="warning"
          />

          {/* Birthdate selector */}
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
          >
            <Box width="100%">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={getLocale(i18n.language).datepicker}
              >
                <CustomDatePicker
                  date={birthDate}
                  birthDateError={birthDateError}
                  handleChange={handleChange}
                />
              </LocalizationProvider>
            </Box>

            {calculatedAgeText && (
              <Box minWidth="fit-content">
                <Typography data-testid="patient_age_display" variant="body2">
                  {calculatedAgeText}
                </Typography>
              </Box>
            )}
          </Box>

          {/* Gender selector */}
          <SearchBox
            data={genderData}
            state={sex}
            setState={setSex}
            label={t("patients_modal_sex")}
            propertyBit={2}
          />
          <SearchBox
            data={sortedLanguageList}
            state={language}
            setState={setLanguage}
            label={t("patients_modal_language")}
          />
          {existingPatient && (
            <Box sx={{ mt: "1.25em" }}>
              <Alert
                variant="outlined"
                severity="warning"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography>
                  {t("patients_modal_already_exists_warning")}
                </Typography>
                <Typography>
                  {t("patients_modal_health_care_id")} {":"}{" "}
                  {existingPatient.healthCareId || "N/A"}
                </Typography>
                <Typography>
                  {t("patients_edit_modal_birth")} {":"}{" "}
                  {existingBirthDate || "N/A"}{" "}
                  <Button
                    variant="outlined"
                    sx={{
                      ...ModalCustomConfirm(),
                      ...ModalCustomConfirmIconHandler(),
                    }}
                    color="primary"
                    onClick={onConfirmNavigation}
                  >
                    {t("word_view")}
                  </Button>
                </Typography>
              </Alert>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={ModalDialogActions}>
        <Button variant="text" sx={ModalCustomCancel} onClick={onCancel}>
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...ModalCustomConfirm(),
            ...ModalCustomConfirmIconHandler(),
          }}
          color="primary"
          disabled={shouldDisable || birthDateError}
          onClick={onConfirm}
        >
          {t("button_add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPatientModal;
