import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ProgressionChartLegend() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        fontSize: "14px",
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Typography fontWeight="bold">{t("word_legend")}</Typography>
      {/* Circle shape */}
      <Box display="flex" alignItems="center" gap={1}>
        <div
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: "#E2772E",
            borderRadius: "50%",
          }}
        ></div>
        <Typography variant="body2">{t("fast_threshold")}</Typography>
      </Box>
      {/* Square shape */}
      <Box display="flex" alignItems="center" gap={1}>
        <div
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: "#E2772E",
          }}
        ></div>
        <Typography variant="body2">{t("full_threshold")}</Typography>
      </Box>
      {/* Triangle shape */}
      <Box display="flex" alignItems="center" gap={1}>
        <div
          style={{
            width: "0px",
            height: "0px",
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            borderBottom: "10px solid #E2772E",
          }}
        ></div>
        <Typography variant="body2">{t("standard_threshold")}</Typography>
      </Box>
    </Box>
  );
}
