// Library methods
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import { CSVLink } from "react-csv";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";

// Styles
import {
  ModalCustomCancel,
  ModalCustomConfirm,
  ModalPaperProps,
  ModalDialogActions,
} from "../../../../styles/muiStylesHelper";

// Dropzone extensions
const dropzoneExtensions = {
  "text/csv": [
    ".csv",
    "text/csv",
    "application/vnd.ms-excel",
    "application/csv",
    "text/x-csv",
    "application/x-csv",
    "text/comma-separated-values",
    "text/x-comma-separated-values",
  ],
};

// receives the csv data, and converts it into a json structure
function csvJSON(csv) {
  const lines = csv.split("\n");
  const result = [];

  // the users' info starts from the third line
  for (let i = 2; i < lines.length; i++) {
    const userObj = { firstName: undefined, lastName: undefined };
    const currentLine = lines[i].split(",");

    // in case there is a blank line, skip this iteration
    if (currentLine.length === 1 && !currentLine[0]) continue;

    // removing the initial and ending quotes from the strings
    userObj.firstName = currentLine[0].replace(/(^"|')|("|'$)/g, "");
    userObj.lastName = currentLine[1].replace(/(^"|')|("|'$)/g, "");

    result.push(userObj);
  }

  return result;
}

const UploadPatientsModal = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  usersToUpload,
  setUsersToUpload,
}) => {
  // internationalization
  const { t } = useTranslation();

  // state to control the display text messages
  const [emptyFile, setEmptyFile] = useState(false);
  const [wrongExtension, setWrongExtension] = useState(false);

  // modal fullScreen on small screens
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // csv link ref
  const csvLinkRef = useRef(null);

  const onFileDialogCancel = () => {
    setEmptyFile(false);
    setUsersToUpload([]);
    setWrongExtension(false);
  };

  const onDropRejected = () => {
    setEmptyFile(false);
    setUsersToUpload([]);
    setWrongExtension(true);
  };

  const onDropAccepted = () => {
    setEmptyFile(false);
    setUsersToUpload([]);
    setWrongExtension(false);
  };

  const onCloseWrapper = () => {
    setEmptyFile(false);
    setUsersToUpload([]);
    setWrongExtension(false);
    onClose();
  };

  const onCancelWrapper = () => {
    setEmptyFile(false);
    setUsersToUpload([]);
    setWrongExtension(false);
    onCancel();
  };

  // csv constants
  const csvTemplate = [
    [t("patients_bulk_upload_modal_csv_description")],
    [
      t("patients_bulk_upload_modal_csv_column_one"),
      t("patients_bulk_upload_modal_csv_column_two"),
    ],
    [
      t("patients_bulk_upload_modal_csv_column_one_example"),
      t("patients_bulk_upload_modal_csv_column_two_example"),
    ],
  ];

  return (
    <Dialog
      open={open}
      onClose={onCloseWrapper}
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          ...ModalPaperProps,
          maxWidth: { xs: "100%", sm: "395.51px" },
          height: { xs: "100%", md: "90%" },
        },
      }}
    >
      <DialogContent>
        <Box sx={{ padding: 1 }}>
          <Typography
            id="modal-modal-title"
            className="text-center"
            style={{ fontWeight: "500" }}
            variant="h5"
            component="h2"
          >
            {t("patients_bulk_upload_modal_title")}
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body1"
            component="div"
            mt={2}
          >
            {t("patients_bulk_upload_modal_description")}
          </Typography>
          <Grid container mt={2}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              sx={{ mb: 2 }}
            >
              <Button
                variant="outlined"
                sx={{
                  height: 50,
                  color: "white",
                  borderRadius: 30,
                  borderColor: "#202338",
                  borderWidth: 2,
                  backgroundColor: "#202338",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#202338",
                    borderColor: "#202338",
                  },
                }}
                onClick={() => csvLinkRef?.current?.link.click()}
              >
                <DownloadIcon sx={{ mr: 1 }} />
                {t("button_template")}
              </Button>
              <CSVLink
                ref={csvLinkRef}
                data={csvTemplate}
                filename={`${t(
                  "patients_bulk_upload_modal_template_filename"
                )}.csv`}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Dropzone
                  accept={dropzoneExtensions}
                  onDrop={async ([file]) => {
                    const reader = new FileReader();
                    reader.onload = function (e) {
                      const csvContent = e.target.result;
                      const convertedCsv = csvJSON(csvContent);
                      if (convertedCsv.length === 0) setEmptyFile(true);
                      setUsersToUpload(convertedCsv);
                    };
                    reader.readAsText(file);
                  }}
                  onDropAccepted={onDropAccepted}
                  onDropRejected={onDropRejected}
                  onFileDialogCancel={onFileDialogCancel}
                >
                  {({ acceptedFiles, getRootProps, getInputProps }) => (
                    <>
                      <div
                        {...getRootProps()}
                        style={{
                          height: 125,
                          border: "2px dashed #202338",
                          padding: "30px",
                          borderRadius: 30,
                        }}
                      >
                        <input {...getInputProps()} />
                        {t("patients_bulk_upload_modal_dropzone")}
                      </div>
                      {acceptedFiles.length > 0 && (
                        <Typography variant="body1" component="div" mt={2}>
                          <b>{t("patients_bulk_upload_modal_file")}:</b>{" "}
                          {acceptedFiles[0].path}
                        </Typography>
                      )}
                      {emptyFile && (
                        <Typography
                          variant="body1"
                          component="div"
                          mt={2}
                          sx={{ color: "red" }}
                        >
                          {t(
                            "patients_bulk_upload_modal_message_missing_user_info"
                          )}
                        </Typography>
                      )}
                      {wrongExtension && (
                        <Typography
                          variant="body1"
                          component="div"
                          mt={2}
                          sx={{ color: "red" }}
                        >
                          {t(
                            "patients_bulk_upload_modal_message_wrong_extension"
                          )}
                        </Typography>
                      )}
                    </>
                  )}
                </Dropzone>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={ModalDialogActions}>
        <Button variant="text" sx={ModalCustomCancel} onClick={onCancelWrapper}>
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={ModalCustomConfirm}
          disabled={!(usersToUpload.length > 0)}
          onClick={onConfirm}
        >
          {t("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadPatientsModal;
