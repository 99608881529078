// Library methods
import React from "react";
import { Grid, Typography } from "@mui/material";
import { getLocalTime } from "../../../../utils/dateHelper";

// Constants
import { conditionTitles } from "../../../../enums";

const CommentCard = ({
  username,
  creationDate,
  comment,
  prevConditions,
  conditions,
}) => {
  const positiveConditions = [];
  conditions.forEach((condition) => {
    if (!prevConditions.includes(condition)) positiveConditions.push(condition);
  });
  const negativeConditions = [];
  prevConditions.forEach((prevCondition) => {
    if (!conditions.includes(prevCondition))
      negativeConditions.push(prevCondition);
  });

  const positiveStatusText = positiveConditions.length
    ? `${getLocalTime(new Date(creationDate))} : Status of ${positiveConditions.map(
        (condition, i) => {
          return i
            ? " " + conditionTitles[condition]
            : conditionTitles[condition];
        }
      )} changed to be positive.`
    : null;
  const negativeStatusText = negativeConditions.length
    ? positiveConditions.length === 0
      ? `${getLocalTime(new Date(creationDate))} :`
      : "" +
        `Status of ${negativeConditions.map((condition, i) => {
          return i
            ? " " + conditionTitles[condition]
            : conditionTitles[condition];
        })} changed to be negative.`
    : null;

  const commentContent =
    (!positiveConditions.length && !negativeConditions.length
      ? `${getLocalTime(new Date(creationDate))} : `
      : "") + comment;

  return (
    <Grid item pl={1} pr={1} pt={0.5} xs={12} mb={0.5} overflow="auto">
      {positiveStatusText && (
        <Typography variant="body2" style={{ wordWrap: "break-word" }}>
          {positiveStatusText}
        </Typography>
      )}
      {negativeStatusText && (
        <Typography variant="body2" style={{ wordWrap: "break-word" }}>
          {negativeStatusText}
        </Typography>
      )}
      {/* {positiveOtherConditions && (
        <Typography variant="body2" style={{ wordWrap: "break-word" }}>
          {positiveOtherConditions}
        </Typography>
      )}
      {negativeOtherConditions && (
        <Typography variant="body2" style={{ wordWrap: "break-word" }}>
          {negativeOtherConditions}
        </Typography>
      )} */}
      <Typography variant="body1" style={{ wordWrap: "break-word" }}>
        {commentContent}
      </Typography>
      {/* <Typography variant="body2" textAlign="right" mr={2}>
        {new Date(creationDate).toLocaleDateString()}
      </Typography> */}
      {/* </Grid> */}
      {/* </Grid> */}

      {/* <Grid item xs={3.5} sm={3} p={1}> */}
      {/* {((conditions && conditions.length) || otherConditions) && (
          <Typography>Conditions:</Typography>
        )} */}
      {/* {conditions && conditions.length && ( */}
      {/* <List disablePadding sx={{ paddingLeft: 1 }}> */}
      {/* <List disablePadding>
          {conditions.map((condition, index) => (
            // component="nav"
            // aria-label="main mailbox folders"
            <ListItem key={index} disablePadding>
              <ListItemText primary={condition} />
            </ListItem>
          ))}
        </List> */}
      {/* )} */}
    </Grid>
  );
};

export default CommentCard;
