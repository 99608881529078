import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CustomTextFieldOutlined,
  CustomTextFieldOutlinedInputProps,
} from "../../../styles/muiStylesHelper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TextField, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";

const SearchBox = ({
  data = [],
  state,
  setState,
  propertyBit = 0,
  label = null,
  mb = "1rem",
  maxHeight = "200px",
  defaultIsListVisible = false,
  parentOpen = false,
}) => {
  const { t } = useTranslation();
  const [valueOfSearchBox, setValueOfSearchBox] = useState("");
  const [isListVisible, setIsListVisible] = useState(defaultIsListVisible);
  const [isFocused, setIsFocused] = useState(false);
  const [mouseIsOverList, setMouseIsOverList] = useState(false);

  const listRef = useRef(null);
  const stateRef = useRef(null);
  const textFieldRef = useRef(null);
  const searchTermRef = useRef("");

  const wrapperStyle = {
    0: "px-0 py-0",
    1: "px-2 py-8",
    2: "px-0 py-2 pt-4",
  };
  const filteredItems = data.filter((item) => {
    const term = searchTermRef.current.toLowerCase();
    if (propertyBit === 0 && typeof item?.name === "string") {
      return t(item.name).toLowerCase().includes(term);
    }
    return item?.name?.toLowerCase().includes(term);
  });

  useEffect(() => {
    const val =
      propertyBit === 0
        ? state?.name && state.name !== ""
          ? t(state.name)
          : searchTermRef.current
        : propertyBit % 2 === 0
          ? state?.name
          : propertyBit === 1 && state?.name && state?.utcOffset
            ? `${state.name} (${state.utcOffset})`
            : state && state?.name !== ""
              ? state?.name
              : searchTermRef.current;
    setValueOfSearchBox(val);
  }, [state, setValueOfSearchBox, propertyBit, t]);

  const getDisplayedProperty = (item) => {
    const propertyDic = {
      0: t(item?.name),
      1: `${item?.name} (${item?.utcOffset})`,
    };
    return propertyDic[propertyBit % 2];
  };

  const handleItemClick = useCallback(
    (item) => {
      searchTermRef.current = "";
      stateRef.current = item?.name;
      setState(item);
      setIsListVisible(false);
      setIsFocused(false);
    },
    [setState]
  );

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      searchTermRef.current = value;
      setState(propertyBit % 2 ? { name: "", utcOffset: "" } : "");
      stateRef.current = value;
      setIsListVisible(true);
      setIsFocused(true);
      setValueOfSearchBox(value); // Update only for display purposes
    },
    [propertyBit, setState]
  );

  const handleInputFocus = useCallback(() => {
    setIsListVisible(true);
    setIsFocused(true);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setMouseIsOverList(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setMouseIsOverList(false);
  }, []);

  const handleInputBlur = useCallback(() => {
    if (stateRef.current) {
      const corresponding = data.find(
        (datum) => datum.name === stateRef.current
      );
      setState(
        corresponding ?? {
          name: stateRef.current,
          val: stateRef.current,
        }
      );
    }
    setTimeout(() => {
      if (!mouseIsOverList) {
        setIsListVisible(false);
        setIsFocused(false);
      }
    }, 100);
  }, [data, setState, mouseIsOverList]);

  const handleMouseDown = useCallback(
    (e, item) => {
      e.preventDefault();
      handleItemClick(item);
    },
    [handleItemClick]
  );

  useEffect(() => {
    if (parentOpen && defaultIsListVisible && textFieldRef?.current) {
      setTimeout(() => {
        textFieldRef.current.focus();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentOpen]);

  return (
    <div className="w-full max-w-screen-xl">
      <div className={`flex justify-center ${wrapperStyle[propertyBit]}`}>
        <div className="w-full">
          <div className="shadow-md rounded-lg" style={{ marginBottom: mb }}>
            <div className="flex items-center bg-20233810 rounded-md">
              <TextField
                sx={() => CustomTextFieldOutlined()}
                id="search"
                label={
                  defaultIsListVisible && isFocused
                    ? null
                    : label ?? t("word_timezone")
                }
                value={valueOfSearchBox}
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                  sx: { ...CustomTextFieldOutlinedInputProps, paddingRight: 0 },
                  endAdornment: (
                    <InputAdornment position="start">
                      <ArrowDropDownIcon
                        cursor="pointer"
                        onClick={() => {
                          if (isListVisible && isFocused) {
                            handleInputBlur();
                          } else {
                            handleInputFocus();
                            // set the focus so the list closes when clicking outside of it
                            textFieldRef.current?.focus();
                          }
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                inputRef={textFieldRef}
                color="warning"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                fullWidth
                autoComplete="off"
                data-testid="custom-search-box"
              />
            </div>
            {isListVisible && (
              <div
                className="py-3 text-sm"
                style={{ maxHeight: maxHeight, overflowY: "auto" }}
                ref={listRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {filteredItems?.length > 0 ? (
                  filteredItems.map((item, index) => (
                    <div
                      key={index}
                      onMouseDown={(e) => handleMouseDown(e, item)}
                      className="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2"
                      aria-label={item?.id}
                    >
                      <div className="flex-grow font-medium px-2">
                        {getDisplayedProperty(item)}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-gray-700 px-3 py-1">No results</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
