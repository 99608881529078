// Library methods
import { Box } from "@mui/system";
import { useParams } from "react-router";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

// Contexts
import {
  CommentAPInfoContext,
  CommentModalContextProvider,
  CommentTitleProvider,
} from "../../contexts/CommentContext";
import {
  ReportDataContext,
  ReportInfoDataContext,
} from "../../contexts/ReportDataContext";

// Styles
import { PageContainerWrapper } from "../../styles/muiStylesHelper";

// Components
import ReportPdf from "../../components/UI/Report";
import SnackbarMessage from "../../components/UI/SnackbarMessage";
import ReportPanel from "../../containers/Report/ReportPanel";

// Utilities
import useClinic from "../../hooks/useClinic";
import ExamTransferModal from "../../components/UI/ExamTransferModal";
import { ExamTransferContext } from "../../contexts/ExamTransferContext";
import { getPatients } from "../../services/Patient";
import { useReportGeneration } from "../../hooks/reports/useReportGeneration";
import {
  AllFileTypes,
  generateReportFile,
  getAccessInfo,
  getReportInfo,
} from "../../services/Exam";

const Report = () => {
  // router params
  const { patientId, examId } = useParams();
  // translation
  const { t } = useTranslation();
  // clinic context
  const { clinicId } = useClinic();
  // auth
  const { getAccessTokenSilently } = useAuth0();
  // exam transfer
  const { openExamTransferModal } = useContext(ExamTransferContext);
  // state
  const [patients, setPatients] = useState(null);

  const {
    isLoading,
    patientName,
    patient,
    exam,
    examReport1,
    examReport2,
    eyeReport1,
    eyeReport2,
    comments1,
    comments2,
    sectionId1,
    sectionId2,
    refetchComment,
    durationStartTime1,
    durationStartTime2,
    duration1,
    duration2,
    shouldDisplayReport,
    clinicTimezone,
    newPatientId,
  } = ReportPdf(false, clinicId, patientId, examId);

  const customGetAccessInfo = async (fileType, signal) => {
    const token = await getAccessTokenSilently();
    return getAccessInfo(token, clinicId, patientId, examId, fileType, signal);
  };

  const customGetReportInfo = async (signal) => {
    const token = await getAccessTokenSilently();
    return getReportInfo(token, clinicId, patientId, examId, signal);
  };

  const customGenerateReportFile = async (type) => {
    const token = await getAccessTokenSilently();
    return generateReportFile(
      token,
      clinicId,
      patientId,
      examId,
      AllFileTypes[type]
    );
  };

  const {
    downloadPdf,
    shouldDisplayDicomButton,
    shouldDisplayPngButton,
    fileInfo,
    reportInfoError,
    openFailedToast,
    setOpenFailedToast,
    getReportInfoData,
  } = useReportGeneration({
    getAccessInfo: customGetAccessInfo,
    generateReportFile: customGenerateReportFile,
    getReportInfo: customGetReportInfo,
  });

  const getPatientsCall = useCallback(
    async (signal) => {
      try {
        const token = await getAccessTokenSilently();
        const patientsList = await getPatients(token, clinicId, signal);
        setPatients(patientsList);
      } catch (e) {}
    },
    [clinicId, getAccessTokenSilently]
  );

  useEffect(() => {
    const controller = new AbortController();
    if (openExamTransferModal) {
      getPatientsCall(controller.signal);
    }
    return () => {
      controller.abort();
    };
  }, [getPatientsCall, openExamTransferModal]);

  return (
    <CommentTitleProvider>
      <CommentAPInfoContext.Provider
        value={{
          clinicId: clinicId,
          patientId: newPatientId,
          examId: examId,
        }}
      >
        <CommentModalContextProvider>
          <ReportDataContext.Provider
            value={{
              isLoading: isLoading,
              patientName: patientName,
              patient: patient,
              exam: exam,
              examReport: examReport1,
              examReport2: examReport2,
              eyeReport: eyeReport1,
              eyeReport2: eyeReport2,
              comments: comments1,
              comments2: comments2,
              sectionId: sectionId1,
              sectionId2: sectionId2,
              refetchComment: refetchComment,
              durationStartTime: durationStartTime1,
              durationStartTime2: durationStartTime2,
              duration: duration1,
              duration2: duration2,
              clinicTimezone: clinicTimezone,
            }}
          >
            <ReportInfoDataContext.Provider
              value={{ getReportInfoData: getReportInfoData }}
            >
              <Box
                sx={() => PageContainerWrapper()}
                px={{ xs: 2, sm: 4, md: 8, lg: 12 }}
                mb={{ xs: 2 }}
              >
                <ReportPanel
                  downloadPdf={downloadPdf}
                  shouldDisplayReport={shouldDisplayReport}
                  fileInfo={fileInfo}
                  reportInfoError={reportInfoError}
                  shouldDisplayDicomButton={shouldDisplayDicomButton}
                  shouldDisplayPngButton={shouldDisplayPngButton}
                />
                {/* Edit exam success/failure toasts */}
                <SnackbarMessage
                  open={openFailedToast}
                  onClose={() => setOpenFailedToast(false)}
                  success={false}
                  text={t("pdf_download_failed")}
                />
              </Box>
              <ExamTransferModal
                patientsOptions={patients}
                fromeWhere={`report`}
              />
            </ReportInfoDataContext.Provider>
          </ReportDataContext.Provider>
        </CommentModalContextProvider>
      </CommentAPInfoContext.Provider>
    </CommentTitleProvider>
  );
};

export default Report;
