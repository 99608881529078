import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Line } from "react-chartjs-2";
import ProgressionChartLegend from "../../../../components/UI/ProgressionChartLegend";

export default function ProgressionChart({ chartData }) {
  const { t } = useTranslation();

  return (
    <>
      {/* Chart Legend */}
      <Box
        sx={{
          position: "relative",
          height: "25%",
        }}
      >
        <ProgressionChartLegend />
      </Box>

      {/* Progression Charts */}
      <Box sx={{ position: "relative", height: "25%" }}>
        <Line
          data={{
            labels: chartData.time,
            datasets: [
              {
                label: "MD",
                data: chartData.MD,
                borderColor: "#E2772E",
                fill: false,
                tension: 0.1,
                pointStyle: chartData.chartPointShapes,
                pointRadius: 5,
                pointBackgroundColor: "#E2772E",
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: "Mean Deviation (MD)",
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: t("Time"),
                },
              },
              y: {
                title: {
                  display: true,
                  text: "MD (dB)",
                },
              },
            },
          }}
        />
      </Box>
      <Box sx={{ position: "relative", height: "25%" }}>
        <Line
          data={{
            labels: chartData.time,
            datasets: [
              {
                label: "PSD",
                data: chartData.PSD,
                borderColor: "#E2772E",
                fill: false,
                tension: 0.1,
                pointStyle: chartData.chartPointShapes,
                pointRadius: 5,
                pointBackgroundColor: "#E2772E",
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: "Pattern Standard Deviation (PSD)",
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: t("Time"),
                },
              },
              y: {
                title: {
                  display: true,
                  text: "PSD (dB)",
                },
              },
            },
          }}
        />
      </Box>
      <Box sx={{ position: "relative", height: "25%" }}>
        <Line
          data={{
            labels: chartData.time,
            datasets: [
              {
                label: "RLFI",
                data: chartData.RLFI,
                borderColor: "#E2772E",
                fill: false,
                tension: 0.1,
                pointStyle: chartData.chartPointShapes,
                pointRadius: 5,
                pointBackgroundColor: "#E2772E",
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: "False Positive Percentage (RLFI)",
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: t("Time"),
                },
              },
              y: {
                title: {
                  display: true,
                  text: "RLFI (%)",
                },
              },
            },
          }}
        />
      </Box>
    </>
  );
}
