import { createContext, useState } from "react";

const CommentContext = createContext({
  Glaucoma: "",
  AMD: "",
  DiabeticRetinopathy: "",
  otherConditions: "",
});

// TODO: Remove this provider because conditionTitles can be imported as an enum
const CommentTitleProvider = ({ children }) => {
  return (
    <CommentContext.Provider
      value={{
        Glaucoma: "Glaucoma",
        AMD: "AMD",
        DiabeticRetinopathy: "DR",
        otherConditions: "Other condition",
      }}
    >
      {children}
    </CommentContext.Provider>
  );
};

const CommentAPInfoContext = createContext({
  // token
  clinicId: "",
  patientId: "",
  examId: "",
});

const CommentModalContext = createContext({
  commentModalSectionId: "",
  setCommentModalSectionId: () => {},
});

const CommentModalContextProvider = ({ children }) => {
  const [commentModalSectionId, setCommentModalSectionId] = useState("");
  return (
    <CommentModalContext.Provider
      value={{ commentModalSectionId, setCommentModalSectionId }}
    >
      {children}
    </CommentModalContext.Provider>
  );
};

const ReportEachSectionContext = createContext({
  sectionId: "",
  examReport: {},
  eyeReport: {},
  durationStartTime: "",
  duration: "",
  comments: [],
});

export {
  CommentContext,
  CommentTitleProvider,
  CommentAPInfoContext,
  CommentModalContext,
  CommentModalContextProvider,
  ReportEachSectionContext,
};
