// Library methods
import { useContext, useEffect, useMemo, useCallback } from "react";

// MUI Components
import { Grid } from "@mui/material";

// Utilities
import { ReportEachSectionContext } from "../../contexts/CommentContext";
import { getExamType } from "../../utils/examReportsHelper";

import ColorVisionPdfReport from "../../containers/Report/ColorVisionReport/EyeReport";
import ContrastPdfReport from "../../containers/Report/ContrastSensitivityReport/EyeReport";
import EstermanPdfReport from "../../containers/Report/EstermanReport/EyeReport";
import VisualFieldPdfReport from "../../containers/Report/VisualFieldReport/EyeReport";
import VisualAcuityPdfReport from "../../containers/Report/VisualAcuityReport/EyeReport";
import Full120Report from "../../containers/Report/SingleThresholdReport/EyeReport";
import ReportPdf from "../../components/UI/Report";
import { ErrorMessageComponent } from "../../components/UI/ErrorMessage";

import {
  AlgorithmContext,
  ExamTypeContext,
} from "../../contexts/ExamPropertyContext";
import { ReportDataContext } from "../../contexts/ReportDataContext";

import useClinic from "../../hooks/useClinic";
import { getClinics } from "../../services/Clinic";

const RegularExamPDF = ({ clinicId, patientId, examId }) => {
  const forBackendPdf = true;
  const reportPdfValues = ReportPdf(forBackendPdf, clinicId, patientId, examId);
  const {
    exam,
    examReport1,
    examReport2,
    eyeReport1,
    eyeReport2,
    comments1,
    comments2,
    sectionId1,
    sectionId2,
    refetchComment,
    durationStartTime1,
    durationStartTime2,
    duration1,
    duration2,
    shouldDisplayReport,
  } = reportPdfValues;

  const { clinicSettings, setClinicSettings, setClinicLicensing } = useClinic();

  useEffect(() => {
    try {
      const getLicensing = async () => {
        const { licensing, settings } = await getClinics(null, clinicId);
        if (licensing) setClinicLicensing(licensing);
        if (settings) setClinicSettings(settings);
        return licensing;
      };
      getLicensing();
    } catch (e) {}
  }, [clinicId, setClinicLicensing, setClinicSettings]);

  const { TYPE_VISUAL_ACUITY } = useContext(ExamTypeContext);
  const { esterman, pellirobson, tumbling, landoltC, snellen } =
    useContext(AlgorithmContext);

  const providerValues = useMemo(() => {
    // provider values for regular exam PDF
    return {
      ...reportPdfValues,
      examReport: examReport1,
      eyeReport: eyeReport1,
      comments: comments1,
      sectionId: sectionId1,
      refetchComment: refetchComment,
      durationStartTime: durationStartTime1,
      duration: duration1,
    };
  }, [
    reportPdfValues,
    comments1,
    duration1,
    durationStartTime1,
    examReport1,
    eyeReport1,
    refetchComment,
    sectionId1,
  ]);

  const SectionWrapper = ({
    report,
    sectionId,
    childComponent,
    eyeReport,
    durationStartTime,
    duration,
    comments,
    needBreak = null,
  }) => {
    return (
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          pageBreakAfter: needBreak ? "always" : "auto",
        }}
      >
        <ReportEachSectionContext.Provider
          value={{
            sectionId: sectionId,
            examReport: report,
            eyeReport: eyeReport,
            durationStartTime: durationStartTime,
            duration: duration,
            comments: comments,
          }}
        >
          {childComponent}
        </ReportEachSectionContext.Provider>
      </Grid>
    );
  };

  const renderEachSectionWrapper = useCallback(
    (type) => {
      let childComponent;
      let needBreak;

      switch (type) {
        case "D-15":
          childComponent = <ColorVisionPdfReport />;
          needBreak =
            exam?.colorVisionSections?.[0]?.completionDate &&
            exam?.colorVisionSections?.[1]?.completionDate;
          break;

        case pellirobson:
        case tumbling:
          childComponent = <ContrastPdfReport />;
          needBreak =
            exam?.contrastSensitivitySections?.[0]?.completionDate &&
            exam?.contrastSensitivitySections?.[1]?.completionDate;
          break;

        case TYPE_VISUAL_ACUITY:
        case landoltC:
        case snellen:
          childComponent = <VisualAcuityPdfReport />;
          needBreak =
            exam?.visualAcuitySections?.[0]?.completionDate &&
            exam?.visualAcuitySections?.[1]?.completionDate;
          break;

        default:
          return null;
      }

      return (
        <>
          <SectionWrapper
            report={examReport1}
            sectionId={sectionId1}
            childComponent={childComponent}
            eyeReport={eyeReport1}
            duration={duration1}
            comments={comments1}
            needBreak={needBreak}
          />
          {/* Exam report 2 is for monocular vision with multiple sections */}
          <SectionWrapper
            report={examReport2}
            sectionId={sectionId2}
            childComponent={childComponent}
            eyeReport={eyeReport2}
            duration={duration2}
            comments={comments2}
          />
          {clinicSettings?.monocularReportPdfLayoutOption === "Single" &&
            sectionId1 != null &&
            sectionId2 != null && <canvas width={1920}></canvas>}
        </>
      );
    },
    [
      TYPE_VISUAL_ACUITY,
      clinicSettings,
      comments1,
      comments2,
      duration1,
      duration2,
      exam,
      examReport1,
      examReport2,
      eyeReport1,
      eyeReport2,
      landoltC,
      pellirobson,
      sectionId1,
      sectionId2,
      snellen,
      tumbling,
    ]
  );

  const reportSection = useMemo(() => {
    const type = getExamType(exam);

    switch (type) {
      case esterman:
        return (
          <SectionWrapper
            report={examReport1}
            sectionId={sectionId1}
            childComponent={<EstermanPdfReport />}
            comments={comments1}
          />
        );
      case "D-15":
      case pellirobson:
      case tumbling:
      case TYPE_VISUAL_ACUITY:
      case landoltC:
      case snellen:
        return renderEachSectionWrapper(type);
      case "Single Threshold":
        return (
          <>
            <SectionWrapper
              report={examReport1}
              sectionId={sectionId1}
              childComponent={<Full120Report />}
              eyeReport={eyeReport1}
              durationStartTime={durationStartTime1}
              duration={duration1}
              comments={comments1}
              needBreak={
                exam?.visualFieldSections?.[0]?.completionDate &&
                exam?.visualFieldSections?.[1]?.completionDate
              }
            />

            <SectionWrapper
              report={examReport2}
              sectionId={sectionId2}
              childComponent={<Full120Report />}
              eyeReport={eyeReport2}
              durationStartTime={durationStartTime2}
              duration={duration2}
              comments={comments2}
            />
          </>
        );
      default:
        return (
          <>
            <SectionWrapper
              report={examReport1}
              sectionId={sectionId1}
              childComponent={<VisualFieldPdfReport />}
              eyeReport={eyeReport1}
              durationStartTime={durationStartTime1}
              duration={duration1}
              comments={comments1}
              needBreak={
                exam?.visualFieldSections?.[0]?.completionDate &&
                exam?.visualFieldSections?.[1]?.completionDate
              }
            />
            <SectionWrapper
              report={examReport2}
              sectionId={sectionId2}
              childComponent={<VisualFieldPdfReport />}
              eyeReport={eyeReport2}
              durationStartTime={durationStartTime2}
              duration={duration2}
              comments={comments2}
            />
          </>
        );
    }
  }, [
    TYPE_VISUAL_ACUITY,
    comments1,
    comments2,
    duration1,
    duration2,
    durationStartTime1,
    durationStartTime2,
    esterman,
    exam,
    examReport1,
    examReport2,
    eyeReport1,
    eyeReport2,
    landoltC,
    pellirobson,
    renderEachSectionWrapper,
    sectionId1,
    sectionId2,
    snellen,
    tumbling,
  ]);

  if (!shouldDisplayReport) {
    return <ErrorMessageComponent />;
  }

  return (
    <ReportDataContext.Provider value={providerValues}>
      <Grid container sx={{ display: "flex" }} columnSpacing={4}>
        {reportSection}
      </Grid>
    </ReportDataContext.Provider>
  );
};

export default RegularExamPDF;
