// Library methods
import { useEffect, useState, useCallback, useContext, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI Components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

// Components
import ClinicStats from "../../containers/Dashboard/ClinicStats";
import DashboardDevicesList from "../../containers/Dashboard/DashboardDevicesList";
import RecentlyCompletedList from "../../containers/Dashboard/RecentlyCompletedList";
import TodayExamsList from "../../containers/Dashboard/TodayExamsList";

// Styles
import { PageContainerWrapper } from "../../styles/muiStylesHelper";

// Utilities
import useClinic from "../../hooks/useClinic";
import { getPatients } from "../../services/Patient";
import {
  getClinicExamStatistics,
  getClinicLinkedExams,
  getClinicPatientStatistics,
  getClinicRecentlyCompletedExams,
  getClinicScheduledExams,
} from "../../services/Exam";
import { getClinicDevices } from "../../services/Device";
import LayoutHeightContext from "../../contexts/LayoutHeight";
import { useSignalR } from "../../hooks/useSignalR";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";
import { getMaxDate, getUtcStartEndDate } from "../../utils/dateHelper";
import { useDeviceAppVersion } from "../../contexts/DeviceContext";
import { LiveUpdateStatusProvider } from "../../contexts/LiveUpdateStatusContext";

const Patients = () => {
  // internationalization
  const { t } = useTranslation();

  // navigate
  const navigate = useNavigate();

  // auth0
  const { getAccessTokenSilently } = useAuth0();

  // clinic context
  const { clinicId, clinicName } = useClinic();

  // states
  const [devices, setDevices] = useState([]);
  const [patients, setPatients] = useState([]);
  // const [exams, setExams] = useState([]);
  const [todayExams, setTodayExams] = useState(null);
  const [recentlyCompletedExams, setRecentlyCompletedExams] = useState(null);
  // const [dashboardData, setDashboardData] = useState(null);
  const [staticsData, setStaticsData] = useState(null);
  const [devicesOptions, setDevicesOptions] = useState([]);

  // indicates if the data has been fetched, or if it is still being fetched
  const [isLoadingDevices, setIsLoadingDevices] = useState(true);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);
  const [isLoadingSignalR, setIsLoadingSignalR] = useState(false);
  const [isLoadingRecentlyCompleted, setIsLoadingRecentlyCompleted] =
    useState(false);
  const [isLoadingTodayExams, setIsLoadingTodayExams] = useState(false);

  // signalR related states
  const [devicesWithExams, setDevicesWithExams] = useState([]);

  const { navbarHeight, footerHeight } = useContext(LayoutHeightContext);
  const clinicStatsRef = useRef(null);
  const [clinicStatsHeight, setClinicStatsHeight] = useState(null);

  const [term, setTerm] = useState(0);
  const [maxNumOfRows, setMaxNumOfRows] = useState(1);

  useDeviceAppVersion(devices);

  const handleDeviceListUpdated = useCallback(async (data) => {
    // get all devices
    const devicesData = JSON.parse(data);

    // get the devices from this clinic with associated exams
    const associatedDevicesList = devicesData?.data?.filter(
      (device) => device.associatedExam !== undefined
    );
    const devicesWithoutExams = devicesData?.data?.filter(
      (device) => device.associatedExam === undefined
    );
    setDevices(devicesData?.data || []);
    setDevicesWithExams(associatedDevicesList);
    setDevicesOptions(devicesWithoutExams);
  }, []);

  // signalR setup
  const { connection, reconnectedRef } = useSignalR(
    clinicId,
    handleDeviceListUpdated,
    setIsLoadingSignalR
  );

  // online status
  const isOnline = useOnlineStatus(connection, clinicId);

  useEffect(() => {
    if (isOnline) {
      if (!reconnectedRef.current) return;
      const timeoutId = setTimeout(() => {
        setIsLoadingSignalR(false);
      }, 2000); // Delay setting the state by 2 sec to sync with signalR reconnection

      return () => clearTimeout(timeoutId);
    } else setIsLoadingSignalR(true);
  }, [isOnline, reconnectedRef]);

  // method that fetches patients data
  const fetchPatients = useCallback(
    async (signal) => {
      if (!clinicName) navigate("/invalid");
      try {
        // get authToken
        const token = await getAccessTokenSilently();

        // get patients list
        const patientsList = await getPatients(token, clinicId, signal);
        setPatients(patientsList);
      } catch (e) {
        //console.log(e);
      }
    },
    [clinicId, clinicName, getAccessTokenSilently, navigate]
  );

  // method that does the patients data fetching, showing the loading indicator
  const fetchPatientsCall = useCallback(
    async (signal) => {
      try {
        await fetchPatients(signal);
      } catch (e) {
        //console.log(e);
      } finally {
      }
    },
    [fetchPatients]
  );

  // method that fetches devices data
  const fetchDevices = useCallback(
    async (signal) => {
      if (!clinicName) navigate("/invalid");
      try {
        // get authToken
        const token = await getAccessTokenSilently();

        // get the devices from this clinic
        const devicesData = await getClinicDevices(token, clinicId, signal);

        // devices without an associated exam
        const devicesWithoutExam = devicesData.filter(
          (device) => device.associatedExam === undefined
        );

        // devices with an associated exam
        const devicesWithExam = devicesData.filter(
          (device) => device.associatedExam !== undefined
        );

        // set the states
        setDevices(devicesData);
        setDevicesOptions(devicesWithoutExam);
        setDevicesWithExams(devicesWithExam);
      } catch (e) {
        //console.log(e);
      }
    },
    [clinicId, clinicName, getAccessTokenSilently, navigate]
  );

  // method that does the devices data fetching, showing the loading indicator
  const fetchDevicesCall = useCallback(
    async (signal) => {
      try {
        setIsLoadingDevices(true);
        await fetchDevices(signal);
      } catch (e) {
        //console.log(e);
      } finally {
        setIsLoadingDevices(false);
      }
    },
    [fetchDevices]
  );

  // method that fetches exams data
  const fetchDashboard = useCallback(
    async (signal) => {
      if (!clinicName) navigate("/invalid");
      try {
        // get authToken
        const token = await getAccessTokenSilently();

        if (!Array.isArray(todayExams)) setIsLoadingTodayExams(true);
        if (!Array.isArray(recentlyCompletedExams))
          setIsLoadingRecentlyCompleted(true);
        const todayUtc = getUtcStartEndDate(0);
        const commonPayload = {
          startDate: todayUtc.startOfUTC,
          endDate: todayUtc.endOfUTC,
        };
        const timeRangeUtc = getUtcStartEndDate(term);
        const recentFilterPayload = {
          recentFilterDate: timeRangeUtc.startOfUTC,
        };
        const promises = [
          getClinicScheduledExams(token, clinicId, commonPayload, signal),
          getClinicLinkedExams(token, clinicId, commonPayload, signal),
          getClinicRecentlyCompletedExams(
            token,
            clinicId,
            recentFilterPayload,
            signal
          ),
          getClinicPatientStatistics(
            token,
            clinicId,
            recentFilterPayload,
            signal
          ),
          getClinicExamStatistics(token, clinicId, recentFilterPayload, signal),
        ];

        const [
          scheduledExamData,
          linkedExamData,
          recentlyCompletedExamsData,
          patientStatistics,
          examStatistics,
        ] = await Promise.all(promises);
        setStaticsData({
          ...patientStatistics,
          ...examStatistics,
        });

        // const formatExams = useCallback(
        const formatExams = (exams) => {
          // helper method to get the completed date in case the exam is completed
          const getExamCompletedDate = (exam) => {
            if (exam?.visualFieldSections?.length > 1) {
              if (
                !exam.visualFieldSections[0].completionDate ||
                !exam.visualFieldSections[1].completionDate
              )
                return (
                  exam.visualFieldSections[0]?.completionDate ??
                  exam.visualFieldSections[1]?.completionDate
                );
              return getMaxDate(
                exam.visualFieldSections[0].completionDate,
                exam.visualFieldSections[1].completionDate
              );
            }
            if (exam?.visualFieldSections?.length === 1)
              return exam?.visualFieldSections[0].completionDate;
            if (exam?.contrastSensitivitySections?.length === 1)
              return exam?.contrastSensitivitySections[0].completionDate;
            if (exam?.colorVisionSections?.length === 1)
              return exam.colorVisionSections[0].completionDate;
            if (exam?.visualAcuitySections?.length === 1)
              return exam.visualAcuitySections[0].completionDate;
            return null;
          };

          return exams?.map((exam) => {
            const completedDate = getExamCompletedDate(exam);
            return {
              ...exam,
              isCompleted: completedDate ? true : false,
              completedDate: completedDate,
              firstName: exam.patient.firstName,
              lastName: exam.patient.lastName,
              // adding some helpful information to the exam
              tutorialOrder: exam.requiresTutorial
                ? t("word_yes")
                : t("word_no"),
              deviceName: exam.associatedDevice?.device?.alias || "-",
            };
          });
        };
        const formattedTodayExams = formatExams(
          scheduledExamData?.length ? scheduledExamData : []
        );
        if (linkedExamData?.length) {
          linkedExamData.forEach((linkedExam) => {
            if (
              !formattedTodayExams.find((exam) => exam.id === linkedExam.id)
            ) {
              formattedTodayExams.push(linkedExam);
            }
          });
        }

        const formattedRecentlyCompletedExams = formatExams(
          recentlyCompletedExamsData?.length ? recentlyCompletedExamsData : []
        );

        // set the states
        setTodayExams(formattedTodayExams);
        setRecentlyCompletedExams(formattedRecentlyCompletedExams);
      } catch (e) {
        setIsLoadingRecentlyCompleted(false);
        setIsLoadingTodayExams(false);
        console.log(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      clinicId,
      clinicName,
      getAccessTokenSilently,
      navigate,
      t,
      term,
      todayExams?.length,
    ]
  );

  // method that does the exams data fetching, showing the loading indicator
  const fetchDashboardCall = useCallback(
    async (signal) => {
      try {
        await fetchDashboard(signal);
      } catch (e) {
        //console.log(e);
      }
    },
    [fetchDashboard]
  );

  useEffect(() => {
    setIsLoadingDashboard(
      !todayExams && !recentlyCompletedExams && !staticsData
    );
  }, [recentlyCompletedExams, staticsData, todayExams]);

  // initial data fetching
  useEffect(() => {
    const controller = new AbortController();
    fetchPatientsCall(controller.signal);
    fetchDevicesCall(controller.signal);
    fetchDashboardCall(controller.signal);
    return () => {
      controller.abort();
    };
  }, [fetchPatientsCall, fetchDevicesCall, fetchDashboardCall]);

  useEffect(() => {
    if (Array.isArray(todayExams)) setIsLoadingTodayExams(false);
  }, [todayExams]);

  useEffect(() => {
    if (Array.isArray(recentlyCompletedExams))
      setIsLoadingRecentlyCompleted(false);
  }, [recentlyCompletedExams]);

  useEffect(() => {
    setRecentlyCompletedExams(null);
    setIsLoadingRecentlyCompleted(true);
  }, [term]);

  // navigate to the exam's report page
  const handleViewReport = (patientId, examId) => {
    navigate(`/patients/${patientId}/exam/${examId}`);
  };

  return (
    <LiveUpdateStatusProvider>
      <Box
        sx={() => PageContainerWrapper()}
        px={{ xs: 2, sm: 4, md: 6, lg: 6 }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: "column",
              sm: "column",
              ls: "column",
              md: "row",
            },
            minHeight: {
              md: "900px",
            },
            height: {
              xs: "100%",
              xl: `calc(100vh - ${footerHeight}px - ${navbarHeight}px)`,
            },
          }}
        >
          <Grid item xs={12} sm={12} md={3} lg={2.5} xl={2}>
            <ClinicStats
              staticsData={staticsData}
              isLoading={isLoadingDashboard}
              ref={clinicStatsRef}
              onHeightChange={(height) => {
                setClinicStatsHeight(height);
              }}
              term={term}
            />
            <DashboardDevicesList
              rows={devices}
              isLoading={isLoadingDevices}
              devicesWithExams={devicesWithExams}
              patients={patients}
              heightExceptDevicesList={
                navbarHeight + footerHeight + clinicStatsHeight
              }
              connection={connection}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={0.5}
            display="flex"
            justifyContent="center"
          >
            <Divider
              sx={{
                maxBorderRightWidth: { xs: 1600, sm: 1200 },
                marginTop: 2,
                borderColor: "#E2772E90",
                height: { xs: 5, sm: 5, md: "93%" },
                borderRightWidth: { md: 3 },
                width: { xs: 100, sm: 100, md: 0 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8.5} lg={9} xl={9.5}>
            <Grid
              container
              spacing={2}
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  ls: "column",
                  md: "row",
                },
              }}
            >
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <Grid
                  display="flex"
                  flexDirection="column"
                  mb={{ xs: 0 }}
                  mt={{ xs: 6.2 }}
                >
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ fontWeight: "bold", color: "#E2772E" }}
                  >
                    {t("exams_title")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} xl={5}>
                <RecentlyCompletedList
                  rows={recentlyCompletedExams}
                  associatedExamDevices={devicesWithExams}
                  isLoading={isLoadingRecentlyCompleted}
                  maxNumOfRows={maxNumOfRows}
                  term={term}
                  setTerm={setTerm}
                />
              </Grid>
              <Grid item xs={12} xl={7}>
                <TodayExamsList
                  connection={connection}
                  isLoading={isLoadingTodayExams}
                  patients={patients}
                  rows={todayExams}
                  devicesOptions={devicesOptions}
                  refetchDevices={fetchDevices}
                  refetchExams={fetchDashboard}
                  devicesWithExams={devicesWithExams}
                  handleViewReport={handleViewReport}
                  isLoadingSignalR={isLoadingSignalR}
                  maxNumOfRows={maxNumOfRows}
                  setMaxNumOfRows={setMaxNumOfRows}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LiveUpdateStatusProvider>
  );
};

export default Patients;
