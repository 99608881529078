// Library methods
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

// Styles
import {
  ModalCustomCancel,
  ModalPaperProps,
  ModalCustomConfirm,
  ModalCustomConfirmIconHandler,
  ModalDialogActions,
} from "../../../styles/muiStylesHelper";

// Utilities
import { availableLanguages, languageMap } from "../../../i18n/languages";

const LanguageSwitcherModal = ({ open, onClose, onConfirm, onCancel }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  // internationalization
  const { t } = useTranslation();

  // handles the value selection
  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  // resets the selection when closing the modal
  useEffect(() => {
    if (!open) {
      // the below if is required, as some browsers might use 'en-US' by default, but the en we display as an option is represented by 'en'.
      // this way, it won't show the english language selected by default, in that case.
      const en = availableLanguages[0];
      const language = i18n.language;
      if (language.includes(en)) setLanguage(en);
      else setLanguage(i18n.language);
    }
  }, [i18n.language, open]);

  // changes the language
  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { ...ModalPaperProps, maxWidth: "400px" },
      }}
    >
      <DialogContent>
        <Box
          padding={1}
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
        >
          <FontAwesomeIcon icon={faLanguage} size="lg" />
          <Typography
            style={{ fontWeight: "bold" }}
            variant="h5"
            component="h2"
          >
            {t("word_language_preference")}
          </Typography>
        </Box>
        <Box px={1} py={2}>
          <FormControl>
            <RadioGroup
              aria-labelledby="language-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={language}
              onChange={handleChange}
            >
              {/* mapping all languages supported */}
              {availableLanguages.map((lng) => (
                <FormControlLabel
                  key={lng}
                  value={lng}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#E2772E",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography noWrap sx={{ fontSize: 18 }}>
                      {languageMap[lng].label}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={ModalDialogActions}>
        <Button variant="text" sx={ModalCustomCancel} onClick={onCancel}>
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...ModalCustomConfirm(),
            ...ModalCustomConfirmIconHandler(),
          }}
          onClick={() => {
            handleLanguageChange(language);
            onConfirm();
          }}
        >
          {t("word_set")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LanguageSwitcherModal;
