import { useLocation, useParams } from "react-router-dom";
import PageIntro from "../../components/UI/PageIntro";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getComments, getPatientById } from "../../services/Patient";
import useClinic from "../../hooks/useClinic";
import { getClinics } from "../../services/Clinic";
import DownloadButtons from "../../components/UI/DownloadButtons";
import { ValidatePDFContext } from "../../contexts/ValidatePDFContext";
import ProgressionAnalysisReportContainer from "../../containers/ProgressionAnalysisReport";
import { CommentModalContextProvider } from "../../contexts/CommentContext";
import Breadcrumb from "../../components/UI/ReportBreadcrumb";
import { Box } from "@mui/material";
import { PageContainerWrapper } from "../../styles/muiStylesHelper";
import { hideLoader, showLoader } from "../../components/loader/loader";
import { useProgressionAnalysisExams } from "../../hooks/progression-analysis/useProgressionAnalysisExams";
import {
  AllFileTypes,
  generateProgressionAnalysisReportFile,
  getProgressionAnalysisAccessInfo,
  getProgressionAnalysisReportInfo,
} from "../../services/Exam";
import { useReportGeneration } from "../../hooks/reports/useReportGeneration";

const ProgressionAnalysisReport = () => {
  const commentsSectionRef = useRef(null);

  const location = useLocation();
  const progressionAnalysisId = location?.pathname?.split(
    "progression-analysis/"
  )?.[1];

  const { patientId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { clinicId, setClinicLicensing } = useClinic();
  const [patient, setPatient] = useState(null);
  const [comments, setComments] = useState([]);

  const isLoading = false;
  const forBackendPdf = false;

  const fetchPatientAndClinic = useCallback(async () => {
    try {
      showLoader();
      const token = await getAccessTokenSilently();
      const [patient, clinicData] = await Promise.all([
        getPatientById(token, clinicId, patientId),
        getClinics(token, clinicId),
      ]);
      hideLoader();

      if (clinicData?.licensing) setClinicLicensing(clinicData.licensing);
      setPatient(patient);
    } catch (e) {
      hideLoader();
      console.error("Error fetching data:", e);
    }
  }, [clinicId, getAccessTokenSilently, patientId, setClinicLicensing]);

  useEffect(() => {
    fetchPatientAndClinic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { examsData: report } = useProgressionAnalysisExams({
    useToken: true,
    clinicId,
    patientId,
    progressionAnalysisId,
  });

  const getAllComments = useCallback(async () => {
    try {
      showLoader();
      const token = await getAccessTokenSilently();
      const res = await getComments(
        token,
        clinicId,
        patientId,
        progressionAnalysisId
      );
      setComments(res);
      hideLoader();
    } catch (error) {
      hideLoader();
    }
  }, [clinicId, patientId, progressionAnalysisId, getAccessTokenSilently]);

  useEffect(() => {
    getAllComments();
  }, [getAllComments]);

  const customGetAccessInfo = async (fileType, signal) => {
    const token = await getAccessTokenSilently();
    return getProgressionAnalysisAccessInfo(
      token,
      clinicId,
      patientId,
      progressionAnalysisId,
      fileType,
      signal
    );
  };

  const customGetReportInfo = async (signal) => {
    const token = await getAccessTokenSilently();
    return getProgressionAnalysisReportInfo(
      token,
      clinicId,
      patientId,
      progressionAnalysisId,
      signal
    );
  };

  const customGenerateReportFile = async (type) => {
    const token = await getAccessTokenSilently();
    return generateProgressionAnalysisReportFile(
      token,
      clinicId,
      patientId,
      progressionAnalysisId,
      AllFileTypes[type]
    );
  };

  const {
    downloadPdf,
    shouldDisplayDicomButton,
    shouldDisplayPngButton,
    fileInfo,
    reportInfoError,
  } = useReportGeneration({
    getAccessInfo: customGetAccessInfo,
    generateReportFile: customGenerateReportFile,
    getReportInfo: customGetReportInfo,
  });

  return (
    <ValidatePDFContext.Provider value={{ forBackendPdf }}>
      <CommentModalContextProvider>
        <Box
          sx={() => PageContainerWrapper()}
          px={{ xs: 2, sm: 4, md: 8, lg: 12 }}
          mb={{ xs: 2 }}
        >
          <PageIntro
            pageTitle="Report"
            showBreadcrumb
            BreadcrumbComponent={
              <Breadcrumb
                isLoading={isLoading}
                patientId={patientId}
                patientName={`${patient?.firstName} ${patient?.lastName}`}
              />
            }
            showAddButton={false}
            showDeleteButton={false}
            showFilterInput={false}
            desktopMarginTop={3}
            desktopMarginBottom={3}
          />
          <DownloadButtons
            isLoading={isLoading}
            fileInfo={fileInfo}
            reportInfoError={reportInfoError}
            downloadPdf={downloadPdf}
            shouldDisplayDicomButton={shouldDisplayDicomButton}
            shouldDisplayPngButton={shouldDisplayPngButton}
          />
          {report && comments && (
            <ProgressionAnalysisReportContainer
              report={report}
              patient={patient}
              clinicId={clinicId}
              progressionAnalysisId={progressionAnalysisId}
              comments={comments ?? []}
              getAllComments={getAllComments}
              commentsSectionRef={commentsSectionRef}
            />
          )}
        </Box>
      </CommentModalContextProvider>
    </ValidatePDFContext.Provider>
  );
};

export default ProgressionAnalysisReport;
