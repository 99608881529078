// ProgressionCharts.js
import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
} from "chart.js";
import { progressionChartShapes } from "../../enums";
import ProgressionChartLegend from "../../components/UI/ProgressionChartLegend";

const ProgressionCharts = ({ examData }) => {
  const [chartDataList, setChartDataList] = useState([]);

  const { t } = useTranslation();
  Chart.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    LineController
  );

  useEffect(() => {
    if (!examData || !examData.length) return;
    const uniqueData = examData.reduce(
      (acc, exam) => {
        const date = new Date(exam.creationDate).toLocaleDateString();
        const MD = exam.reportData?.postProcessing?.totalMeanDeviation ?? null;
        const PSD =
          exam.reportData?.postProcessing?.patternStandardDeviation ?? null;
        const RLFI =
          exam.reportData?.postProcessing?.retinaLogikFieldIndex ?? null;
        const chartPointShapes =
          progressionChartShapes[exam.reportData?.exam.algorithm];

        if (!acc.time.includes(date)) {
          acc.time.push(date);

          acc.MD.push(MD !== null ? MD : undefined);
          acc.PSD.push(PSD !== null ? PSD : undefined);
          acc.RLFI.push(RLFI !== null ? RLFI : undefined);
          acc.chartPointShapes.push(
            chartPointShapes !== null ? chartPointShapes : undefined
          );
        }

        return acc;
      },
      { time: [], MD: [], PSD: [], RLFI: [], chartPointShapes: [] }
    );

    setChartDataList(uniqueData);
  }, [examData]);

  return (
    <>
      <Typography marginBottom={3} variant="h6">
        {t("Progression Charts")}
      </Typography>

      {/* Chart Legend */}
      <Box mb={4}>
        <ProgressionChartLegend />
      </Box>

      <Grid container width="100%">
        {["MD", "PSD", "RLFI"].map((key) => (
          <Grid
            key={key}
            data-testid={`${key}-progression-chart`}
            item
            xs={12}
            sm={4}
          >
            <Line
              data={{
                labels: chartDataList.time,
                datasets: [
                  {
                    label: key,
                    data: chartDataList[key],
                    borderColor: "#E2772E",
                    fill: false,
                    tension: 0.1,
                    pointStyle: chartDataList.chartPointShapes,
                    pointRadius: 5,
                    pointBackgroundColor: "#E2772E",
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: true,
                    text:
                      key === "MD"
                        ? "Mean Deviation (MD)"
                        : key === "PSD"
                          ? "Pattern Standard Deviation (PSD)"
                          : "Retina Logik Field Index (RLFI)",
                  },
                },
                scales: {
                  x: {
                    title: { display: true, text: "Time" },
                  },
                  y: {
                    title: { display: true, text: `${key} (dB)` },
                  },
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ProgressionCharts;
