import SharedDeleteModal from "../SharedDeleteModal";

const DeleteExternalExamModal = (props) => (
  <SharedDeleteModal
    {...props}
    titleKey="exams_delete_confirmation"
    descriptionKey="external_exam_delete_description"
  />
);

export default DeleteExternalExamModal;
