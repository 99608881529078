// import moment from "moment";
import moment from "moment-timezone";

import { localizeDate } from "./localeHelper";

const formatDate = (date) =>
  `${date.toISOString().slice(0, 10)} ${date.toISOString().slice(11, 19)}`;

const filterByDateRange = (array, days, dateKey) => {
  const todayDate = new Date();
  const today = moment(todayDate);
  const initialRangeDate = moment(today).subtract(days, "day");

  return array.filter((element) => {
    const date = localizeDate(element[dateKey])?.toISOString();
    return moment(date).isBetween(initialRangeDate, today);
  });
};

const sortByDate = (array, key) =>
  array.sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime());

/**
 * Compare two dates to get the max value.
 * @param {*} a First date
 * @param {*} b Second date
 * @returns the date with "greater" value
 */
const getMaxDate = (a, b) => {
  const dateA = new Date(a).getTime();
  const dateB = new Date(b).getTime();
  return dateA > dateB ? a : b;
};

// Ensure local date is sent to backend
const makeLocalISOString = (d) => {
  const date = new Date(d);
  const pad = (number) => number.toString().padStart(2, "0");

  const localISOString =
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    "." +
    (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5);

  // Get the timezone offset in minutes and create a string formatted as ±[hh][mm]
  const timezoneOffset = -date.getTimezoneOffset();
  const sign = timezoneOffset >= 0 ? "+" : "-";
  const hoursOffset = pad(Math.floor(Math.abs(timezoneOffset) / 60));
  const minutesOffset = pad(Math.abs(timezoneOffset) % 60);

  return localISOString + sign + hoursOffset + ":" + minutesOffset;
};

const convertLocalToUTC = (localISOString) => {
  const date = new Date(localISOString);
  const pad = (number) => number.toString().padStart(2, "0");

  const utcISOString =
    date.getUTCFullYear() +
    "-" +
    pad(date.getUTCMonth() + 1) +
    "-" +
    pad(date.getUTCDate()) +
    "T" +
    pad(date.getUTCHours()) +
    ":" +
    pad(date.getUTCMinutes()) +
    ":" +
    pad(date.getUTCSeconds()) +
    "." +
    (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5);

  // UTC time zone offset is always +00:00
  return utcISOString + "+00:00";
};

// Make sure utc date from backend is converted to local date.
const getLocalTime = (utcTimeStr) => {
  // Parse the UTC string to a Date object
  const date = new Date(utcTimeStr ?? Date.now);

  // Get the timezone offset in minutes and convert it to milliseconds
  const timezoneOffset = date.getTimezoneOffset() * 60000; // 60000 milliseconds in a minute

  // Subtract the timezone offset to get the local time
  const localDate = new Date(date.getTime() - timezoneOffset);

  // Manually construct the date string in 'YYYY-MM-DD' format
  const yyyy = localDate.getFullYear();
  const mm = (localDate.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-based
  const dd = localDate.getDate().toString().padStart(2, "0");

  const localDateString = `${yyyy}-${mm}-${dd}`;
  return localDateString;
};
const toLocalDateTime = (utcTimeStr) => {
  if (!utcTimeStr) return null;
  const date = new Date(utcTimeStr); // Assuming utcTimeStr is a correct UTC string

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // months are 0-based
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000`;
};

/**
 * Checks if a given date matches today's day.
 */
const isSameDayAsToday = (dateString) =>
  moment(dateString).isSame(getLocalTime(Date.now()), "day");

// This is to list all timezone abbreviations
const getAllTimeZoneInfo = () => {
  const timezoneNames = moment.tz.names();
  return timezoneNames.map((tz) => {
    return {
      name: tz,
      currentTime: moment.tz(tz).format("YYYY-MM-DD HH:mm:ss"),
      utcOffset: moment.tz(tz).format("Z"),
      abbreviation: moment.tz(tz).format("z"),
    };
  });
};

const getUtcStartEndDate = (days) => {
  const now = new Date();

  const startDate = new Date(now);
  startDate.setDate(now.getDate() - days);

  // Set the start time based on the local time
  startDate.setHours(0, 0, 0, 0);

  const startOfUTC = new Date(
    Date.UTC(
      startDate.getUTCFullYear(),
      startDate.getUTCMonth(),
      startDate.getUTCDate(),
      startDate.getUTCHours(),
      startDate.getUTCMinutes(),
      startDate.getSeconds()
    )
  );

  // For the end date, current date at 23:59:59 in local time
  const endDate = new Date(now);
  endDate.setHours(23, 59, 59, 999);

  const endOfUTC = new Date(
    Date.UTC(
      endDate.getUTCFullYear(),
      endDate.getUTCMonth(),
      endDate.getUTCDate(),
      endDate.getUTCHours(),
      endDate.getUTCMinutes(),
      endDate.getUTCSeconds()
    )
  );

  return { startOfUTC, endOfUTC };
};

const formatDateForMuiX = (dateString) => {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // JS months are 0-based, but now in UTC
  const day = date.getUTCDate().toString().padStart(2, "0");
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  // Format to 'YYYY-MM-DDTHH:mm:ss' (e.g., '1999-03-09T21:00:00')
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

const calculateYearDifference = (dateString) => {
  const todaysDate = new Date();
  const pastDate = new Date(dateString);

  // subtract past year from current year
  let difference = todaysDate.getUTCFullYear() - pastDate.getUTCFullYear();

  // if the current date's month/day are before the past date's month/day,
  // subtract another year
  if (
    todaysDate.getUTCMonth() < pastDate.getUTCMonth() ||
    (todaysDate.getUTCMonth() === pastDate.getUTCMonth() &&
      todaysDate.getUTCDate() < pastDate.getUTCDate())
  ) {
    difference--;
  }

  return difference;
};

// Checks if a date is in the format YYYY-MM-DD
const isValidDateFormat = (dateString) => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  return dateRegex.test(dateString);
};

export {
  formatDate,
  filterByDateRange,
  sortByDate,
  getMaxDate,
  isSameDayAsToday,
  makeLocalISOString,
  getLocalTime,
  toLocalDateTime,
  convertLocalToUTC,
  getAllTimeZoneInfo,
  getUtcStartEndDate,
  formatDateForMuiX,
  calculateYearDifference,
  isValidDateFormat,
};
