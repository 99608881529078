// // Library methods
import { useTranslation } from "react-i18next";
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";

// MUI Components
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import "./index.scss";

import { Cancel } from "@mui/icons-material";

const ExternalExamDetailsModal = ({
  openModal = false,
  handleClose,
  examDetails,
}) => {
  const { t } = useTranslation();

  function displayAsPercentage(value) {
    if (value === undefined || value === null) {
      return "N/A";
    }
    return `${value * 100}%`;
  }

  function toHumanReadable(sentence) {
    if (!sentence || typeof sentence !== "string") {
      return "N/A";
    }

    return sentence
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camelCase words
      .toLowerCase() // Convert to lowercase
      .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase()) // Capitalize the first letter of each word
      .trim(); // Remove extra spaces
  }

  return (
    <Dialog
      open={openModal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose(event.target.value);
        }
      }}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          width: { xs: "80%", sm: "70%", md: "480px" },
          margin: "auto",
        },
      }}
      className="external-details-dialog"
    >
      <div className="haederSec">
        <DialogTitle id="form-dialog-title" sx={{ fontWeight: "bold" }}>
          {t("external_exam_header")}
        </DialogTitle>

        <Cancel onClick={() => handleClose(false)} />
      </div>

      <DialogContent>
        {/* content */}

        <div className="examDetailsContainer">
          <div className="examDetails__content">
            <strong>{t("external_exam_details_GHT")}</strong>{" "}
            <p>{toHumanReadable(examDetails?.glaucomaHemifieldTest)}</p>
          </div>

          <div className="examDetails__content">
            <strong>{t("external_exam_details_VFI")}</strong>{" "}
            <p>{displayAsPercentage(examDetails?.visualFieldIndex)}</p>
          </div>

          <div className="examDetails__content">
            <strong>{t("external_exam_details_PSD")}</strong>{" "}
            <p>{examDetails?.patternStandardDeviation}</p>
          </div>

          <div className="examDetails__content">
            <strong>{t("external_exam_details_MD")}</strong>{" "}
            <p>{examDetails?.meanDeviation}</p>
          </div>

          <div className="examDetails__content">
            <strong>{t("external_exam_details_FP")}</strong>
            <p>{displayAsPercentage(examDetails?.falsePositive)}</p>
          </div>

          <div className="examDetails__content">
            <strong>{t("external_exam_details_FN")}</strong>
            <p>{displayAsPercentage(examDetails?.falseNegative)}</p>
          </div>

          <div className="examDetails__content">
            <strong>{t("external_exam_details_fixation_method")}</strong>{" "}
            <p>{toHumanReadable(examDetails?.fixationMethod)}</p>
          </div>

          <div className="examDetails__content">
            <strong>{t("external_exam_details_fixation")}</strong>{" "}
            <p>{examDetails?.fixationValue}</p>
          </div>

          <div className="examDetails__content">
            <strong>{t("external_exam_details_stimulus_size")}</strong>{" "}
            <p>{examDetails?.stimulusSize}</p>
          </div>

          <div className="examDetails__content">
            <strong>{t("external_exam_details_FoveaDb")}</strong>{" "}
            <p>{examDetails?.foveaDb}</p>
          </div>

          <div className="examDetails__content">
            <strong>{t("external_exam_details_machine_source")}</strong>{" "}
            <p>{examDetails?.machineSource}</p>
          </div>

          {/* more details */}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(ExternalExamDetailsModal);
