import { useEffect, useCallback, useState, useMemo } from "react";
import { useNavigate } from "react-router";

// MUI Components
import { Grid } from "@mui/material";

import { usePatient } from "../../hooks/patients/usePatient";
import { useProgressionAnalysisExams } from "../../hooks/progression-analysis/useProgressionAnalysisExams";
import { getExamType } from "../../utils/examReportsHelper";
import { sectionCompletionState } from "../../hooks/useExamStatus";
import { getComments } from "../../services/Patient";

import { ReportDataContext } from "../../contexts/ReportDataContext";
import { ErrorMessageComponent } from "../../components/UI/ErrorMessage";
import ProgressionAnalysisReportContainer from "../../containers/ProgressionAnalysisReport";

const ProgressionAnalysisPDF = ({
  clinicId,
  patientId,
  progressionAnalysisId,
}) => {
  const [comments, setComments] = useState([]);

  // navigate
  const navigate = useNavigate();

  const { analysisData, examsData: analysisReport } =
    useProgressionAnalysisExams({
      useToken: false,
      patientId,
      clinicId,
      progressionAnalysisId,
    });

  const { data: patientData } = usePatient({
    useToken: false,
    patientId,
    clinicId,
    isPdf: true,
  });

  const refetchComment = useCallback(async () => {
    try {
      let token = null;

      setComments(
        await getComments(token, clinicId, patientId, progressionAnalysisId)
      );
    } catch (e) {
      navigate("/401");
    }
  }, [clinicId, patientId, progressionAnalysisId, navigate]);

  const providerValues = useMemo(() => {
    return {
      patientName: patientData?.name,
      patient: patientData,
      examReport: analysisReport,
      comments,
      refetchComment,
    };
  }, [patientData, analysisReport, comments, refetchComment]);

  const getAllComments = useCallback(async () => {
    try {
      const token = null;
      const res = await getComments(
        token,
        clinicId,
        patientId,
        progressionAnalysisId
      );
      setComments(res);
    } catch (error) {}
  }, [clinicId, patientId, progressionAnalysisId]);

  useEffect(() => {
    getAllComments();
  }, [getAllComments]);

  // whether the progression analysis PDF is valid or not
  const validateProgressionAnalysis = useCallback((analysis, analysisExams) => {
    if (!analysis || !analysisExams) return false;

    // check state of progression analysis
    if (analysis.state === sectionCompletionState.Invalid) return false;

    // check state of individual exams
    const boolArray = analysisExams.map((exam) => {
      const examAlgorithm = getExamType(exam);
      const examState = exam?.reportData?.state;

      if (
        exam?.visualFieldSections?.[0]?.completionState ===
          sectionCompletionState.Invalid ||
        exam?.visualFieldSections?.[1]?.completionState ===
          sectionCompletionState.Invalid
      )
        return false;

      if (examAlgorithm === "D-15" && examState?.row2?.length > 0) return true;

      // Pelli Robson
      if (examState?.letters?.length > 0) return true;

      // Tumbling E
      if (examState?.directions?.length > 0) return true;

      // Visual Field || Esterman || Full120
      if (examState?.visualSpots?.length > 0) return true;
      if (examState?.visualSpots?.length > 0) return true;

      return false;
    });

    return boolArray.every((val) => val === true);
  }, []);

  const shouldDisplayProgressionAnalysis =
    Boolean(progressionAnalysisId) &&
    validateProgressionAnalysis(analysisData, analysisReport);

  if (!shouldDisplayProgressionAnalysis) {
    return <ErrorMessageComponent />;
  }

  return (
    <ReportDataContext.Provider value={providerValues}>
      <Grid container sx={{ display: "flex" }} columnSpacing={4}>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            pageBreakAfter: "auto",
          }}
        >
          <ProgressionAnalysisReportContainer
            report={analysisReport ?? []}
            patient={patientData}
            clinicId={clinicId}
            progressionAnalysisId={progressionAnalysisId}
            comments={comments}
            getAllComments={getAllComments}
            commentsSectionRef={null}
          />
        </Grid>
      </Grid>
    </ReportDataContext.Provider>
  );
};

export default ProgressionAnalysisPDF;
