// Library methods
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

// Components
import ProgressionAnalysisPDF from "./ProgressionAnalysisPDF";
import RegularExamPDF from "./RegularExamPDF";
import { ErrorMessageComponent } from "../../components/UI/ErrorMessage";

// Contexts
import { ValidatePDFContext } from "../../contexts/ValidatePDFContext";
import {
  CommentAPInfoContext,
  CommentTitleProvider,
} from "../../contexts/CommentContext";

const Pdf = () => {
  const [searchParams] = useSearchParams();

  const clinicId = searchParams.get("clinic_id") || "";
  const patientId = searchParams.get("patient_id") || "";
  const examId = searchParams.get("exam_id") || "";
  const progressionAnalysisId =
    searchParams.get("progression_analysis_id") || "";

  const forBackendPdf = true;
  const shouldDisplayRegularReport = Boolean(
    clinicId && patientId && examId && !progressionAnalysisId
  );
  const shouldDisplayProgressionAnalysis = Boolean(
    clinicId && patientId && !examId && progressionAnalysisId
  );

  const componentToDisplay = useMemo(() => {
    // regular exam PDF
    if (shouldDisplayRegularReport) {
      return (
        <RegularExamPDF
          clinicId={clinicId}
          patientId={patientId}
          examId={examId}
        />
      );
    }
    // progression analysis PDF
    else if (shouldDisplayProgressionAnalysis) {
      return (
        <ProgressionAnalysisPDF
          clinicId={clinicId}
          patientId={patientId}
          progressionAnalysisId={progressionAnalysisId}
        />
      );
    }

    // default: error message
    return <ErrorMessageComponent />;
  }, [
    shouldDisplayRegularReport,
    shouldDisplayProgressionAnalysis,
    clinicId,
    examId,
    patientId,
    progressionAnalysisId,
  ]);

  return (
    <CommentTitleProvider>
      <CommentAPInfoContext.Provider
        value={{
          clinicId,
          patientId,
          examId,
        }}
      >
        <ValidatePDFContext.Provider value={{ forBackendPdf }}>
          {componentToDisplay}
        </ValidatePDFContext.Provider>
      </CommentAPInfoContext.Provider>
    </CommentTitleProvider>
  );
};

export default Pdf;
