// Library methods
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Components
import RecentlyCompletedExamCard from "./RecentlyCompletedExamCard";
import Loader from "../../../components/UI/Loader";
import ListHeader from "../../../components/UI//ListHeader";

// Utilities
import { getComparator } from "../../../utils/tableHelper";
import { useEffect, useMemo, useState } from "react";
import { itemsRendering, pagesCount } from "../../../utils/paginationHelper";
import PaginationWrapper from "../../../components/UI/PaginationWrapper";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { TermSwitcher } from "../../../components/UI/TermSwitcher";

const RecentlyCompletedList = ({
  rows,
  isLoading,
  associatedExamDevices,
  maxNumOfRows,
  term,
  setTerm,
}) => {
  const orderByCompletedDate = ["completedDate"];
  const orderByPatientName = ["firstName", "lastName"];
  const orderByTypeAlg = ["type", "algorithm", "gridType"];
  // internationalization
  const { t } = useTranslation();

  const theme = useTheme();
  const smallerThanXlScreen = useMediaQuery(theme.breakpoints.down("xl"));

  // routing
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  // order
  const [order, setOrder] = useState("desc");
  const [orderByOptions, setOrderByOptions] = useState([
    "completedDate",
    ...orderByPatientName,
  ]);

  // head rows
  const headCells = [
    {
      id: "patient",
      label: t("current_patient"),
      gridSize: 3,
      orderUsing: [
        ...orderByPatientName,
        ...orderByCompletedDate,
        ...orderByTypeAlg,
      ],
    },
    {
      id: "type",
      label: t("exams_column_type"),
      gridSize: 5,
      orderUsing: [
        ...orderByTypeAlg,
        ...orderByPatientName,
        ...orderByCompletedDate,
      ],
    },
    {
      id: "completedDate",
      label: t("exam_Completed"),
      gridSize: 2.5,
      orderUsing: [
        ...orderByCompletedDate,
        ...orderByPatientName,
        ...orderByTypeAlg,
      ],
    },
    {
      id: "manage",
      label: "",
      gridSize: 1,
    },
  ];

  // navigate to the exam's report page
  const handleViewReport = (patientId, examId) => {
    navigate(`/patients/${patientId}/exam/${examId}`);
  };

  // handle sort Request (it can now order considering two fields)
  const handleRequestSort = (event, property) => {
    const isAsc =
      orderByOptions.length &&
      orderByOptions[0] === property[0] &&
      order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderByOptions(property);
  };
  // sorted rows
  const sortedRows = useMemo(
    () => (rows ?? []).sort(getComparator(order, orderByOptions)),
    [order, orderByOptions, rows]
  );

  // rows to render
  const rowsToRender = useMemo(() => {
    return itemsRendering(
      sortedRows,
      page,
      smallerThanXlScreen ? 6 : Math.max(maxNumOfRows, 4)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortedRows?.[0], smallerThanXlScreen, maxNumOfRows]);

  useEffect(() => setPage(1), [rows]);

  // pagination change
  const onPaginationChange = (event, value) => {
    setPage(value);
  };

  const ListElement = () =>
    isLoading ? (
      <Loader containerHeight="30vh" />
    ) : (
      <Grid container mt={3}>
        <Grid item xs={12}>
          <ListHeader
            headCells={headCells}
            order={order}
            orderBy={orderByOptions?.[0] ?? "completedDate"}
            onRequestSort={handleRequestSort}
            showCheckbox={false}
            checkBoxGrid={0.5}
          />
          {rowsToRender.length > 0 ? (
            <Box my={4}>
              {rowsToRender.map((row) => {
                return (
                  <RecentlyCompletedExamCard
                    key={row.id}
                    row={row}
                    headCells={headCells}
                    handleViewReportClick={handleViewReport}
                    associatedExamDevices={associatedExamDevices}
                  />
                );
              })}
            </Box>
          ) : (
            <Box
              my={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="52px"
            >
              <Typography
                noWrap
                variant="body1"
                sx={{ textTransform: "capitalize" }}
                color="text.secondary"
              >
                {t("no_recently_completed_exams_found")}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    );

  return (
    <Box maxHeight={500}>
      <Grid container display="flex" alignItems="center">
        <Grid item xs={7}>
          <Typography
            component="div"
            variant="h7"
            sx={{
              fontWeight: "bold",
              fontSize: { sm: "16px", lg: "20px" },
            }}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            whiteSpace={"nowrap"}
          >
            {t("recently_completed")}
          </Typography>
        </Grid>
        <Grid item xs={5} display="flex" justifyContent="flex-end">
          <TermSwitcher term={term} setTerm={setTerm} />
        </Grid>
      </Grid>
      {ListElement()}
      {rows?.length > rowsToRender?.length && (
        <PaginationWrapper
          page={page}
          count={pagesCount(
            sortedRows,
            smallerThanXlScreen ? 6 : Math.max(maxNumOfRows, 4)
          )}
          onChange={onPaginationChange}
        />
      )}
    </Box>
  );
};

export default RecentlyCompletedList;
