import React from "react";
import { useTranslation } from "react-i18next";
import ProgressAnalysisEyeReport from "../../../ProgressionAnalysisReport/ProgressAnaysisEyeReport";
import { Typography } from "@mui/material";
import { getLocalTime } from "../../../../utils/dateHelper";

export default function FetchExam({ exam, selectedExam }) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography variant="body1">
        <span style={{ fontWeight: "bold" }}>
          {t("progress_analysis_exam_date")}:
        </span>{" "}
        {getLocalTime(exam.creationDate)}
      </Typography>
      <ProgressAnalysisEyeReport
        key={selectedExam.id}
        examReport={selectedExam.reportData}
        examData={selectedExam}
        orientation="left"
      />
    </div>
  );
}
