// Library methods
import { forwardRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

// MUI Components
import Grid from "@mui/material/Grid";

// Components
import ClinicStatsCard from "./ClinicStatsCard";
import PageIntro from "../../../components/UI/PageIntro";

// Utilities
import { Box } from "@mui/material";
import useLayoutHeight from "../../../hooks/useLayoutHeight";

const ClinicStats = forwardRef(
  (
    {
      staticsData = {
        examsFinished: null,
        examsTerminated: null,
        patientsCreated: null,
        totalPatients: null,
      },
      isLoading,
      onHeightChange,
      term = 0,
    },
    ref
  ) => {
    // internationalization
    const { t, i18n } = useTranslation();

    const statsInfo = useMemo(
      () => ({
        dynamicStatsToDisplay: {
          title:
            term === 0
              ? t("Today")
              : i18n.language === "fr"
                ? `7 ${t("word_last")} ${t("word_days")}`
                : `${t("word_last")} 7 ${t("word_days")}`,
          examsCompleted: {
            title: `${t("completed")} ${t("word_exams")}`,
            value: staticsData?.examsFinished,
          },
          examsTerminated: {
            title: `${t("word_terminated")} ${t("word_exams")}`,
            value: staticsData?.examsTerminated,
          },
          patientsCreated: {
            title: t("created_patients"),
            value: staticsData?.patientsCreated,
          },
        },
        staticStatsToDisplay: {
          title: t("word_total"),
          totalExams: {
            title: t("word_exams"),
            value: staticsData?.totalExams,
          },
          totalPatients: {
            title: t("dashboard_total_patients"),
            value: staticsData?.totalPatients,
          },
        },
      }),
      [
        term,
        t,
        i18n.language,
        staticsData?.examsFinished,
        staticsData?.examsTerminated,
        staticsData?.patientsCreated,
        staticsData?.totalExams,
        staticsData?.totalPatients,
      ]
    );

    const ListElement = () => (
      <Grid container mt={{ xs: 1, sm: 5 }} spacing={2}>
        {Object.keys(statsInfo).map((infoKey) => (
          <Grid key={infoKey} item xs={6} md={12}>
            <ClinicStatsCard data={statsInfo[infoKey]} />
          </Grid>
        ))}
      </Grid>
    );

    const height = useLayoutHeight(ref);
    useEffect(() => {
      // Only call the onHeightChange callback if it's provided
      if (onHeightChange) {
        onHeightChange(height);
      }
    }, [height, onHeightChange]);

    return (
      <>
        <Box ref={ref}>
          <PageIntro
            pageTitle={t("dashboard_title_clinic_stats")}
            showAddButton={false}
            showDeleteButton={false}
            showExpandButton={false}
            showFilterInput={false}
            desktopMarginTop={6.2}
          />
          {ListElement()}
        </Box>
      </>
    );
  }
);

export default ClinicStats;
