import axios from "axios";
import {
  config,
  injectBearer,
  isResponseValid,
  pdfConfig,
  webServiceUrl,
} from "../../utils/servicesHelper";

const ErrorMessages = {
  ReportInfoError: "Report info Error",
  ReportFileGenrationError: "Failed to generate ",
};
const AllFileTypes = {
  pdf: "pdf",
  dicom: "dicom",
  png: "png",
};
const addExam = async (accessToken, clinicId, patientId, ExamData) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams`,
      ExamData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const addProgressAnalysis = async (
  accessToken,
  clinicId,
  patientId,
  ExamData
) => {
  try {
    const response = await axios.post(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/progression-analysis`,
      ExamData,
      config(accessToken)
    );

    if (isResponseValid(response)) {
      return response.data;
    } else {
      throw new Error("Invalid response format.");
    }
  } catch (error) {
    throw error;
  }
};

const getClinicScheduledExams = async (accessToken, clinicId, params, signal) =>
  axios
    .get(`${webServiceUrl}/clinics/${clinicId}/dashboard/scheduled-exams`, {
      headers: { Authorization: injectBearer(accessToken) },
      params,
      signal: signal,
    })
    .then((response) => {
      const dashboard = isResponseValid(response);
      return dashboard;
    })
    .catch((error) => {
      throw error;
    });

const getClinicLinkedExams = async (accessToken, clinicId, params, signal) =>
  axios
    .get(`${webServiceUrl}/clinics/${clinicId}/dashboard/linked-exams`, {
      headers: { Authorization: injectBearer(accessToken) },
      params,
      signal: signal,
    })
    .then((response) => {
      const dashboard = isResponseValid(response);
      return dashboard;
    })
    .catch((error) => {
      throw error;
    });

const getClinicRecentlyCompletedExams = async (
  accessToken,
  clinicId,
  params,
  signal
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/dashboard/recently-completed-exams`,
      {
        headers: { Authorization: injectBearer(accessToken) },
        params,
        signal: signal,
      }
    )
    .then((response) => {
      const dashboard = isResponseValid(response);
      return dashboard;
    })
    .catch((error) => {
      throw error;
    });

const getClinicPatientStatistics = async (
  accessToken,
  clinicId,
  params,
  signal
) =>
  axios
    .get(`${webServiceUrl}/clinics/${clinicId}/dashboard/patient-statistics`, {
      headers: { Authorization: injectBearer(accessToken) },
      params,
      signal: signal,
    })
    .then((response) => {
      const dashboard = isResponseValid(response);
      return dashboard;
    })
    .catch((error) => {
      throw error;
    });

const getClinicExamStatistics = async (accessToken, clinicId, params, signal) =>
  axios
    .get(`${webServiceUrl}/clinics/${clinicId}/dashboard/exam-statistics`, {
      headers: { Authorization: injectBearer(accessToken) },
      params,
      signal: signal,
    })
    .then((response) => {
      const dashboard = isResponseValid(response);
      return dashboard;
    })
    .catch((error) => {
      throw error;
    });

const getExams = async (accessToken, clinicId, patientId, signal) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams`,
      config(accessToken, signal)
    )
    .then((response) => {
      const exams = isResponseValid(response);
      return exams;
    })
    .catch((error) => {
      throw error;
    });

const getExamById = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  signal,
  isPdf = null
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}`,
      isPdf ? pdfConfig(signal) : config(accessToken, signal)
    )
    .then((response) => {
      const exam = isResponseValid(response);
      return exam;
    })
    .catch((error) => {
      throw error;
    });

const getExamSectionById = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  sectionId,
  examFieldType
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${examFieldType}/${sectionId}`,
      config(accessToken)
    )
    .then((response) => {
      const sections = isResponseValid(response);
      return sections;
    })
    .catch((error) => {
      throw error;
    });

const getSubmisionData = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  sectionId,
  signal,
  isPdf = null
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${sectionId}/submission`,
      isPdf ? pdfConfig(signal) : config(accessToken, signal)
    )
    .then((response) => {
      const report = isResponseValid(response);
      return report;
    })
    .catch((error) => {
      throw error;
    });

const getAccessInfo = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  fileFormat = "pdf",
  signal
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/file-management/${fileFormat}`,
      config(accessToken, signal)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getReportInfo = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  signal
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/report-info`,
      config(accessToken, signal)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw new Error(ErrorMessages.ReportInfoError);
    });

const generateReportFile = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  fileFormat = "pdf",
  signal
) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/regenerate-report/${fileFormat}`,
      null,
      config(accessToken, signal)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw new Error(ErrorMessages.ReportFileGenrationError + fileFormat);
    });

const getCommentsReport = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  sectionId,
  signal,
  isPdf = null
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${sectionId}/comments`,
      isPdf ? pdfConfig(signal) : config(accessToken, signal)
    )
    .then((response) => {
      const report = isResponseValid(response);
      return report;
    })
    .catch((error) => {
      throw error;
    });

const getProgressAnalysisCommentsReport = async (
  accessToken,
  clinicId,
  patientId,
  progressionAnalysisId,
  signal,
  isPdf = null
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/progression-analysis/${progressionAnalysisId}/comments`,
      isPdf ? pdfConfig(signal) : config(accessToken, signal)
    )
    .then((response) => {
      const report = isResponseValid(response);
      return report;
    })
    .catch((error) => {
      throw error;
    });

const addExamSection = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  ExamData,
  examFieldType
) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${examFieldType}`,
      ExamData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const deleteExam = async (accessToken, clinicId, patientId, examId) =>
  axios
    .delete(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}`,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const deleteExamSection = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  sectionId,
  examFieldType
) =>
  axios
    .delete(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${examFieldType}/${sectionId}`,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const updateExam = async (accessToken, clinicId, patientId, examId, examData) =>
  axios
    .put(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}`,
      examData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const updateExamSection = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  sectionId,
  examData,
  examFieldType
) =>
  axios
    .put(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${examFieldType}/${sectionId}`,
      examData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

/**
 * commentData: [{
 *    "content": string,
 *    "conditions": `[string]`,
 *    "otherConditions": string
 * }]
 */
const addCommentReport = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  sectionId,
  commentData
) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/sections/${sectionId}/comments`,
      commentData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });
const addProgressAnalysisCommentReport = async (
  accessToken,
  clinicId,
  patientId,
  progressionAnalysisId,
  commentData
) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/progression-analysis/${progressionAnalysisId}/comments`,
      commentData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getTodayPatientCount = async (accessToken, clinicId) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/ScheduledPatientsCount`,
      config(accessToken)
    )
    .then((response) => {
      const report = isResponseValid(response);
      return report;
    })
    .catch((error) => {
      throw error;
    });

const transferExam = async (
  accessToken,
  clinicId,
  patientId,
  examId,
  newPatientId
) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/transfer/${newPatientId}`,
      null,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getAllProgressionAnalysis = async (accessToken, clinicId, patientId) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/progression-analysis`,
      config(accessToken)
    )
    .then((response) => {
      const report = isResponseValid(response);
      return report;
    })
    .catch((error) => {
      throw error;
    });

const getSingleProgressionAnalysis = async (
  accessToken,
  clinicId,
  patientId,
  analysisId
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/progression-analysis/${analysisId}`,
      config(accessToken)
    )
    .then((response) => {
      const report = isResponseValid(response);
      return report;
    })
    .catch((error) => {
      throw error;
    });

const deleteProgressionAnalysis = async (
  accessToken,
  clinicId,
  patientId,
  progressionAnalysisId
) =>
  axios
    .delete(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/progression-analysis/${progressionAnalysisId}`,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getProgressAnalysisStatus = async (
  accessToken,
  clinicId,
  patientId,
  examId
) => {
  try {
    const response = await axios.get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/exams/${examId}/progression-analysis`,
      config(accessToken)
    );

    if (isResponseValid(response)) {
      return response.data;
    } else {
      throw new Error("Invalid response format.");
    }
  } catch (error) {
    throw error;
  }
};

const getAllExternalExams = async (accessToken, clinicId, patientId) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/external-exams`,
      config(accessToken)
    )
    .then((response) => {
      const report = isResponseValid(response);
      return report;
    })
    .catch((error) => {
      throw error;
    });

const deleteExternalExam = async (accessToken, clinicId, patientId, id) =>
  axios
    .delete(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/external-exams/${id}`,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getProgressionAnalysisReportInfo = async (
  accessToken,
  clinicId,
  patientId,
  progressionAnalysisId,
  signal
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/progression-analysis/${progressionAnalysisId}/report-info`,
      config(accessToken, signal)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw new Error(ErrorMessages.ReportInfoError);
    });

const generateProgressionAnalysisReportFile = async (
  accessToken,
  clinicId,
  patientId,
  progressionAnalysisId,
  fileFormat = "pdf",
  signal
) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/progression-analysis/${progressionAnalysisId}/regenerate-report/${fileFormat}`,
      null,
      config(accessToken, signal)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw new Error(ErrorMessages.ReportFileGenrationError + fileFormat);
    });

const getProgressionAnalysisAccessInfo = async (
  accessToken,
  clinicId,
  patientId,
  progressionAnalysisId,
  fileFormat = "pdf",
  signal
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/progression-analysis/${progressionAnalysisId}/file-management/${fileFormat}`,
      config(accessToken, signal)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

export {
  addExam,
  addProgressAnalysis,
  getClinicScheduledExams,
  getClinicLinkedExams,
  getClinicRecentlyCompletedExams,
  getClinicPatientStatistics,
  getClinicExamStatistics,
  getExams,
  getExamById,
  getExamSectionById,
  getSubmisionData,
  getAccessInfo,
  getReportInfo,
  getProgressionAnalysisAccessInfo,
  getProgressionAnalysisReportInfo,
  generateProgressionAnalysisReportFile,
  getCommentsReport,
  generateReportFile,
  addExamSection,
  deleteExam,
  deleteExamSection,
  updateExam,
  updateExamSection,
  addCommentReport,
  getTodayPatientCount,
  transferExam,
  getAllProgressionAnalysis,
  getSingleProgressionAnalysis,
  deleteProgressionAnalysis,
  addProgressAnalysisCommentReport,
  getProgressAnalysisCommentsReport,
  ErrorMessages,
  AllFileTypes,
  getProgressAnalysisStatus,
  getAllExternalExams,
  deleteExternalExam,
};
